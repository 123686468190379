/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useEffect } from "react"

  /**
   * Hook to set the page title.
   *
   * Automatically resets the page title back to the previous value when the component is unmounted.
   *
   * @param title The title to set the page title to.
   */
export function useTitle(title:string) {
    useEffect(() => {
      const prevTitle = document.title
      document.title = "BeeMG | " + title
      return () => {
        document.title = prevTitle
      }
    })
  }