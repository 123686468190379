/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useState } from 'react'
import { Modal, ModalCloseButton, ModalHeader, ModalBody, ModalFooter } from '../../Components/base/modal/modal'
import { MeetingDetails } from '../../models/meetingDetails.model'
import { Meeting } from '../../models/meeting.model'
import { useTranslation } from 'react-i18next'
import Field from '../../Components/base/field/field'
import Button from '../../Components/base/button/button'
import { MeetingRequest, Select } from '../../models/meetingRequest.model'
import { useSelector } from 'react-redux'
import { getCurrentUser } from '../../Services/userReducer'
import { useNavigate } from 'react-router-dom'
import { DisplayFormattedDate, DisplayFormattedTime } from '../../Components/formattedDateAndTime'
interface _JoinAnonymousModalProps {
    isOpen: boolean
    toggle: (e?: any) => void
    meetingDetails?: MeetingDetails
    meeting?: Meeting
    payment: any
    razorpayOpen?: boolean
    setRazorpayOpen?:any
    meetingRequestData?: MeetingRequest
    RFMPayNow: boolean
    meetingRequest?: any
}
/**
 * A modal component for joining an event as anonymous.
 * It includes a checkbox to join the event as anonymous, and a button to pay for the event.
 * If the user is not anonymous and joins as anonymous, it displays a button to update anonymous profile.
 * @param {object} props - Component props
 * @param {boolean} props.isOpen - Whether the modal should be open or not
 * @param {function} props.toggle - Callback function to handle the modal toggle
 * @param {object} props.meetingDetails - The meeting details object
 * @param {object} props.meeting - The meeting object
 * @param {function} props.payment - Callback function to handle the payment
 * @param {boolean} props.RFMPayNow - Whether the modal is for RFM or not
 * @param {object} props.meetingRequestData - The meeting request data object
 * @param {object} props.meetingRequest - The meeting request object
 * @param {boolean} props.razorpayOpen - Whether the razorpay modal is open or not
 * @param {function} props.setRazorpayOpen - Callback function to handle the razorpay modal open state
 * @returns {JSX.Element} The JoinAnonymousModal component
 */
export default function JoinAnonymousModal(props: _JoinAnonymousModalProps) {
    const { isOpen, toggle, meetingDetails, meeting, payment, RFMPayNow, meetingRequestData, meetingRequest, razorpayOpen, setRazorpayOpen } = props
    const [joinAnonymous, setJoinAnonymous] = useState(false)
    const { t } = useTranslation()
    const currentUser = useSelector(getCurrentUser);
    const navigate = useNavigate()
    return (
        <div >
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                closeOnClickOutside={false}
                animate={true}>
                <ModalCloseButton toggle={toggle} />
                {!RFMPayNow && <ModalHeader>
                    <div className='flex flex-col gap-y-2 md:w-[30rem] w-[18rem] text-black'>
                        <span data-testid="paymentModal_title" className='text-left text-ellipsis overflow-hidden'>
                            {t('pay')} {t('for').toLowerCase()} {meetingDetails?.title}
                        </span>
                        {meeting && <span data-testid="paymentModal_dateTime" className='text-sm font-normal text-left'>
                            <DisplayFormattedDate displayDate={meeting.fromDateTime}/>{" "} <DisplayFormattedTime displayFromTime={meeting.fromDateTime}  displayToTime={meeting.toDateTime} />
                        </span>}
                    </div>
                </ModalHeader>}
                {
                    RFMPayNow && <ModalHeader>
                        <div className='flex flex-col gap-y-2 md:w-[30rem] w-[18rem] text-black'>
                            <span className='text-left text-ellipsis overflow-hidden' data-testid="RFMmodal_title">
                                {t('pay')} {t('for').toLowerCase()} {t('requestForMeeting').toLowerCase()}
                            </span>
                            {meetingRequest && <span className='text-sm font-normal text-left' data-testid="RFM_requestSentTo">
                                {`${t('meetingRequestSentTo')} ${meetingRequest.guest.firstName + ' ' + meetingRequest.guest.lastName}`}
                            </span>}
                            <div className="flex flex-row items-center text-sm font-normal text-gray-600" data-testid="RFMmodal_dateTime">
                                {t('requestedOn')}{' '}
                                <DisplayFormattedDate displayDate={meetingRequest?.createdAt} />
                                {' '}
                                {t('scheduledOn')}{' '}
                                {meetingRequestData?.select === Select.Option1 && meetingRequestData?.startAt1DateTime &&
                                    <DisplayFormattedDate displayDate={meetingRequestData.startAt1DateTime} />}
                                {meetingRequestData?.select === Select.Option2 && meetingRequestData?.startAt2DateTime &&
                                    <DisplayFormattedDate displayDate={meetingRequestData.startAt2DateTime} />}
                                {meetingRequestData?.select === Select.Option3 && meetingRequestData?.startAt3DateTime &&
                                    <DisplayFormattedDate displayDate={meetingRequestData.startAt3DateTime} />}
                            </div>
                        </div>
                    </ModalHeader>
                }
                <ModalBody>
                    <div className='flex flex-row items-center text-sm font-normal text-gray-600'>
                        <Field
                            type="checkbox" name="onlineList" id="input_onlineList"
                            onChange={(e: any) => {
                                if (e.target.checked === true) {
                                    setJoinAnonymous(true)
                                } else {
                                    setJoinAnonymous(false)
                                }
                            }}
                        />
                        <label htmlFor='input_onlineList' id="chk_otherPeopleList" data-testid="btn_JoinAnonymous">
                            {t('joinAnonymous')}
                        </label>
                        {joinAnonymous && !currentUser.anonymous &&
                            <div className='ml-8 md:ml-0'>
                                <Button size="sm" rounded color="join" onClick={() => navigate(`/s/profile`,{ state: { isAnon: true } })}><p id="btn_anonymousProfile" data-testid="btn_anonymousProfile" className="text-xs md:text-sm ">{t('updateAnonymousProfile')}</p></Button>
                            </div>}
                    </div>
                </ModalBody>
                <ModalFooter>
                    {meeting && meetingDetails && !RFMPayNow && <Button
                        className="px-5 py-1 md:px-8"
                        disabled={(!currentUser.anonymous && joinAnonymous || razorpayOpen === true)}
                        size="sm" color="payNow2" id="btn_modalPayNow" data-testid="btn_modalPayNow"
                        onClick={() => {
                            payment(meeting.id, meetingDetails.id, meeting.currency, joinAnonymous)
                        }
                        }>
                        {t('payNow')}
                    </Button>}
                    {RFMPayNow && <Button
                        disabled={(!currentUser.anonymous && joinAnonymous || razorpayOpen === true)}
                        size="sm" color="RFMpayNow"
                        data-testid="btn_payNow_RFM"
                        className = "w-52"
                        onClick={() => {
                            payment(meetingRequestData)
                        }
                        }>
                        {t('payNow')}
                    </Button>}
                </ModalFooter>
            </Modal>
        </div >
    )
}

