/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {useTranslation} from 'react-i18next';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import Icon from '../../Components/base/icon/icon';
import Loading from '../../Components/base/loading/loading';
import NoSession from '../../Components/NoSession';
import usePastEventsHook from '../../Hooks/MyEvents/PastEventsHook';
import NewIcon from '../../Components/base/icon/newIcons';
import {
  DisplayFormattedMonth,
  DisplayFormattedTime,
} from '../../Components/formattedDateAndTime';
import {ToolTip} from '../../Components/base/tooltip/tooltip';

/**
 * A page component that displays a list of past schedules of an event.
 * The component is a container that renders a list of meetings and a frame to display the participants list.
 * Each meeting is a grid with 4 columns, with the first column containing the date,
 * the second column containing the time, and the third column containing the number of participants.
 * The component is rounded and has a gray border.
 * The component is a link to the participants list page for the meeting.
 * @returns A JSX element containing the list of meetings and a frame to display the participants list.
 */
function PastEvents() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const pathName = useLocation();
  const {meetingDetailId, meetingId} = useParams();
  const searchStr = window.location.search.substring(1);
  const {meetingSuccess, meetings, meetingError} =
    usePastEventsHook(meetingDetailId);

  if (!meetingSuccess && !meetingError) {
    return <Loading />;
  }
  if (!meetings || meetings.length === 0) {
    return <NoSession>{t('noschedules')}</NoSession>;
  }

  return (
    <div className="flex flex-col lg:flex-row w-100">
      <div className="w-full lg:w-1/2 text-base h-full overflow-scroll max-h-96">
        {meetings.map((meeting, index) => (
          <div
            className={`grid grid-cols-12  py-1 items-center rounded-xl border mb-0.5 mr-1 cursor-default h-28 ${
              meetingId === meeting.id
                ? 'bg-BeeMG-light-gray border-BeeMG-light-gray'
                : 'bg-white border-BeeMG-light-gray'
            }`}>
            <div
              data-testid="EventDateMonth"
              className={`flex flex-col col-span-3 py-4 text-center border-r-2 ${
                meetingId === meeting.id
                  ? 'border-gray-200'
                  : 'border-BeeMG-light-gray'
              }`}>
              <span className="text-xl font-semibold">
                {parseInt(meeting.display_MonthDate.split(' ')[1]) > 9
                  ? meeting.display_MonthDate.split(' ')[1]
                  : '0' + meeting.display_MonthDate.split(' ')[1]}
              </span>
              <span className="font-normal text-sm">
                <DisplayFormattedMonth displayDate={meeting.fromDateTime} />
              </span>
            </div>
            {/* Details */}
            <div className="flex flex-col gap-y-2 col-span-8 w-[15rem] ml-4 leading-none text-sm font-medium">
              <p data-testid="sessionTime" className="tracking-tight">
                <DisplayFormattedTime
                  displayFromTime={meeting.fromDateTime}
                  displayToTime={meeting.toDateTime}
                />
              </p>
              <div
                data-testid={`totalParticipants${index}`}
                id={`totalParticipants${index}`}>
                <span> {meeting.meetingParticipant.length + 1}</span>{' '}
                <span>{t('users')}</span>
              </div>
              <div>
                {meeting.feedback?.length > 0 && (
                  <span
                    data-testid={`reviewsCount${index}`}
                    id={`reviewsCount${index}`}>
                    {' '}
                    {meeting.feedback?.length}
                  </span>
                )}{' '}
                <span>{t('reviews')}</span>
              </div>
            </div>
            <div className="flex flex-col items-center relative right-3 flex-1 top-2 gap-y-3">
              <div
                data-testid={`viewParticipants${index}`}
                id={`viewParticipants${index}`}
                className={
                  pathName.pathname.includes(
                    `${`/s/events/my-events/${meetingDetailId}/pastEvents/${meeting.id}/participants`}`,
                  )
                    ? `h-8 w-8 mx-auto p-[0.35rem] items-center justify-center rounded-full bg-primary`
                    : ''
                }>
                <ToolTip tip="View Guests" keyId="View Guests">
                  <NewIcon
                    className="cursor-pointer items-center"
                    icon="PROFILE"
                    size="small"
                    height="small"
                    stroke="#545454"
                    onClick={() => {
                      navigate(
                        `/s/events/my-events/${meetingDetailId}/pastEvents/${meeting.id}/participants?${searchStr}`,
                      );
                    }}
                  />
                </ToolTip>
              </div>
              <div
                data-testid={`tab_Reviews${index}`}
                id={`tab_Reviews${index}`}
                className={
                  pathName.pathname.includes(
                    `/s/events/my-events/${meetingDetailId}/pastEvents/${meeting.id}/reviews`,
                  )
                    ? `h-8 w-8 mx-auto p-[0.34rem] items-center justify-center rounded-full bg-primary`
                    : ''
                }>
                <ToolTip tip="View Reviews" keyId="View Reviews">
                  <Icon
                    className="cursor-pointer"
                    icon="CALENDER_BLACK"
                    size="small"
                    height="small"
                    onClick={() => {
                      navigate(
                        `/s/events/my-events/${meetingDetailId}/pastEvents/${meeting.id}/reviews?${searchStr}`,
                      );
                    }}
                  />
                </ToolTip>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full lg:w-3/4 px-4 ml-6 h-full overflow-x-hidden overflow-y-auto cursor-default max-h-96">
        <Outlet />
      </div>
    </div>
  );
}

export default PastEvents;
