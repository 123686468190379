/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LogoGIF from "../../Assets/Images/beemg_logo.gif"
import useToggle from '../../Components/_utils/useToggle'
import {
  AccordionBorder,
  AccordionItem,
  AccordionPanel,
} from '../../Components/base/accordion/accordion'
import Button from '../../Components/base/button/button'
import Field from '../../Components/base/field/field'
import Icon from '../../Components/base/icon/icon'
import { Modal, ModalBody, ModalCloseButton, ModalFooter } from '../../Components/base/modal/modal'
import { toastSuccess } from '../../Components/toast'
import { useAttendingHooks, useAttendingReceivedCardHooks, useRejectRequestHooks } from '../../Hooks/AttendingHooks'
import { useViewReadMore } from '../../Hooks/UpcomingEvents'
import { useGetCancellationPolicyQuery } from '../../Services/policyApi'
import { CancellationPolicy } from '../../models/cancellationPolicy.model'
import { Select } from '../../models/meetingRequest.model'
import CardImagePlaceHolder from '../EventsCard/CardImagePlaceHolder'
import CardTitle from '../EventsCard/CardTitle'
import { DisplayEventCost } from '../EventsCard/DisplayEventCost'
import { EventCardRequest } from '../EventsCard/EventCardRequest'
import { getCurrentUser } from '../../Services/userReducer'
import { useSelector } from 'react-redux'
import { DisplayFormattedDate, DisplayFormattedTime } from '../../Components/formattedDateAndTime'

const style = {
  requestDetails: `max-w-md py-1 flex gap-2 items-baseline text-sm items-center`,
  sm_requestDetails: `border-b max-w-md py-2 flex text-xs`,
  optionDetails: `max-w-md py-1 flex gap-2 items-center text-sm`,
  radioButton: `form-check-input appearance-none rounded-full h-4 w-4 border-b border-gray-300 bg-white checked:ring-BeeMG-orange checked:border-BeeMG-yellow focus:outline-none transition duration-200 mr-2 cursor-pointer`,
}

/**
 * Displays a received meeting request.
 * @param meetingRequest - the received meeting request.
 * @returns the component.
 */
export function ReceivedRequest({ meetingRequest }: { meetingRequest:any }) {
  const { t } = useTranslation()
  const { toggle, isOpen } = useToggle();
  const { detailsLength, setDetailsLength, readMoreToLess, setReadMoreToLess } = useViewReadMore({ })
  const { data: cancellationPolicies } = useGetCancellationPolicyQuery()
  const { attendingEventPageRefetch } = useAttendingHooks()
  const currentUser = useSelector(getCurrentUser);
  

  const {
    register, reset, watch, trigger, setValue, availableMeetingSubmitDisable, expireDateSubmitDisable,
    handleSubmit,
    acceptReceivedSuccess,
    acceptReceivedLoading,
    handleReceivedBlock,
    blockReceivedSuccess,
    loadMeetingReceivedRequest,
    setShow,
    setShowedValues,
    handleReceivedDetails,
    onSubmit, getValues,
    checkForMeetingRequest,
  } = useAttendingReceivedCardHooks({ meetingRequest })

  useEffect(() => {
    if (blockReceivedSuccess) {
      toastSuccess(t('userBlockedSuccessfully'))
      attendingEventPageRefetch()
    }
  }, [blockReceivedSuccess, t])

  useEffect(() => {
    if (acceptReceivedSuccess) {
      toastSuccess(t('requestAcceptedSuccessfully'))
      toggle();
    }
  }, [acceptReceivedSuccess, t])


  useEffect(() => {

    if (meetingRequest) {
      handleReceivedDetails()
      checkForMeetingRequest(meetingRequest?.guest?.id)
    }
  }, [getValues()])



  return (
  <> {loadMeetingReceivedRequest !== undefined &&
    <AccordionBorder>
      <div >
     
        <AccordionItem keyId={meetingRequest.id} id={`${meetingRequest.id}`} toggle={`${meetingRequest.id}`} color="card">
          <EventCardRequest keyId={meetingRequest.id}>
            <EventCardRequest.Image><CardImagePlaceHolder images={currentUser?.image} /></EventCardRequest.Image>
             <EventCardRequest.Image1>{loadMeetingReceivedRequest?.isRequestAnonymous ? <CardImagePlaceHolder avatar ={ loadMeetingReceivedRequest?.user?.avatar}  /> : <CardImagePlaceHolder images={loadMeetingReceivedRequest && meetingRequest?.user?.image}   /> }</EventCardRequest.Image1>
              <EventCardRequest.Title><CardTitle keyId={meetingRequest.id} key={meetingRequest.id} meetingTitle={`${t('meetingRequestReceivedFrom')} ${loadMeetingReceivedRequest?.isRequestAnonymous ? loadMeetingReceivedRequest?.user?.nickName : loadMeetingReceivedRequest?.user?.firstName + ' ' + loadMeetingReceivedRequest?.user?.lastName}`} /></EventCardRequest.Title>
              <EventCardRequest.SubTitle> {t('requestedOn')}{' '}
                <DisplayFormattedDate displayDate={meetingRequest.createdAt} />
              </EventCardRequest.SubTitle>
            <EventCardRequest.Icons><DisplayEventCost  keyId={meetingRequest.id} meeting={meetingRequest} /></EventCardRequest.Icons>
            <EventCardRequest.Actions>
              <div className="grid grid-cols-6 w-full">
                <div className={`text-center col-span-5 cursor-pointer font-semibold text-sm text-gray-400`}>
                  <span>{t('paidRequest')}</span>
                </div>
                <div className='flex justify-end mx-auto'>
                  <div className='opacity-0'><Icon size="small" icon="REMINDER" /></div>
                </div>
              </div>
            </EventCardRequest.Actions>
          </EventCardRequest>
        </AccordionItem>

        <AccordionPanel id={`${meetingRequest.id}`} >
          <div className="md:grid md:grid-flow-row flex flex-col grid-cols-12 gap-2 md:gap-0 text-sm lg:text-base border-transparent border-t hover:border-gray-300 pt-5 px-2 md:px-0">
            <div className="col-span-1"></div>
            <div className="col-span-4 text-right mr-6 ml-8">
              <ul>
                <div data-testid="ttl_details" className="font-semibold text-left ">{t('details')}:</div>
                <li id="chk_details" data-testid="detailsInfo" className={style.requestDetails}>
                  <span>
                    {loadMeetingReceivedRequest?.details?.length > 50 ?
                      <>
                        {readMoreToLess && <div className={`text-justify`}>{loadMeetingReceivedRequest?.details.slice(0, detailsLength)} <span className='flex justify-end underline underline-offset-1 cursor-pointer' onClick={() => { setDetailsLength(loadMeetingReceivedRequest?.details.length); setReadMoreToLess(!readMoreToLess) }}>{t('readMore')}</span></div>}
                        {!readMoreToLess && <div className={`text-justify`}>{loadMeetingReceivedRequest?.details.slice(0, detailsLength)} <span className='flex justify-end underline underline-offset-1 cursor-pointer' onClick={() => { setDetailsLength(37); setReadMoreToLess(!readMoreToLess) }}>{t('readLess')}</span></div>}
                      </> : loadMeetingReceivedRequest?.details}
                  </span>
                </li>
                <li data-testid="chk_participants" id="chk_participants" className={style.requestDetails}>
                  {t('users')}: 
                  <span>
                    {loadMeetingReceivedRequest?.participants}
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-span-4 flex md:text-center lg:ml-8">
               <form onSubmit={handleSubmit(onSubmit)} id="meetingRequest">
                  <div data-testid="txt_meetingRequest" className="font-semibold text-left ml-8">{t('requestedSession')}:</div>
                  <ul>
                    <li className={style.optionDetails}>
                      <input
                        {...register('meetingRequestOption')}
                        className={style.radioButton}
                        type="radio"
                        name="meetingRequestOption"
                        id="meetingRequestOption1"
                        data-testid="meetingRequestOption1"
                        onClick={() => setValue('meetingRequestOption', Select.Option1)}
                        value={Select.Option1}
                      >
                      </input>
                      <span id="chk_dateOption1" data-testid="chk_dateOption1">
                        <DisplayFormattedDate displayDate={loadMeetingReceivedRequest?.display_startAt1Date} />
                      </span>
                      <span id="chk_timeOption1" data-testid="chk_timeOption1">
                        <DisplayFormattedTime displayFromTime={loadMeetingReceivedRequest?.startAt1DateTime} displayToTime={loadMeetingReceivedRequest?.endAt1DateTime} />  
                      </span>
                    </li>
                    {loadMeetingReceivedRequest?.startAt2DateTime && (
                      <li className={style.optionDetails}>
                        <input
                          {...register('meetingRequestOption')}
                          className={style.radioButton}
                          type="radio"
                          name="meetingRequestOption"
                          id="meetingRequestOption2"
                          data-testid="meetingRequestOption2"
                          onClick={() => setValue('meetingRequestOption', Select.Option2)}
                          value={Select.Option2}></input>
                        <span id="chk_dateOption2" data-testid="chk_dateOption2">
                          <DisplayFormattedDate displayDate={loadMeetingReceivedRequest?.display_startAt2Date} />
                        </span>
                        <span id="chk_timeOption2" data-testid="chk_timeOption2">
                          <DisplayFormattedTime displayFromTime={loadMeetingReceivedRequest?.startAt2DateTime} displayToTime={loadMeetingReceivedRequest?.endAt2DateTime} />
                        </span>
                      </li>
                    )}
                    {loadMeetingReceivedRequest?.startAt3DateTime && (
                      <li className={style.optionDetails}>

                        <input
                          {...register('meetingRequestOption')}
                          className={style.radioButton}
                          type="radio"
                          name="meetingRequestOption"
                          id="meetingRequestOption3"
                          data-testid="meetingRequestOption3"
                          onClick={() => setValue('meetingRequestOption', Select.Option3)}
                          value={Select.Option3}></input>
                        <span id="chk_dateOption3" data-testid="chk_dateOption3">
                          <DisplayFormattedDate displayDate={loadMeetingReceivedRequest?.display_startAt3Date} />
                        </span>
                        <span id="chk_timeOption3" data-testid="chk_timeOption3">
                          <DisplayFormattedTime displayFromTime={loadMeetingReceivedRequest?.startAt3DateTime} displayToTime={loadMeetingReceivedRequest?.endAt3DateTime} />
                        </span>
                      </li>
                    )}
                  </ul>
                  {availableMeetingSubmitDisable && <div className="pb-10 text-xs text-red-500" id="anExistingTimeSlot">{t('youAlreadyHaveMeetingInThisTimeSlot')}</div>}
                  {expireDateSubmitDisable && <div id="error_dateExpired" className="pb-10 text-xs text-red-500 ">{t('selectedTimeSlotIsExpired')}</div>}
                </form>
            </div>
            {/* <div className="col-auto"></div> */}
            <div className="col-span-3 mr-6">
              <div className="flex flex-col mt-2 gap-2 pb-2 px-4">
                <Button className='py-1.5 text-sm text-gray-600 rounded-md' id="btn_accept" disabled={(availableMeetingSubmitDisable || expireDateSubmitDisable) || (watch('meetingRequestOption') ? false : true) || setShow} onClick={toggle}>
                  {t('accept')}
                </Button>
                <Button className='py-1.5 text-sm text-gray-600 rounded-md hover:bg-red-600 hover:text-white' id="btn_reject" onClick={() => setShowedValues(!setShow)}>
                  {t('reject')}
                </Button>
                <Button className='py-1.5 text-sm text-gray-600 rounded-md bg-transparent' id="btn_delete" onClick={() => handleReceivedBlock()}>
                  {t('block')}
                </Button>
              </div>
            </div>
          </div>
          <RejectReply setShow={setShow} setShowedValues={setShowedValues} meetingRequest={meetingRequest} />
        </AccordionPanel>
      </div>
      <Modal isOpen={isOpen} toggle={toggle} closeOnClickOutside={false}>
        <div onClick={() => reset()}>
          <ModalCloseButton toggle={toggle} />
        </div>
        <ModalBody>
          <div className="text-xl font-semibold mb-3">
            {t('cancellationPolicy')}
          </div>
          <div className="flex flex-col m-3 gap-2">
            {cancellationPolicies?.map((policy: CancellationPolicy, index: number) =>
            (
              <div className="flex " key={policy.id}>
                <div>
                  <input
                    type="radio"
                    {...register('cancellationPolicy')}
                    id={`policy${index}`}
                    data-testid={`policy${index}`}
                    checked={policy.id === getValues('cancellationPolicy')}
                    value={policy.id}
                    onChange={() => {
                      setValue('cancellationPolicy', policy.id)
                      trigger()
                    }}
                  />
                </div>
                <span className="ml-2">
                  <span className='font-semibold'>{policy.policyType}</span>
                  <span>: {policy.chargePeriodFromTimeInMinute === "0" && t('noCancellationCharge')}
                    {policy.chargePeriodFromTimeInMinute !== "0" &&
                      <>
                        {t('cancellationBetween')}  {policy.chargePeriodFromTime}{' '}{t('hours')} ~ {Math.floor(Number(policy.chargePeriodToTime))}{' '}{t('hours')} is {policy.deductionPercentage}% {t('chargeable')} and before {policy.chargePeriodFromTime}{' '}{t('hours')}  ~ is 100% {t('chargeable')}
                      </>}
                  </span>
                </span>
              </div>))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className='flex items-center w-52' data-testid="btn_closePolicy" id="btn_closePolicy" form="meetingRequest" color="footerButton" disabled={(watch('cancellationPolicy') ? false : true) || acceptReceivedLoading} submit>
            {acceptReceivedLoading && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>}
            {t(`confirm`)}
          </Button>
        </ModalFooter>
      </Modal>
    </AccordionBorder>
    }
  </>
  )
}

/**
 * Renders a form to reject a meeting request and specify a reason.
 * @param {{ setShow: any, setShowedValues: any, meetingRequest: any }} props
 * @prop {function} setShow - A function to set the visibility of the component.
 * @prop {function} setShowedValues - A function to set the visibility of the component.
 * @prop {any} meetingRequest - The meeting request to be rejected.
 * @returns {JSX.Element} The component.
 */
export function RejectReply({ setShow, setShowedValues, meetingRequest }: { setShow: any, setShowedValues: any, meetingRequest: any }) {
  const { t } = useTranslation()
  const { OnReject, handleSubmit, register, reset, errors, isDirty, isValid, rejectReceivedSuccess } = useRejectRequestHooks({ meetingRequest })
  useEffect(() => {
    if (rejectReceivedSuccess) {
      toastSuccess(t('requestRejectedSuccessfully'))
    }
  }, [rejectReceivedSuccess, t])

  return (
    <>
      {setShow && (
        <form onSubmit={handleSubmit(OnReject)}>
          <div className="my-4 mx-20">
            <Field
              {...register('reason')}
              error={t((errors?.reason as any)?.message)}
              name="reason"
              type="textarea"
              id="input_reason"
              placeholder={t('pleaseStateTheReason') + ' *'}
            />
            <div className="flex justify-end mt-4">
              <Button id="btn_cancel" submit size="sm" className='bg-transparent py-1.5 px-7 rounded-md' onClick={() => {
                setShowedValues(false)
                reset()
              }}>
                {t('cancel')}
              </Button>
              <Button id="btn_submit" submit size="sm" className='py-1.5 px-7 rounded-md' disabled={!isDirty || !isValid}>
                {t('submit')}
              </Button>

            </div>
          </div>
        </form>

      )}
    </>
  )
}
