/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useTranslation } from "react-i18next"
import { Modal, ModalBody, ModalCloseButton, ModalHeader } from "../../../Components/base/modal/modal"
import useToggle from "../../../Components/_utils/useToggle"
import { getCurrentUser } from "../../../Services/userReducer"
import { useAppSelector } from "../../../Store/hooks"
import UpdateCategory from "./SelectCategories"
import UserInterests from "../UserInterests"
import EditWrapper from "./EditWrapper"
import { ToolTip } from "../../../Components/base/tooltip/tooltip"
import Icon from "../../../Components/base/icon/icon"
import { useUpdateCategoryHook } from "../../../Hooks/SelectCategoriesHook"

  /**
   * UserInterestsEditor renders a button to edit user interests.
   * When the button is clicked, it opens a modal with the UpdateCategory component which allows the user to update their interests.
   * The component also renders a UserInterests component showing the current user's interests.
   * The UserInterests component is passed a user object prop which is the current user from the user reducer.
   * The component also handles the closing of the modal and checks for any changes to the user's interests when the modal is closed.
   * @returns {JSX.Element} A JSX element representing the UserInterestsEditor component.
   */
const UserInterestsEditor = () => {
  const { t } = useTranslation()
  const currentUser = useAppSelector(getCurrentUser)
  const { checkInterestsUpdates } = useUpdateCategoryHook();

  const { isOpen: isEditorOpen, toggle: toggleIsEditorOpen } = useToggle()

  /**
   * Handles the edit button click event by toggling the isEditorOpen state variable.
   * If the editor is not already open, it will open the editor.
   * Otherwise, it will do nothing.
   */
  const handleEditClick = () => {
    if (!isEditorOpen)
      toggleIsEditorOpen()
  }

  /**
   * Handles the closing of the modal by calling the checkInterestsUpdates
   * function to check for any changes to the user's interests and
   * toggling the isEditorOpen state variable.
   */
  const handleCloseEditor = () => {
    //check for interests changes while closing the modal.
    checkInterestsUpdates()
    toggleIsEditorOpen()
  }

  return (
    <EditWrapper
      keyId="userInterests"
      onEditClick={handleEditClick}
      className="min-w-[20rem]"
    >
      <UserInterests user={currentUser} />
      <Modal
        isOpen={isEditorOpen}
        toggle={() => {
          handleCloseEditor()
        }}
        closeOnClickOutside={false}>
        <div className="p-3">
          <div className="flex flex-row justify-between">
            <ModalHeader keyId="chooseYourInterests">
              <div className="flex flex-row gap-x-2 items-center">
                <div>{t('chooseYourInterests')}</div>
                <ToolTip tip='chooseYourInterestsFromTheListGivenBelow' theme="BeeMG-gray" keyId='interests'>
                  <Icon icon="QUESTION" size="x-small" />
                </ToolTip>
              </div>
            </ModalHeader>
            <ModalCloseButton
              toggle={() => {
                handleCloseEditor()
              }}
            />
          </div>
          <ModalBody>
            <div className="flex flex-col gap-y-5 flex-1 min-h-[10rem] w-full relative">
              <UpdateCategory />
            </div>
          </ModalBody>
        </div>
      </Modal>
    </EditWrapper>
  )
}

export default UserInterestsEditor