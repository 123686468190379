/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import defaultImg from '../Assets/Images/beemg-icons-creator-camera-2023.svg'
import useSignOutHook from '../Hooks/SignOutHook'
import { useGetCognitoKeysQuery } from '../Services/envApi'
import { useGetSettingValue } from '../Services/settingReducer'
import { getCurrentUser } from '../Services/userReducer'
import { useAppSelector } from '../Store/hooks'
import Avatar from './base/avatar/avatar'
import NewIcon from './base/icon/newIcons'
import Loading from './base/loading/loading'
import { Menu, Transition } from '@headlessui/react'

const dropDownStyle = {
  navList: `w-full flex px-3 py-2.5 hover:bg-gray-200 hover:rounded-lg gap-x-2 text-xs items-center font-normal`,
  iconStrokeStyle: `#545454`,
}
/**
 * A dropdown menu for the user.
 *
 * Contains links to user profile, transaction list, invite page, and settings page.
 *
 * @returns {JSX.Element} A JSX element containing the user dropdown menu.
 */
const UserDropdownMenu: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: cognitoKeys, isSuccess: cognitoKeysSuccess } =
    useGetCognitoKeysQuery()
  const linkData = useGetSettingValue('WEB_LINK')
  const currentUser = useAppSelector(getCurrentUser)
  const { signOutActions } = useSignOutHook()

  if (!cognitoKeysSuccess || !linkData || !currentUser || !cognitoKeys) {
    return <Loading />
  }

  return (
    <Menu>
      <div className="relative">
        <Menu.Button className=" text-gray-400 font-normal flex items-center cursor-pointer">
          {currentUser && (
            <div id="dropMenu_lang_userDropDown" className="flex gap-2 items-center text-black">
              {currentUser?.userImage && (
                <Avatar size="xx-small" images={currentUser.image} />
              )}
              {!currentUser?.userImage && (
                <img
                  className="bg-gray-200 rounded-full h-8 w-8 p-2"
                  src={defaultImg}
                  alt="placeholder"
                />
              )}
              <span className="font-normal text-xs capitalize">
                {`${currentUser.firstName.length > 7
                  ? `${currentUser.firstName.substring(0, 6) + '...'}`
                  : `${currentUser?.firstName}`
                  }`}
              </span>
              <NewIcon icon="DOWN_ARROW" size="x-small" />
            </div>
          )}
        </Menu.Button>

        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        ></Transition>

        <Menu.Items className="absolute right-0 py-2 px-2 bg-white rounded-lg lg:w-44 xs:w-52 mt-6 mb-0 mx-0 bg-clip-padding w-auto border">
          <Menu.Item>
            <div
              onClick={() => {
                navigate('/s/profile')
              }}
              className={dropDownStyle.navList}>
              <div>
                <NewIcon
                  stroke={dropDownStyle.iconStrokeStyle}
                  icon="PROFILE"
                  size="small"
                />
              </div>
              <div id="menu_profile">{t('profile')}</div>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div
              onClick={() => {
                navigate('/s/transaction-list')
              }}
              className={dropDownStyle.navList}>
              <div>
                <NewIcon
                  stroke={dropDownStyle.iconStrokeStyle}
                  icon="TRANSACTION"
                  size="small"
                />
              </div>
              <div id="menu_transactions">{t('transactions')}</div>
            </div>
          </Menu.Item>
          <Menu.Item><div onClick={() => { navigate('/s/invite') }} className={dropDownStyle.navList}><div><NewIcon stroke={dropDownStyle.iconStrokeStyle} icon="INVITE" size="small" /></div><div id="menu_invite" >{t('invite')}</div></div></Menu.Item>
          <Menu.Item><div onClick={() => { navigate('/s/settings') }} className={dropDownStyle.navList}><div><NewIcon stroke={dropDownStyle.iconStrokeStyle} icon="SETTINGS" size="small" /></div><div id="menu_settings" >{t('settings')}</div></div></Menu.Item>
          <div className='h-16' />
          <Menu.Item>
            <div className={`${dropDownStyle.navList} hover:bg-gray-100`} onClick={() => {
              signOutActions();
              window.localStorage.clear();
              navigate('/signin');
            }}>
              <div><NewIcon icon="SIGNOUT" stroke='none' size="small" /></div><div ><span className='text-red-500 ' id="menu_signOut">{t('signOut')}</span></div></div></Menu.Item>
        </Menu.Items>
      </div>
    </Menu>
  )
}

export default UserDropdownMenu
