/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import 'tippy.js/dist/tippy.css';
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Icon from '../../Components/base/icon/icon'
import Loading from '../../Components/base/loading/loading'
import { ToolTip } from '../../Components/base/tooltip/tooltip'
import NoSession from '../../Components/NoSession'
import { VideoType } from '../../models/meetingDetails.model'
import { useGetFutureMeetingsByMeetingDetailsIdQuery } from '../../Services/meetingApi'
import NewIcon from '../../Components/base/icon/newIcons';
import { useCurrencyHook } from '../../Hooks/CurrencyHooks';
import { DisplayFormattedMonth, DisplayFormattedTime } from '../../Components/formattedDateAndTime';

  /**
   * @function ExistingMeetingList
   * @description ExistingMeetingList component to render the upcoming schedules of an event.
   * @param {Object} props Component props
   * @param {string} props.meetingDetailId Meeting detail ID
   * @param {string} props.meetingId Meeting ID
   * @param {string} props.searchParams Search parameters
   * @returns {ReactElement} ExistingMeetingList component
   */
export const ExistingMeetingList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const { meetingDetailId, meetingId } = useParams()
  const location = useLocation()
  const { data: futureMeetings, isSuccess: futureMeetingsSuccess } = useGetFutureMeetingsByMeetingDetailsIdQuery(meetingDetailId ? meetingDetailId : '', { skip: !meetingDetailId })
  const [searchParams] = useSearchParams();
  const { formatCurrency } = useCurrencyHook();  

  if (!futureMeetingsSuccess) {
    return <Loading />
  }

  if (!futureMeetings || futureMeetings.length === 0) {
    return<div className='flex text-gray-400 justify-start mt-2'>
    <NoSession>{t('noschedules')}</NoSession>
    </div> 
  }
  return (
    <div className="flex flex-col gap-1 mt-1 w-full">
      {futureMeetings?.map((futureMeeting, index) => {        return (
          <div key={futureMeeting.id} className={`grid grid-cols-12 items-center rounded-xl border py-2 mb-0.5 mr-1 cursor-pointer ${meetingId === futureMeeting.id ? 'bg-BeeMG-light-gray border-BeeMG-light-gray' : 'bg-white border-BeeMG-light-gray'}`}>
            <div data-testid='EventDateMonth' className={`flex flex-col col-span-3 text-base font-semibold text-center py-5 border-r-2 ${meetingId === futureMeeting.id ? 'border-gray-200' : 'border-BeeMG-light-gray'} `}>
              <DisplayDate date={futureMeeting.display_MonthDate}/>
              <span className="font-normal text-sm"><DisplayFormattedMonth displayDate={futureMeeting.fromDateTime} /></span>
            </div>
            <div
              id="title-editEvent"
              onClick={() => {
                navigate(`/s/events/my-events/${meetingDetailId}/schedule/${futureMeeting.id}/edit?${searchParams.toString()}`)
              }}
              className="flex flex-col justify-between leading-normal col-span-8 ml-3 text-xs md:text-base">
              <p  id='sessionTime' data-testid='sessionTime' className="font-medium text-sm tracking-tight">
                <DisplayFormattedTime displayFromTime={futureMeeting.fromDateTime} displayToTime={futureMeeting.toDateTime}/>
              </p>
              <div className="tracking-tight flex flex-col">
                <div className="overflow-hidden break-words" >
                  <span id='sessionLang' data-testid='sessionLang' className="md:text-sm">{futureMeeting.language}</span>
                </div>
                {!(futureMeeting.meetingDetails.isFreeAudioMeeting) &&
                  <div className="flex gap-1 items-center">
                    <span id='sessionCost' data-testid='sessionCost' className="md:text-sm items-center">
                      {formatCurrency(futureMeeting.cost, futureMeeting.currency)}
                      /{t('person')}
                    </span>
                  </div>
                }
              </div>
            </div>
            <div className="flex flex-col justify-end items-end leading-normal col-span-1 m-3">
              <div id={`editEvent${index}`} className="text-base gap-y-3 tracking-tight hover:underline underline-offset-2 flex flex-col place-items-center cursor-pointer mr-2">
                <div className={location.pathname.includes(`${`/s/events/my-events/${meetingDetailId}/schedule/${futureMeeting.id}/participants`}`) ? `h-8 w-8 mx-auto p-[0.35rem] items-center justify-center rounded-full bg-primary` : ""}>
                  <ToolTip tip={'viewUsers'} cursor='pointer' keyId="viewParticipants">
                    <Icon
                      icon="USER_BLACK"
                      size="small" height="medium"
                      onClick={() => {
                        navigate(`/s/events/my-events/${meetingDetailId}/schedule/${futureMeeting.id}/participants?${searchParams.toString()}`)
                      }}
                    />
                  </ToolTip>
                </div>
                {futureMeeting.meetingDetails.videoType === VideoType.Audio &&
                  <div className={location.pathname.includes(`${`/s/events/my-events/${meetingDetailId}/schedule/${futureMeeting.id}/cohosts`}`) ? `h-8 w-8 mx-auto p-[0.4rem] items-center justify-center rounded-full bg-primary` : ""}>
                    <ToolTip tip="addCohost" cursor='pointer' keyId="addCohost">
                      <NewIcon
                        icon="COHOST"
                        size="small" height="medium"
                        onClick={() => {
                          navigate(`/s/events/my-events/${meetingDetailId}/schedule/${futureMeeting.id}/cohosts?${searchParams.toString()}`)
                        }}
                      />
                    </ToolTip>
                  </div>
                }
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

/**
 * A helper component to display a given date string in the format "DD MMM YYYY".
 * If the day of the month is less than 10, it adds a leading zero.
 * @param {{ date: string }} props 
 * @return {JSX.Element} A JSX element containing the formatted date string.
 */
function DisplayDate({ date }: { date: string }) {
  return (
   <>{parseInt((date).split(" ")[1]) > 9 ? ((date).split(" ")[1]) : '0' + ((date).split(" ")[1])}</> 
  )
}