/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import Spinner from "../spinner/spinner";

/**
 * A component that displays a loading spinner.
 *
 * This component displays a spinner of a loading animation. It is used in
 * places where the app is waiting for data to load.
 *
 * @returns A JSX element that displays a loading animation.
 */
function Loader() {
    return (
        <div className="flex justify-center" id="load_data" data-testid="load_data">
            <Spinner color="#f59e0b" className="h-12" />
        </div>
    )
}

export default Loader;