/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { User } from "../../models/user.model";

/**
 * Displays the host's description.
 *
 * @param {{ user: User }} props - The component props, including the user object.
 * @returns {JSX.Element} The JSX element representing the user description.
 */
const UserDescription = ({ user }: { user: User }) => {

  return (
    <div data-testid="hostDescription" id="hostDescription" className="whitespace-normal break-words text-justify font-normal">
      <span
        className="text-base"
        dangerouslySetInnerHTML={{
          __html: user?.selfIntro ? user?.selfIntro : '',
        }}>
      </span>
    </div>
  )
}

export default UserDescription;
