/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import { CancellationPolicy } from './cancellationPolicy.model';
import {CommonEntity} from './commonEntity.model'
import {Meeting} from './meeting.model'
import {MeetingDetails} from './meetingDetails.model'
import {User} from './user.model'

export enum Accept {
  Yes = 'Yes',
  No = 'No',
}

export enum RequestType {
  FreeRequest = 'Free request',
  PaidRequest = 'Paid request',
}

export enum Select {
  Option1 = 'Option 1',
  Option2 = 'Option 2',
  Option3 = 'Option 3',
}

export enum Type {
  Invite = 'Invite',
  RejectRequest = 'Reject request',
  DeleteMeeting = 'Delete meeting',
  MeetingRequest = 'Meeting request',
  VideoRequest = 'Video request',
  Participant = 'Participant confirmation',
  DeleteMeetingMinimum = 'Delete meeting on minimum limit',
  Limit = 'Limit alert',
}

export interface MeetingRequest extends CommonEntity {
  guest: User | null
  user: User | null
  invitedUser: User | null
  startAt1DateTime: string
  endAt1DateTime: string
  startAt2DateTime?: string
  endAt2DateTime?: string
  startAt3DateTime?: string
  endAt3DateTime?: string
  participants: number
  duration: string
  cost: number
  costUSD: number
  costLocal: number
  currency: string
  accept: Accept
  select: Select
  type: Type
  requestType: RequestType
  meeting: Meeting
  dateSeen: Date
  message: string
  details: string
  meetingDetails: MeetingDetails
  cancellationPolicy : CancellationPolicy
  request: string
  reason: string
  isRequestAnonymous: boolean

   // Display Fields
   display_date: string;
   display_time: string;
   display_FromDateTime: string;
   display_ToDateTime: string;
   display_endAt: string;
   display_actualStartAt: string;
   display_actualEndAt: string;
   display_expectedEndAt: string;
   display_imageUrl: string;
   display_hostName: string;
   display_createdAt: Date;
   display_startAt1Date: string;
   display_startAt2Date: string;
   display_startAt3Date: string;
   display_startAt1Time: string;
   display_startAt2Time: string;
   display_startAt3Time: string;
   costLocal_formatted: string;
  
}
