/*
 * Copyright © 2024 @Himutsulab All Rights Reserved.
 */
import { getPreference } from '../Services/userReducer';
import { store } from '../Store';
import { getCurrencyList  } from '../Services/currencyListReducer';

/**
 * Converts a date string in UTC to the user's local time zone.
 *
 * @param {string} timeZone - The user's local time zone.
 * @param {string} dateTimeUTC - The date string in UTC format.
 * @returns {string} The date string converted to the user's local time zone.
 */
export function getUTCToUserTimeZone(timeZone, dateTimeUTC) {
  return dateTimeUTC
}

/**
 * Returns the user's timezone preference. If no preference is set, returns the browser's locale time zone.
 *
 * @returns {string} The user's timezone preference, or the browser's locale time zone if no preference is set.
 */
export function getUserTimeZone() {
  return getPreference(store.getState())?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Returns the user's preferred currency. If no preference is set, returns null.
 *
 * @returns {string | null} The user's preferred currency, or null if no preference is set.
 */
export function getUserPreferenceCurrency() {
  return getPreference(store.getState())?.currency;
}

/**
 * Retrieves the list of currencies from the application state.
 *
 * @returns {Currency[]} The list of currencies.
 */
export function getCurrency() {
  return getCurrencyList(store.getState());
}
