/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import React from 'react'
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import Avatar from "../../Components/base/avatar/avatar"
import { getCurrentUser } from "../../Services/userReducer";
import StarRating from './StarRating'
import { DisplayFormattedDate } from '../../Components/formattedDateAndTime'
import { Feedback } from '../../models/feedback.model'
import { useFeedbackHooks } from '../../Hooks/FeedbackHooks'

/**
 * A component that displays a list of feedbacks for a given meeting details ID.
 * 
 * @param {{ meetingDetailsId: string }} props - The component props.
 * @param {string} props.meetingDetailsId - The meeting details ID to fetch feedbacks for.
 * 
 * @returns {JSX.Element} The review view card component.
 */
export function ReviewsViewCard({ meetingDetailsId }: { meetingDetailsId: string }) {
  const { increaseLimit, feedbacks } = useFeedbackHooks({ meetingDetailsId })
  const { t } = useTranslation()

  if (feedbacks.length === 0) {
    return <></>
  }

  return (
    <>
      <div className="mt-5">
        <p className="text-[1.1rem] font-semibold" data-testid="txt_reviews">
          {t('eventReviews')}
        </p>
        <div
          onScroll={() => {
            increaseLimit()
          }}
          className="flex flex-col mt-2 pb-2 max-h-60 overflow-y-auto">
          {React.Children.toArray(
            feedbacks?.map((feedbackList: any, index: number) => {
              return (
                <div>
                  <ReviewsViewCardDisplay
                    feedbackList={feedbackList}
                    key={index.toString()}
                  />
                  {index === feedbacks.length - 1 ? (
                    ''
                  ) : (
                    <hr className="my-4" />
                  )}
                </div>
              )
            })
          )}
        </div>
      </div>
      <div className="mt-5">
        <hr />
      </div>
    </>
  )
}

  /**
   * A component that displays a review for a given meeting details ID.
   * 
   * @param {{ feedbackList: Feedback }} props - The component props.
   * @param {Feedback} props.feedbackList - The feedback item to display.
   * 
   * @returns {JSX.Element} The review view card component.
   */
export function ReviewsViewCardDisplay({ feedbackList }: { feedbackList: Feedback }) {

  const { t } = useTranslation()
  const currentUser = useSelector(getCurrentUser)
  const [reviewLength] = useState<number>(100)
  const [readMoreToLess, setReadMoreToLess] = useState<boolean>(true)

  return (
    <div
      className="flex flex-row items-start w-100 py-1 gap-x-2"
      key={feedbackList.id}>
      <div className="w-24 md:w-12">
        <Avatar
          size="x-small"
          images={feedbackList?.user?.image}
          avatar={feedbackList?.user?.avatar}
          anonymous={feedbackList?.user?.anonymous}
          className='p-2'
        />
      </div>
      <div className="w-full flex flex-col justify-start items-start gap-y-0.5">
        <div className="flex flex-row items-center gap-x-1">
          {feedbackList?.user?.id === currentUser.id ? (
            <p
              data-testid={`name-${feedbackList?.id}`}
              id={`name-${feedbackList?.id}`}
              className="text-md font-normal">
              {t('you')}
            </p>
          ) : (
            <div
              data-testid={`name-${feedbackList?.id}`}
              id={`name-${feedbackList?.id}`}
              className="text-md font-normal">
              {feedbackList.name}
            </div>
          )}
          <div>
            {feedbackList.rating > 0 && (
              <StarRating rating={feedbackList.rating} />
            )}
          </div>
        </div>

        <div
          data-testid={`date-${feedbackList?.id}`}
          className=" text-xs font-normal">
          {feedbackList?.meetingDetails?.title} {`${t('on')} `}
          <DisplayFormattedDate displayDate={feedbackList.createdAt} />
        </div>
        <div
          data-testid={`review-${feedbackList?.id}`}
          className="text-xs font-normal w-full justify-start">
          {feedbackList.review.length > reviewLength ? (
            <>
              {readMoreToLess ? (
                <>
                  {feedbackList.review.slice(0, reviewLength)}
                  <span
                    className="flex justify-end uppercase underline underline-offset-1 cursor-pointer"
                    data-testid="btn_readMore"
                    id="btn_readMore"
                    onClick={() => setReadMoreToLess(!readMoreToLess)}>
                    {t('readMore') + "+"}
                  </span>
                </>
              ) : (
                <>
                  {feedbackList.review}
                  <span
                    className="flex justify-end uppercase underline underline-offset-1 cursor-pointer"
                    data-testid="btn_readLess"
                    id="btn_readLess"
                    onClick={() => setReadMoreToLess(!readMoreToLess)}>
                    {t('readLess') + "+"}
                  </span>
                </>
              )}
            </>
          ) : (
            <>{feedbackList.review}</>
          )}

          {feedbackList?.reply && (
            <p className="text-xs">
              {t('replied')}-{feedbackList.reply}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
