/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import { RefundRequest } from '../models/refundRequest.model';
import { allApis } from './allApis';

/**
 * Defines the endpoints for refund request API.
 *
 * @param {object} options - Options for the API endpoints.
 * @return {{ createRefundRequest: mutation, uploadRefundAttachments: mutation , getAllRefundRequest: query }} - An object containing the defined endpoints.
 */

export const refundRequestApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    createRefundRequest: builder.mutation<RefundRequest, Partial<RefundRequest>>({
      query: (refundRequest) => ({
        url: 'refundRequest',
        method: 'POST',
        body: refundRequest,
      }),
      invalidatesTags: (result, error, refundRequest) => [
        { type: 'MeetingNotificationRemainder' },
      ],
    }),

    uploadRefundAttachments: builder.mutation<any, { id: string; files: FormData }>({
      query: ({ id, files }) => ({
        url: `refundRequest/refundRequestAttachments/${id}`,
        method: 'POST',
        body: files,
      }),
    }),

    getAllRefundRequest: builder.query<RefundRequest[], void>({
      query: () => 'refundRequest',
    }),
  }),
});

export const {
  useCreateRefundRequestMutation,
  useUploadRefundAttachmentsMutation,
  useGetAllRefundRequestQuery,
} = refundRequestApi;
