/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {eachDayOfInterval, format} from 'date-fns';
import {Ledger, TransactionType} from '../models/ledger.model';

/**
 * A custom React hook for grouping ledger data by dates.
 *
 * @return {{ groupByDates: Function }} An object containing the groupByDates function.
 */

export const useBarChatHook = () => {
  const groupByDates = (
    ledgerData: Ledger[],
    timeRange: 'thisWeek' | 'thisMonth',
  ) => {
    let startDate: any;
    if (timeRange === 'thisWeek') {
      startDate = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
    } else if (timeRange === 'thisMonth') {
      startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    } else {
      throw new Error('Invalid time range');
    }

    const grouped = ledgerData
      .filter(item => new Date(item.transactionDateTime) >= startDate)
      .reduce((acc: any, item: any) => {
        const date = new Date(item.transactionDateTime);
        const formattedDate = format(date, 'yyyy-MM-dd');
        if (!acc[formattedDate]) {
          acc[formattedDate] = {
            date: formattedDate,
            credits: [],
            creditsTotal: 0,
          };
        }
        if (item.transactionType === TransactionType.Credit) {
          acc[formattedDate].credits.push(item.transactionAmount);
          acc[formattedDate].creditsTotal += item.transactionAmount;
        }
        return acc;
      }, {});

    const dates = eachDayOfInterval({start: startDate, end: new Date()}).map(
      date => format(date, 'yyyy-MM-dd'),
    );
    const credits = dates.map(date => {
      const credit = grouped[date];
      if (credit) {
        return credit.creditsTotal;
      } else {
        return 0;
      }
    });

    return {dates, credits};
  };

  return {
    groupByDates,
  };
};
