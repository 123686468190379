/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import '../../../react-phone-input-2.css';

type Props = {
  value?: string;
  disabled?: boolean;
  placeholder: string;
  icon?: JSX.Element;
  error?: string | any;
  onChange: (value: string) => void;
};

const style = {
  container: `relative mb-1`,
  message: `text-base relative flex flex-1 w-full rounded-l-lg rounded-r-none py-1 pl-4 pr-10 bg-white text-gray-700 placeholder:text-sm placeholder-gray-400 text-base focus:outline-none focus:ring-1 focus:ring-orange-300 focus:border-transparent border border-gray-300`,
  default: `leading-5 text-base relative flex flex-1 w-full rounded-lg p-input bg-white text-gray-700 placeholder:text-sm placeholder-gray-400 text-base focus:outline-none focus:ring-1 focus:ring-orange-300 focus:border-transparent border border-gray-300`,
  dropDown: `leading-5 z-50 text-base relative flex flex-1 w-full rounded-lg py-2 px-4 bg-white text-gray-700 placeholder:text-sm placeholder-gray-400 text-base focus:outline-none focus:ring-1 focus:ring-orange-300 focus:border-transparent border border-gray-300`,
  disabled: `cursor-text bg-gray-300 text-black`,
  dot: `text-red-500 pl-0.5`,
  error: `ring-red-500 ring-1`,
  errorMessage: `text-sm text-red-500 mt-2`,
  iconContainer: `absolute flex border border-transparent  rounded-lg left-0 top-0 h-full w-10`,
  iconContainerRight: `absolute flex border border-transparent  rounded-lg right-0 top-0 h-full w-10`,
  icon: `flex items-center justify-center rounded-tl-md rounded-bl-md z-10 text-gray-400 text-lg h-full w-full bg-white `,
  iconRight: `flex items-center justify-center border-l rounded-tr-md rounded-br-md z-10 text-gray-400 text-lg h-full w-full bg-white `,
}

  /**
   * Renders a phone number field.
   *
   * @param {string} value the current phone number value
   * @param {boolean} disabled whether the field is disabled
   * @param {string} placeholder the placeholder text
   * @param {JSX.Element} icon a JSX element to render as the icon
   * @param {string|any} error an error message to display
   * @param {(value: string) => void} onChange the callback to call when the value changes
   *
   * @returns {JSX.Element}
   */
export function PhoneNumberField({
  value,
  disabled,
  placeholder,
  icon,
  error,
  onChange,
}: Props) {

  const [ipAddress, setIpAddress] = useState('');
  const [countryCode, setCountryCode] = useState('');

  useEffect(() => {
    if (!value) {
      fetch('https://api.ipify.org?format=json').then(res => res.json()).then(data => setIpAddress(data.ip));
      fetch('https://api.ipgeolocation.io/ipgeo?apiKey=' + process.env.REACT_APP_IP_GEOLOCATION_API_KEY + '&ip=' + ipAddress)
        .then(response => response.json())
        .then(data => setCountryCode(data.country_code2))
    }
  }, [value, ipAddress]);



  return (

    <div className="relative rounded-md z-30 focus:outline-none focus:ring-1 focus:ring-orange-300 focus:border-transparent border border-gray-300">
      {icon && <div className={style.iconContainerRight}>
        <div className={style.iconRight}>{icon}</div>
      </div>}
      <PhoneInput
        inputStyle={{ color : 'rgb(156 163 175)'}}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
         onChange={(value: any)=> onChange(value)}
        disableDropdown
        country={countryCode?.toLowerCase()}
      // inputStyle={{
      //   border: 'none',
      //   height: '2.5rem',
      //   borderRadius: '0.5rem',
      //   fontSize: '1rem',
      //   color: '#374151',
      // }}
      // dropdownStyle={{
      //   border: 'none',
      //   borderRadius: '0.5rem',
      // }}
      />
      {/* <input
          aria-required={dot}
          aria-invalid={!!error}
          className={`${style.default}
            ${error ? style.error : 'border-gray-300'} 
            ${disabled ? style.disabled : ''} ${className ? className : ''}`}
          disabled={disabled}
          autoComplete="off"
          maxLength={maxLength}
          id={name}
          name={name}
          type="number"
          ref={ref}
          {...rest}
        /> */}
      {error && <ErrorIcon />}
    </div>


  )
}

/**
 * A small red error icon.
 *
 * @returns {JSX.Element} The error icon.
 */
const ErrorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    fill="currentColor"
    className="absolute right-2 -mt-6 text-red-500"
    viewBox="0 0 1792 1792"
  >
    <path d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z" />
  </svg>
)