/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { FC } from 'react'
import { Meeting } from '../../models/meeting.model'
import Whirligig from '../base/carousel/whirligig/whirligig'
import Icon from '../base/icon/icon'
import useWindowDimensions from '../base/carousel/whirligig/utils'
import { CreatorCard } from './CreatorCard'
import { useTranslation } from 'react-i18next'

interface EventsProps {
  title: string
  meetings?: Meeting[]
}

  /**
   * A component that renders a list of creator events, divided into different sections
   * based on the meeting start time and whether the event is free or not.
   *
   * @param {{ title: string, meetings?: Meeting[] }}
   * @returns {JSX.Element}
   */
const CreatorEventsList: FC<EventsProps> = (props: EventsProps) => {
  const { t } = useTranslation()
  const style = {
    title: `text-2xl font-medium text-gray-400 my-1`,
  }
  const cardWidth = 200;
  let whirligig: any
  const next = () => whirligig.next()
  const prev = () => whirligig.prev()
  const { width } = useWindowDimensions()

  let numberOfCards = Math.floor(width / cardWidth)
  let gap = '0.5rem'

  return (
    <div className={style.title}>
      <div className="flex justify-between ">
        <div data-testid={props.title} id={`eventsTitle_${props.title.split(' ').join('')}`} className="font-semibold text-gray-400 mb-4 text-2xl lg:text-[1.8rem]">
          {t(props.title)}
        </div>
        {props.meetings && props.meetings.length >= numberOfCards && (
          <div id={`arrow_${props.title.split(' ').join('')}`} className='mr-3'>
            <button onClick={prev} className="mr-5">
              <Icon icon="LEFT" size="medium" height='large' />
            </button>
            <button onClick={next}>
              <Icon icon="RIGHT" size="medium" height='large' />
            </button>
          </div>
        )}
      </div>
      <Whirligig
        visibleSlides={numberOfCards}
        gutter={gap}
        ref={_whirligigInstance => {
          whirligig = _whirligigInstance
        }}>
        {props.meetings &&
          props.meetings.map((meeting: Meeting, index: number) => {
            return <CreatorCard key={meeting.id} meeting={meeting} keyId={index} eventsTitle={props.title} meetingDetails={meeting.meetingDetails} />
          })}
      </Whirligig>
    </div>
  )
}

export default CreatorEventsList
