/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useTranslation } from "react-i18next"
import { Ledger } from "../../models/ledger.model"
// import useWindowDimensions from "../../Components/base/carousel/whirligig/utils"
import { useTransactionCheckHooks } from "../../Hooks/TransactionHook"
import { useCurrencyHook } from "../../Hooks/CurrencyHooks"


    /**
     * Component to display refund details in the transaction history.
     * @param {Ledger} transaction - The transaction details to display.
     * @returns {JSX.Element} - The component element.
     */
export default function RefundType({ transaction }: { transaction: Ledger }) {
    const { t } = useTranslation()
    // const { width } = useWindowDimensions()
    const { creditCostDetail, creditCost, amountPaid } = useTransactionCheckHooks(transaction)
    const { formatCurrencySymbol } = useCurrencyHook()

    /**
     * Helper function to remove the floating point from a number or string.
     * @param {number | string} value - The value to remove the floating point from.
     * @returns {number | string} - The value with the floating point removed.
     */
    const removeFloatingPoint = (value: any) => {
        if (typeof value === 'number') {
            return value.toFixed(0)
        } else if (typeof value === 'string') {
            return value.split('.')[0]
        }
    };

    let amountPaidValue: string | undefined = '0';
    let platformFeeValue: string | undefined = '0';
    let inconvenienceFeeValue: string | undefined = '0';
    let processingFeeValue: string | undefined = '0';
    let totalPaidValue: string | undefined = '0';

    if (transaction.transactionDetails) {
        transaction.transactionDetails.forEach((detail: { KEY: string; received_amount: any; guest: any; amount: number }, index: any) => {
            if (detail.KEY === "total-received") {
                amountPaidValue = removeFloatingPoint(Number(detail.received_amount) / (detail.guest ?? 1));
            } else if (detail.KEY === "cancelation-fee") {
                platformFeeValue = removeFloatingPoint(detail.amount);
            } else if (detail.KEY === "inconvinence-fee") {
                inconvenienceFeeValue = removeFloatingPoint(detail.amount);
            } else if (detail.KEY === "processing-fee") {
                processingFeeValue = removeFloatingPoint(Number(detail.amount) / (detail.guest ?? 1));
            } else if (detail.KEY === "total-paid") {
                totalPaidValue = removeFloatingPoint(detail.amount);
            }
        });
    }
    else if (!transaction.transactionDetails) {
        amountPaidValue = removeFloatingPoint(amountPaid);
        platformFeeValue = ' - ';
        inconvenienceFeeValue = ' - ';
        processingFeeValue = removeFloatingPoint(creditCostDetail);
        totalPaidValue = removeFloatingPoint(creditCost);
    }
    return (
        <>
            {transaction?.isDeduction ? (
                // <>
                //     <div className="flex flex-col text-left md:w-full sm:w-3/4">
                //         <div className="text-sm font-semibold border-t pt-1 md:pl-48 sm:pl-36">
                //             <div className="grid grid-cols-2 gap-x-4 pl-4">
                //                 <span data-testid='txt_amtPaid' >{t('amountPaid')}</span>
                //                 <span data-testid='amtPaidVal' className="text-right pr-24">{amountPaidValue}</span>
                //             </div>
                //             <div className="grid grid-cols-2 gap-x-4 pl-4">
                //                 <span data-testid='txt_platformFee' >{t('platformFee')}<span>({transaction?.meeting?.meetingDetails?.cancellationPolicy?.deductionPercentage}{' '} %)</span></span>
                //                 <span data-testid='platformFeeVal' className="text-right pr-24">{platformFeeValue}</span>
                //             </div>
                //             <div className="grid grid-cols-2 gap-x-4 pl-4">
                //                 <span data-testid='txt_inconvenienceFee' >{t('inconvenienceFee')}<span>({transaction?.meeting?.meetingDetails?.cancellationPolicy?.inconvenienceTax}{' '} %)</span></span>
                //                 <span data-testid='inconvenienceFeeVal' className="text-right pr-24">{inconvenienceFeeValue}</span>
                //             </div>
                //             <div className="grid grid-cols-2 gap-x-4 pl-4">
                //                 <span data-testid='txt_processingFee' >{t('processingFee')}</span>
                //                 <span data-testid='processingFeeVal' className="text-right pr-24">{processingFeeValue}</span>
                //             </div>
                //             <div className="grid grid-cols-2 gap-x-4 pl-4">
                //                 <span data-testid='txt_balanceTotal' >{t('balanceTotal')}</span>
                //                 <span data-testid='ttlPaidVal' className="text-right pr-24 items-center font-bold uppercase">{totalPaidValue}</span>
                //             </div>
                //         </div>
                //     </div>
                // </>
                <div className="flex flex-wrap md:px-2 justify-between p-3 md:ml-48 md:mr-24 bg-gray-100 rounded">
                    <div className="bg-gray-100 text-sm pl-2 pr-5 w-full md:w-auto mb-4 md:mb-0 text-center">
                        {`${t('amountRefundedAfter')} ${transaction?.meeting?.meetingDetails?.cancellationPolicy?.deductionPercentage}% ${t('deduction')}`}
                    </div>
                    <div className="bg-gray-100 text-sm w-full md:w-auto text-center">
                        {t('totalAmountCredited')}: {formatCurrencySymbol(transaction.currency)}{removeFloatingPoint(transaction.transactionAmount)}{' '}
                    </div>
                </div>
            ) : null
            }

            {!transaction?.isDeduction ? (
                <div className="flex flex-wrap md:px-2 justify-between p-3 md:ml-48 md:mr-24 bg-gray-100 rounded">
                    <div data-testid='txt_amtRefund' className="bg-gray-100 text-sm pl-2 pr-5 w-full md:w-auto mb-4 md:mb-0 text-center">
                        {t('amountRefundedAfterCancellationCharges')}
                    </div>
                    {/* Processing fee is not stored anywhere */}
                    {/* {transaction.transactionDetails &&
                        transaction.transactionDetails.map((detail: any, index: number) => (
                            <div key={"transactionDetails" + index} className="bg-gray-100 text-sm pr-5 w-full md:w-auto mb-3 md:mb-0">
                                {detail.KEY === "processing-fee" && (
                                    <span data-testid='txt_processingFee' >
                                        {t('processingFee')}: {detail.KEY === "processing-fee" && (removeFloatingPoint((detail.amount) / (detail.guest ?? 1)))}
                                    </span>
                                )}
                            </div>
                        ))} */}
                    <div className="bg-gray-100 text-sm w-full md:w-auto text-center" data-testid={`amount-${transaction.id}`}>
                        {t('totalAmountCredited')}: {formatCurrencySymbol(transaction.currency)}{removeFloatingPoint(transaction.transactionAmount)}{' '}
                    </div>
                </div>
            ) : null}
        </>
    )
}