/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {useTranslation} from 'react-i18next'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  HatenaIcon,
  HatenaShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share'
import Icon from '../Components/base/icon/icon'
import {Config} from '../Config'
import {Popover} from 'react-tiny-popover'
import {useState} from 'react'
import {ToolTip} from '../Components/base/tooltip/tooltip'

/**
 * A component for sharing a URL to social media platforms.
 *
 * @param {object} props - The component props.
 * @param {string} props.shareData - The data to be shared.
 * @param {'shareHostProfile' | 'shareEvent'} [props.sharingType] - The type of sharing.
 * @param {string} [props.meetingDetailsId] - The ID of the meeting.
 * @param {string} [props.userId] - The ID of the user.
 * @param {string} [props.hostName] - The name of the host.
 *
 * @return {JSX.Element} The component JSX element.
 */
function Share({
  shareData,
  sharingType,
  meetingDetailsId,
  userId,
  hostName,
}: {
  shareData: string
  sharingType?: 'shareHostProfile' | 'shareEvent'
  meetingDetailsId?: string
  userId?: string
  hostName?: string
}) {
  let preUrl = window.location.origin
  const url =
    sharingType === 'shareEvent'
      ? `${preUrl}/s/meeting/${meetingDetailsId}`
      : `${preUrl}${Config.API_URL}/s/p/${userId}`
  const {t} = useTranslation()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <div>
      <Popover
        onClickOutside={() => setIsPopoverOpen(false)}
        isOpen={isPopoverOpen}
        positions={['bottom', 'left']}
        align={'end'}
        padding={10}
        containerClassName="z-20"
        content={
          <div
            onClick={() => setIsPopoverOpen(false)}
            className="p-3 w-96 rounded-lg border bg-white">
            <TwitterShareButton
              className="mx-2"
              url={url}
              hashtags={['hashtag1', 'hashtag2']}>
              <ToolTip cursor="pointer" tip="X" keyId="Twitter">
                <XIcon size={32} round />
              </ToolTip>
            </TwitterShareButton>
            <WhatsappShareButton className="m-2" url={url}>
              <ToolTip cursor="pointer" tip="WhatsApp" keyId='WhatsApp'>
                <WhatsappIcon size={32} round />
              </ToolTip>
            </WhatsappShareButton>
            <InstapaperShareButton className="m-2" url={url}>
              <ToolTip cursor="pointer" tip="Instagram" keyId='Instagram'>
                <InstapaperIcon size={32} round />
              </ToolTip>
            </InstapaperShareButton>
            <EmailShareButton
              className="m-2"
              url={url}
              subject={`Welcome to BeeMG!`}
              body={
                sharingType === 'shareEvent'
                  ? `Join us on the event "${shareData}", Hosted by ${hostName} \nClick here to view more details.\n`
                  : undefined
              }>
              <ToolTip cursor="pointer" tip="Email" keyId='Email'>
                <EmailIcon size={32} round />
              </ToolTip>
            </EmailShareButton>
            <FacebookShareButton className="m-2" url={url}>
              <ToolTip cursor="pointer" tip="Facebook" keyId='Facebook'>
                <FacebookIcon size={32} round />
              </ToolTip>
            </FacebookShareButton>
            <LineShareButton className="m-2" url={url}>
              <ToolTip cursor="pointer" tip="Line" keyId='Line'>
                <LineIcon size={32} round />
              </ToolTip>
            </LineShareButton>
            <LinkedinShareButton className="m-2" url={url}>
              <ToolTip cursor="pointer" tip="LinkedIn" keyId='LinkedIn'>
                <LinkedinIcon size={32} round />
              </ToolTip>
            </LinkedinShareButton>
            <TelegramShareButton className="m-2" url={url}>
              <ToolTip cursor="pointer" tip="Telegram" keyId='Telegram'>
                <TelegramIcon size={32} round />
              </ToolTip>
            </TelegramShareButton>
            <TumblrShareButton className="m-2" url={url}>
              <ToolTip cursor="pointer" tip="Tumblr" keyId='Tumblr'>
                <TumblrIcon size={32} round />
              </ToolTip>
            </TumblrShareButton>
            <ViberShareButton className="m-2" url={url}>
              <ToolTip cursor="pointer" tip="Viber" keyId='Viber'>
                <ViberIcon size={32} round />
              </ToolTip>
            </ViberShareButton>
            <RedditShareButton className="m-2" url={url}>
              <ToolTip cursor="pointer" tip="Reddit" keyId='Reddit'>
                <RedditIcon size={32} round />
              </ToolTip>
            </RedditShareButton>
            <HatenaShareButton className="m-2" url={url}>
              <ToolTip cursor="pointer" tip="Hatena" keyId='Hatena'>
                <HatenaIcon size={32} round />
              </ToolTip>
            </HatenaShareButton>
            <PinterestShareButton className="m-2" url={url} media="beeMG">
              <ToolTip cursor="pointer" tip="Pinterest" keyId='Pinterest'>
                <PinterestIcon size={32} round />
              </ToolTip>
            </PinterestShareButton>
            <div className="p-2">
              <hr />
            </div>
            <span
              onClick={() => {
                navigator.clipboard.writeText(url).then(() => {
                  setIsPopoverOpen(false)
                })
              }}
              data-testid="link_copyURL"
              className="text-sm font-normal m-2 cursor-pointer">
              {t('copyURL')}
            </span>
          </div>
        }>
        <div
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          className="flex flex-row items-center gap-x-1 cursor-pointer">
          <Icon icon="SHARE" size="small" />
          <div
            id="title-share"
            data-testid="title-share"
            className="text-md font-normal">
            {t('shareEvent')}
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default Share
