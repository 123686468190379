/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import React, {forwardRef, useImperativeHandle} from 'react'

interface AccordionProps {
  children: React.ReactNode
  defaultPanel?: string
}

interface AccordionContextProps {
  selected?: string
  toggleItem?: (id: string) => void
}

interface AccordionItemProps extends React.HTMLAttributes<HTMLElement> {
  toggle?: string;
  children: React.ReactNode;
  color: 'card' | 'indigo' | 'green' | 'filter';
  keyId?: string;
  pointing?: boolean
}

interface AccordionPanelProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  id: string;
}

const style = {
  item: {
    card: `focus:outline-none text-black md:p-4 p-2 flex`,
    filter: `py-1 flex`,
    indigo: `block focus:outline-none bg-indigo-800 text-white border-b my-2 p-3`,
    green: `block focus:outline-none bg-green-800 text-white border-b my-2 p-3`,
  },
  panel: `overflow-hidden md:overflow-x-hidden transition-height ease duration-300 text-gray-600`,
};

/* Logic */
const Context = React.createContext<AccordionContextProps>({});

export const Accordion = forwardRef(({ children, defaultPanel }: AccordionProps, ref) => {
  useImperativeHandle(
    ref, () => ({
  /**
   * Set the selected toggle ID.
   * @param {string} id the ID of the toggle to select
   */
      setSelectedToggle(id: string) {
        setSelected(id);
      },
      /**
       * Returns the currently selected toggle ID.
       * @return {string} the ID of the toggle currently selected
       */
      getSelectedToggle() {
        return selected
      }
    }),
  )
  const [selected, setSelected] = React.useState(defaultPanel || '');

  const toggleItem = React.useCallback((id: string) => {
    setSelected((prevState) => (prevState !== id ? id : ''));
  }, []);

  return (
    <Context.Provider value={{ selected, toggleItem }}>
      {children}
    </Context.Provider>
  );
})


//custom hook to consume all accordion values
const useAccordion = () => React.useContext(Context);

  /**
   * The AccordionItem component.
   *
   * @typedef {Object} AccordionItemProps
   * @property {string} color - The color of the item.
   * @property {string} toggle - The toggle ID of the item.
   * @property {React.ReactNode} children - The content of the item.
   * @property {string} keyId - The key ID of the item.
   * @property {boolean} pointing - If true, the item is a button.
   *
   * @returns {React.ReactElement} The rendered AccordionItem component.
   */
export function AccordionItem({ color, toggle, children, keyId, pointing }: AccordionItemProps) {
  const {toggleItem } = useAccordion();
  return (
    <>
      <div
        id={`div_${keyId}`}
        data-testid={`div_${keyId}`}
        role={pointing===true ? "button" : ""}
        // @ts-ignore
        onClick={() => toggleItem(toggle)}
        className={style.item[color]}>
        {children}
      </div>
    </>
  )
}

  /**
   * The AccordionPanel component.
   *
   * @typedef {Object} AccordionPanelProps
   * @property {string} id - The ID of the panel.
   * @property {React.ReactNode} children - The content of the panel.
   *
   * @returns {React.ReactElement} The rendered AccordionPanel component.
   */
export function AccordionPanel({ children, id }: AccordionPanelProps) {
  const { selected } = useAccordion();
  const ref = React.useRef<HTMLDivElement>();
  const inlineStyle =
    selected === id ? { height: "auto" } : { height: 0 };

  return (
    // @ts-ignore
    <div ref={ref} id={id} className={style.panel} style={inlineStyle}>
      <div className='pb-3'>
        {children}
      </div>
    </div>
  );
}

  /**
   * The AccordionBorder component.
   *
   * @typedef {Object} AccordionBorderProps
   * @property {React.ReactNode} children - The content of the border.
   * @property {string} [id] - The ID of the border.
   * @property {string} [keyId] - The key ID of the border.
   *
   * @returns {React.ReactElement} The rendered AccordionBorder component.
   */
export function AccordionBorder({ children, id, keyId }: {children: React.ReactNode, id?: string; keyId?: string}) {
  const { selected } = useAccordion();
  return <div data-testid={`eventCard-${keyId}`} id={`eventCard-${keyId}`} className={`border border-transparent hover:border-gray-200 rounded-2xl w-auto ${ (id && selected === id) && ' border-gray-200 '}`  }>{children}</div>;
}


