/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { format } from "date-fns";
import { useTranslation } from "react-i18next"
import { ChatDateLabel } from "../../Store/Chat/ChatModel"
import { DisplayFormattedDate } from "../../Components/formattedDateAndTime";

/**
 * A component that renders a date label for a chat message.
 *
 * The component renders a horizontal line with a date label in the middle.
 * The date label displays the date of the message in the format "MMMM d, yyyy".
 * If the message is from today, the date label displays "Today".
 *
 * @param {{ msg: ChatDateLabel }} props The component props.
 * @param {ChatDateLabel} props.msg The chat message date label.
 *
 * @returns {JSX.Element} The rendered component.
 */
const DateLabel = ({ msg }: { msg: ChatDateLabel }) => {
  const { t } = useTranslation()

  const isToday = () => format(new Date(), "MMMM d, yyyy") === msg.date;

  return (
    <div className="flex flex-row gap-0 items-center justify-center">
      <div className="h-[0.5px] opacity-20 bg-gray-400 flex flex-1" />
      <div className="my-3 mx-auto leading-none px-2 py-1.5 text-xxs text-gray-600 font-medium shadow-sm">
        {isToday() ? t('today') : <DisplayFormattedDate displayDate={msg.date}/>}
      </div>
      <div className="h-[0.5px] opacity-20 bg-gray-400 flex flex-1" />
    </div>
  )
}

export default DateLabel;