/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useGetSettingValue } from '../Services/settingReducer';

/**
 * A component for displaying a video.
 *
 * The component renders a video element with controls, muted, loop, and autoplay.
 * The video source is set to the value of the videoFile prop, prefixed with the
 * value of the IMAGE_URL setting.
 *
 * @param {{videoFile: string}} props - The props of the component.
 * @param {string} props.videoFile - The name of the video file to display.
 *
 * @returns {ReactElement} - The rendered component.
 */
export function VideoDisplay({videoFile}: {videoFile: string}) {
  const imageURL = useGetSettingValue('IMAGE_URL')

  return (
    <>
      <video
        key={videoFile}
        controls
        muted
        loop
        autoPlay
        className="flex rounded-xl h-40 w-64 md:h-40 md:w-64 lg:h-40 lg:w-64"
        style={{objectFit: 'cover'}}>
        <source
          data-testid={`video-${videoFile}`}
          src={`${imageURL}/${videoFile}`}
          type="video/mp4"
        />
      </video>
    </>
  )
}

export default VideoDisplay
