/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import {useTranslation} from 'react-i18next'

/**
 * A functional component that displays a login successful message
 *
 * This component renders a div with a centered login successful message
 * translated from the i18next translation file.
 *
 * @returns {JSX.Element} A JSX element to be rendered.
 */
function LogInSuccess() {
  const {t} = useTranslation()

  return (
    <>
      <div className="flex justify-center text-3xl mt-4">
        {t('loginSuccessful')}
      </div>
    </>
  )
}

export default LogInSuccess
