/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useTranslation } from "react-i18next"
import { Ledger, TransactionType } from "../../models/ledger.model"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { getPreference } from "../../Services/userReducer"
import { useTransactionCheckHooks } from "../../Hooks/TransactionHook"
import { AccordionBorder, AccordionItem, AccordionPanel } from "../../Components/base/accordion/accordion"
import { EventCard } from "../EventsCard/EventCard"
import CardImagePlaceHolder from "../EventsCard/CardImagePlaceHolder"
import CardTitle from "../EventsCard/CardTitle"
import StatusGreen from "./StatusGreen"
import TransactionAmount from "./TransactionAmount"
import DisplayTransactionDate from "./DisplayTransactionDate"
import DisplayTransactionStatus from "./DisplayTransactionStatus"
import DisplayTransactionDescription from "./DisplayTransactionDescription"
import { formatDateToTimezone } from "../../Utils/datetime"
import RefundType from "./RefundType"
import { useCurrencyHook } from "../../Hooks/CurrencyHooks"
import { DisplayFormattedDate, DisplayFormattedTime } from "../../Components/formattedDateAndTime"

/**
 * A component to display credited transaction details in the transaction history.
 * @param {Ledger} transaction - The transaction details to display.
 * @returns {JSX.Element} - The component element.
 */
export default function CreditedCard({ transaction }: { transaction: Ledger }) {
    const { t } = useTranslation()
    const { transactionType } = useParams()
    const preference = useSelector(getPreference)
    const { meetingTypeRefund } = useTransactionCheckHooks(transaction)
    const { formatCurrencySymbol, formatCurrency } = useCurrencyHook()

    /**
     * A helper function to remove floating point from a number or string.
     * @param {number | string} value - The value to remove the floating point from.
     * @returns {number | string} - The value with the floating point removed.
     */
    const removeFloatingPoint = (value: any) => {
        if (typeof value === 'number') {
            return value.toFixed(0)
        } else if (typeof value === 'string') {
            return value.split('.')[0]
        }
    }

    if (transactionType === 'debit') {
        return null
    }

    return (
      <AccordionBorder
        keyId={transaction.id}
        id={transaction.id}
        key={transaction.id}>
        <AccordionItem
          keyId={transaction.id}
          toggle={`panel-${transaction.id}`}
          color="card">
          <EventCard keyId={transaction.id}>
            <EventCard.Image>
              <CardImagePlaceHolder
                images={transaction?.meeting?.meetingDetails?.image}
              />
            </EventCard.Image>
            <EventCard.Title>
              <CardTitle
                keyId={transaction.id}
                meetingTitle={
                  transaction?.meeting?.meetingDetails?.title &&
                  transaction?.meeting?.meetingDetails?.title.length > 40
                    ? `${transaction?.meeting?.meetingDetails?.title.slice(
                        0,
                        40,
                      )}...`
                    : transaction?.meeting?.meetingDetails?.title
                }
              />
            </EventCard.Title>
            <EventCard.SubTitle>
              <div className="font-normal">
                {t('eventOn')}{' '}
                <DisplayFormattedDate
                  displayDate={transaction.display_meetingDate}
                />{' '}
                -{' '}
                <DisplayFormattedTime
                  displayFromTime={transaction?.meeting?.fromDateTime}
                  displayToTime={transaction?.meeting?.toDateTime}
                />
              </div>
            </EventCard.SubTitle>
            <EventCard.SubTitle1>
              <DisplayTransactionDate transaction={transaction} />
            </EventCard.SubTitle1>
            <EventCard.Actions>
              <div className="flex md:flex-col text-end items-end gap-3 md:gap-0">
                <StatusGreen>
                  <span className="flex">
                    <TransactionAmount transaction={transaction} symbol={'+'} />
                  </span>
                </StatusGreen>
              </div>
            </EventCard.Actions>
          </EventCard>
        </AccordionItem>
        <AccordionPanel id={`panel-${transaction.id}`}>
          <div className="w-full">
            <hr />
            <div className="flex flex-col w-full mx-auto items-center justify-between text-sm md:text-base">
              <div
                className={`flex flex-row text-black w-full justify-between items-center text-normal`}>
                <DisplayTransactionStatus
                  transaction={transaction}></DisplayTransactionStatus>
                <div className="flex flex-col items-end capitalize pr-4">
                  <DisplayTransactionDescription
                    transaction={transaction}
                    cardType={TransactionType.Credit}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center mb-2">
              {((transaction?.transactionType === 'Credit' &&
                !transaction?.previousTransactions) ||
                meetingTypeRefund === 'TYPE 1' ||
                meetingTypeRefund === 'TYPE 2') && (
                <div className="w-full rounded-lg">
                  {transaction?.transactionType === 'Credit' &&
                    !transaction?.previousTransactions && (
                      <>
                        <div className="flex flex-row justify-around">
                          <div className="flex flex-col text-right">
                            <div className="grid grid-cols-5 gap-x-12 ml-28 p-3 text-sm font-semibold bg-gray-100 rounded text-end mb-2">
                              <div>{t('transactionDate')}</div>
                              <div className="text-left">
                                {t('participants')}
                              </div>
                              <div>{t('processingFee')}</div>
                              <div>
                                {t('debit')}(
                                {formatCurrencySymbol(preference?.currency)})
                              </div>
                              <div>
                                {t('credit')}(
                                {formatCurrencySymbol(preference?.currency)})
                              </div>
                            </div>
                            {(
                              transaction?.transactionDetails
                                ?.transactionDetails as any[]
                            )?.map((item, index) => (
                              <div
                                key={'transactionTransactionDetails' + index}>
                                {item.transactionType ===
                                  TransactionType.Debit && (
                                  <div className="grid grid-cols-5 gap-x-12 ml-28 text-sm text-end mb-2">
                                    <div className="pl-3">
                                      {formatDateToTimezone(item.transactionDateTime)?.toString()}
                                    </div>
                                    <div className="justify-left text-left pl-2">
                                      {item?.user?.firstName}
                                    </div>
                                    <div className="text-red-500 text-center">
                                      {removeFloatingPoint(item.processingFee)}
                                    </div>
                                    <div>-</div>
                                    <div className="text-BeeMG-green pr-4">
                                      {removeFloatingPoint(item.transactionAmount)}
                                    </div>
                                  </div>
                                )}
                                {item.transactionType ===
                                  TransactionType.Credit && (
                                  <div className="grid grid-cols-5 gap-x-12 ml-28 text-sm text-end mb-2">
                                    <div className="pl-3">
                                      {formatDateToTimezone(item.transactionDateTime)?.toString()}
                                    </div>
                                    <div className="justify-left text-left pl-2 line-through">
                                      {item?.user?.firstName}
                                    </div>
                                    <div className="text-red-500 text-center">
                                      {removeFloatingPoint(item?.processingFee) ?? '-'}
                                    </div>
                                    <div className="text-red-500">
                                      {removeFloatingPoint(item.transactionAmount)}
                                    </div>
                                    <div className="text-BeeMG-green pr-4">
                                      {removeFloatingPoint(item.processingFee) ?? '-'}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="flex flex-col text-left md:w-full sm:w-3/4">
                          <div className="text-sm font-semibold border-t pt-1 md:pl-48 sm:pl-36">
                            <div className="grid grid-cols-2 gap-x-4 pl-4">
                              <span>{t('total')}</span>
                              <span className="text-right pr-24">
                                
                              {formatCurrency(
                                transaction.transactionDetails.balanceTotal,
                                transaction.currency, true
                              )}
                              </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 pl-4">
                              <span>
                                {transaction.transactionDetails
                                  ?.hostCommissionPercentage
                                  ? t('platformFee') +
                                    ' (' +
                                    transaction.transactionDetails
                                      ?.hostCommissionPercentage +
                                    '%)'
                                  : t('platformFee') +
                                    ' (' +
                                    (formatCurrency(
                                      transaction.transactionAmount,
                                      transaction.currency,
                                    )) +
                                    '%)'}
                              </span>
                              <span className="text-right pr-24">
                                {formatCurrency(
                                  transaction.transactionDetails
                                    .platformCommission,transaction.currency,true
                                )}
                              </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 pl-4">
                              <span>{t('inconvenienceFee')}</span>
                              <span className="text-right pr-24">
                                {formatCurrency(
                                  transaction.transactionDetails.directToHost +
                                    transaction.transactionDetails
                                      .directToPlatform,transaction.currency,true
                                )}
                               
                              </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 pl-4">
                              <span>{t('hostCancellationFee')}</span>
                              <span className="text-right pr-24">
                                {formatCurrency(
                                  transaction.transactionDetails.directToHost,transaction.currency, true
                                )}
                              </span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 pl-4">
                              <span>{t('netTotal')}</span>
                              <span className="text-right pr-24">
                                {formatCurrency(
                                  transaction.transactionDetails
                                    .finalAmountToHost,transaction.currency, true
                                )}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div></div>
                      </>
                    )}
                  {meetingTypeRefund === 'TYPE 1' && (
                    <RefundType transaction={transaction} />
                  )}
                  {meetingTypeRefund === 'TYPE 2' && (
                    <RefundType transaction={transaction} />
                  )}
                </div>
              )}
            </div>
          </div>
        </AccordionPanel>
      </AccordionBorder>
    )
}