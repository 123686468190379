/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {
  useGetNearUsersQuery,
  useGetRecentUsersQuery,
  useGetUsersLikeYouQuery,
} from '../Services/userApi';
import {getCurrentUser} from '../Services/userReducer';
import {User} from '../models/user.model';
import {UserFilter} from '../models/userFilter.model';

/**
 * A custom React hook for managing collaborator-related data and functionality.
 *
 * It fetches near users, recent users, and users like the current user, and provides
 * filtered results based on a search text. It also provides a form registration
 * and value setting functionality.
 *
 * @return {object} An object containing the fetched data, filtered results, and
 *                  form registration and value setting functions.
 */

export const useCollaboratorHooks = () => {
  const currentUser = useSelector(getCurrentUser);
  const filter: UserFilter = {currentUserId: currentUser?.id} as UserFilter;

  const [nearUsersDisplay, setNearUsersDisplay] = useState<User[]>();
  const [recentUsersDisplay, setRecentUsersDisplay] = useState<User[]>();
  const [usersLikeYouDisplay, setUsersLikeYouDisplay] = useState<User[]>();
  const [filterText, setFilterText] = useState<string>();
  const {register, watch, setValue} = useForm<any>({});

  const {
    data: nearUsers,
    isSuccess: nearUsersSuccess,
    refetch: collabPeopleNearToMeRefetch,
    isFetching: collabPeopleNearToMeRefetching,
  } = useGetNearUsersQuery(filter);
  const {
    data: recentUsers,
    isSuccess: recentUsersSuccess,
    refetch: collabPeopleMetRecentRefetch,
    isFetching: collabPeopleMetRecentRefetching,
  } = useGetRecentUsersQuery(filter);
  const {
    data: usersLikeYou,
    isSuccess: usersLikeYouSuccess,
    refetch: collabPeopleLikeYouRefetch,
    isFetching: collabPeopleLikeYouRefetching,
  } = useGetUsersLikeYouQuery(filter);

  useEffect(() => {
    if (watch('name')) {
      setFilterText(watch('name'));
    } else {
      setFilterText('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('name')]);

  const filterUser = useCallback((users: User[], filterText: string) => {
    return users.filter(user => {
      if (user.anonymous) {
        return user.nickName
          ?.toLocaleLowerCase()
          .includes(filterText.toLocaleLowerCase());
      }
      return user.firstName
        .toLocaleLowerCase()
        .includes(filterText.toLocaleLowerCase());
    });
  }, []);

  useEffect(() => {
    const filteredNearUsers = filterUser(
      nearUsers?.results ?? [],
      filterText ?? '',
    );
    const filteredRecentUsers = filterUser(
      recentUsers?.results ?? [],
      filterText ?? '',
    );
    const filteredUsersLikeYouUsers = filterUser(
      usersLikeYou?.results ?? [],
      filterText ?? '',
    );
    setNearUsersDisplay(filteredNearUsers);
    setRecentUsersDisplay(filteredRecentUsers);
    setUsersLikeYouDisplay(filteredUsersLikeYouUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nearUsers, filterText]);

  const nearUsersToDisplay = filterText ? nearUsersDisplay : nearUsers?.results;
  const recentUsersToDisplay = filterText
    ? recentUsersDisplay
    : recentUsers?.results;
  const usersLikeYouToDisplay = filterText
    ? usersLikeYouDisplay
    : usersLikeYou?.results;

  return {
    nearUsers,
    recentUsers,
    usersLikeYou,
    nearUsersToDisplay,
    nearUsersSuccess,
    recentUsersToDisplay,
    recentUsersSuccess,
    usersLikeYouToDisplay,
    register,
    setValue,
    usersLikeYouSuccess,
    collabPeopleNearToMeRefetch,
    collabPeopleMetRecentRefetch,
    collabPeopleLikeYouRefetch,
    collabPeopleNearToMeRefetching,
    collabPeopleMetRecentRefetching,
    collabPeopleLikeYouRefetching,
  };
};
