/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { updateAppOffline } from "../../Services/utilReducer";
import { useAppDispatch } from "../../Store/hooks";
import { getIsAppOffline } from './../../Services/utilReducer';
import { useAppSelector } from './../../Store/hooks';

  /**
   * Checks if the app is currently offline. This hook is used to listen
   * to online and offline events of the browser and update the app's
   * offline status accordingly.
   * @returns {Object} An object containing the boolean value of isAppOffline
   */
export const useCheckOnlineStatus = () => {
  const dispatch = useAppDispatch()
  const isAppOffline = useAppSelector(getIsAppOffline)

  useEffect(() => {

    /**
     * This function is called when the browser's online status changes.
     * It checks the current online status of the browser and updates the
     * app's offline status accordingly by dispatching the updateAppOffline
     * action.
     */
    function changeStatus() {
      dispatch(updateAppOffline(!navigator.onLine));
    }

    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);

    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };

  }, []);

  return { isAppOffline }
}