/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import toast from 'react-hot-toast'
import Icon from './base/icon/icon'

/**
 * Displays a success toast notification with a given message.
 *
 * @param {string} message The message to be displayed in the toast.
 */
export function toastSuccess(message) {
  toast.success(message, {
    id: message ? message.toString() : undefined,
    duration: 6000,
    position: 'top-center',
    style: {backgroundColor: '#008000', color: '#FFFFFF'},
  })
}

/**
 * Displays an error toast notification with a given message.
 *
 * It returns the message to be displayed in the toast.
 */
export function toastError(message) {
  toast.error(message, {
    id: message ? message.toString() : undefined,
    duration: 6000,
    position: 'top-center',
    style: {backgroundColor: '#FF0000', color: '#FFFFFF'},
  })
}

/**
 * ToastInfo
 * @returns toast with Icon and Unique ID
 * @param string message
 * @param string icon
 */
export function toastInfo(message, icon) {
  toast(message, {
    id: message ? message.toString() : undefined,
    duration: 6000,
    icon: icon ? <Icon icon={icon} size={'large'} /> : undefined,
    position: 'top-center',
    style: {backgroundColor: '#2E96B4', color: 'white'},
  })
}
