/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useTranslation } from "react-i18next";
import Button from "../../Components/base/button/button";
import { Meeting } from "../../models/meeting.model";
import { useHandleMeetingNavigation } from "../LiveKit/hooks/livekitWebOnlyHooks";

/**
 * The StartMeetingButton component renders a button that starts a meeting.
 *
 * The component renders a button with the "Start Meeting" text.
 * When the button is clicked, it calls the joinMeeting function from the useHandleMeetingNavigation hook.
 * The function navigates the user to the meeting page and joins the meeting.
 *
 * @param {{meeting: Meeting}} props
 * @prop {Meeting} meeting - The meeting object.
 *
 * @returns {JSX.Element} The StartMeetingButton component.
 */
export function StartMeetingButton({ meeting }: { meeting: Meeting }) {
  const { t } = useTranslation()
  const { joinMeeting } = useHandleMeetingNavigation()

  return <Button size="sm" rounded color="join" className="w-24" onClick={() => joinMeeting(meeting.id)}>{t('startMeeting')}</Button>
}