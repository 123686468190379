/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {UserCategory} from '../models/userCategory.model';
import {allApis} from './allApis';

export const userCategoryApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    createUserCategory: builder.mutation<UserCategory, Partial<UserCategory>>({
      query: userCategory => ({
        url: `userCategory`,
        method: 'POST',
        body: userCategory,
      }),
      invalidatesTags: ['CurrentUser'],
    }),
    deleteUserCategory: builder.mutation<boolean, UserCategory>({
      query: userCategory => ({
        url: `userCategory/${userCategory.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CurrentUser'],
    }),
  }),
});

export const {useCreateUserCategoryMutation, useDeleteUserCategoryMutation} =
  userCategoryApi;
