/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {UserPolicyAgreements} from '../models/userPolicyAgreements.model';
import {useGetEventCreationPolicyQuery} from '../Services/policyApi';
import {useAgreeEventPolicyMutation} from '../Services/userPolicyAgreementsApi';

/**
 * Custom hook to manage event policy agreements.
 *
 * @return {Object} An object containing event policies, agreement status, an agree function, and policy success status.
 */

export const useEventPolicyAgreementHooks = () => {
  const {data: policies, isSuccess: policiesSuccess} =
    useGetEventCreationPolicyQuery();
  const [agreeEventPolicy, {isSuccess: eventPolicyAgreed}] =
    useAgreeEventPolicyMutation();

  const agree = () => {
    const userPolicy = {} as UserPolicyAgreements;
    userPolicy.policies = policies;
    agreeEventPolicy(userPolicy);
  };

  return {
    policies,
    eventPolicyAgreed,
    agree,
    policiesSuccess,
  };
};
