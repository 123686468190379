/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {useEffect, useState} from 'react';
import {RefundType, Processed} from '../models/cancellationRequest.model';
import {MeetingParticipant} from '../models/meetingParticipant.model';
import {
  useCheckCancellationRequestQuery,
  useCreateCancellationMutation,
} from '../Services/cancellationRequestApi';
import {useGetAllInvitedGuestsQuery} from '../Services/meetingParticipantApi';
import {useForm} from 'react-hook-form';
import {cancellationFormValidationSchema} from '../Utils/validation';
import {yupResolver} from '@hookform/resolvers/yup';

type Props = {
  meeting: any;
  closeModal: (e: any) => void;
};

/**
 * Custom hook for handling attending partial cancellation functionality.
 * It fetches invited guests, checks for existing cancellation requests, and provides functionality for creating new cancellation requests.
 *
 * @param {object} meeting - Meeting object containing relevant data.
 * @param {function} closeModal - Function to close the modal.
 * @return {object} An object containing various data and functions for managing attending partial cancellation.
 */

export const useAttendingPartialCancellationHooks = ({
  meeting,
  closeModal,
}: Props) => {
  const {data: inviteData, isSuccess: inviteSuccess} =
    useGetAllInvitedGuestsQuery(
      meeting?.meetingParticipant[0]?.transaction?.id,
    );
  const {data: partialCancellationData, isSuccess: partialCancellationSuccess} =
    useCheckCancellationRequestQuery(meeting.id ?? '', {skip: !meeting.id});

  const [allInviteData, setAllInviteData] = useState<MeetingParticipant[]>([]);
  const [selectedGuests, setSelectedGuests] = useState<MeetingParticipant[]>(
    [],
  );
  const [invitedGuest, setinvitedGuest] = useState<any>();
  const [
    createCancellation,
    {
      isSuccess: createPartialCancellationSuccess,
      isLoading: createPartialCancellationLoading,
    },
  ] = useCreateCancellationMutation();

  const {
    control,
    register,
    getValues,
    trigger,
    formState: {errors, isValid},
  } = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(cancellationFormValidationSchema),
  });

  useEffect(() => {
    if (inviteData) {
      setAllInviteData([...inviteData, meeting?.meetingParticipant[0]]);
      inviteData.forEach(invite => {
        if (invite.cancelationRequest) {
          setSelectedGuests([...selectedGuests, invite]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteData, meeting]);

  const handleSave = (e: any) => {
    // TODO: Optimize this API calls
    Promise.all(
      selectedGuests.map(async (meetingParticipant: MeetingParticipant) => {
        const args = {
          meeting,
          meetingParticipant,
          refundType: RefundType.Partial,
          cancellationReason: getValues('reason'),
        };
        await createCancellation(args);
      }),
    ).then(() => {
      closeModal(e);
    });
  };
  const handleInvitedGuests = (_id: string) => {
    setinvitedGuest(inviteData);
  };
  useEffect(() => {
    if (inviteData) {
      setinvitedGuest(inviteData);
    }
  }, [inviteData]);

  const setChecked = (participant: MeetingParticipant) => {
    const contains = selectedGuests.find(guest => guest.id === participant.id);
    if (!contains) {
      setSelectedGuests([...selectedGuests, participant]);
    } else {
      setSelectedGuests(
        selectedGuests.filter(guest => guest.id !== participant.id),
      );
    }
  };
  return {
    allInviteData,
    setAllInviteData,
    selectedGuests,
    setSelectedGuests,
    inviteData,
    inviteSuccess,
    createCancellation,
    setChecked,
    handleSave,
    register,
    errors,
    control,
    isValid,
    trigger,
    partialCancellationData,
    handleInvitedGuests,
    invitedGuest,
    partialCancellationSuccess,
    createPartialCancellationSuccess,
    createPartialCancellationLoading,
  };
};

/**
 * Custom hook for handling partial cancellation functionality.
 * It tracks the cancellation status of a specific invited guest.
 *
 * @param {MeetingParticipant} inviteGuest - The invited guest to track cancellation status for.
 * @return {{ partialCancel: 'CANCELLED' | 'REQUESTED' }} An object containing the current cancellation status.
 */

export const usePartialCancellationHook = ({
  inviteGuest,
}: {
  inviteGuest: MeetingParticipant;
}) => {
  const [partialCancel, setPartialCancel] = useState<
    'CANCELLED' | 'REQUESTED'
  >();
  useEffect(() => {
    if (inviteGuest?.cancelationRequest) {
      setPartialCancel('REQUESTED');
    }
    if (inviteGuest?.cancelationRequest?.processed === Processed.Yes) {
      setPartialCancel('CANCELLED');
    }
  }, [inviteGuest]);

  return {
    partialCancel,
  };
};
