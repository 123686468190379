/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {useAppDispatch} from './../Store/hooks';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {login} from '../API/loginAWS';
import {useGetCognitoKeysQuery} from '../Services/envApi';
import {useGetSettingValue} from '../Services/settingReducer';
import {changeToken, getToken} from '../Services/tokenReducer';
import {userApi} from '../Services/userApi';
import {changeCurrentUserDetail} from '../Services/userReducer';
import {useAppSelector} from '../Store/hooks';
import {Auth} from 'aws-amplify';
import {configureAWS} from '../API/configureAWS';
import {store} from '../Store';
import {changeCognitoModelObject} from '../Services/signUpReducer';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {loginValidationSchema} from '../Utils/validation';

/**
 * Custom hook for handling sign-in functionality.
 *
 * @return {object} An object containing various states and functions for sign-in, including token, cognito keys, link data, form control, and error handling.
 */

export const useSignInHook = () => {
  const token = useAppSelector(getToken);
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();

  const {data: cognitoKeys, isSuccess: cognitoKeysSuccess} =
    useGetCognitoKeysQuery();
  const linkData = useGetSettingValue('WEB_LINK');
  const [serverError, setServerError] = useState<string>('');
  const [navigateLink, setNavigateLink] = useState<string>('');
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [navigateScreen, setNavigateScreen] = useState<boolean>(false);
  const [isForgotPasswordDisabled, setIsForgotPasswordDisabled] =
    useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: {errors},
    trigger,
    getValues,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
  });

  if (cognitoKeys && linkData) {
    configureAWS(cognitoKeys, linkData);
  }

  const onSubmit = (values: any) => {
    setIsLoggingIn(true);
    if (serverError) {
      setServerError('');
      setIsLoggingIn(false);
    }
    login(values.email, values.password)
      .then((cognitoUser: any) => {
        dispatch(
          changeToken(cognitoUser.signInUserSession.accessToken.jwtToken),
        );
        dispatch(changeCurrentUserDetail(cognitoUser.attributes));
        setIsLoggingIn(false);
        reset();
      })
      .catch(err => {
        if (
          err.code.includes('NotAuthorizedException') &&
          err.message.indexOf('Incorrect username or password') > -1
        ) {
          if (!serverError) {
            setServerError('emailOrPasswordIsIncorrect');
          }
        } else if (err.code.includes('UserNotConfirmedException')) {
          store
            .dispatch(
              userApi.endpoints.getUserDetailByEmail.initiate(values.email),
            )
            .then((res: any) => {
              dispatch(changeCognitoModelObject(res.data));
            })
            .catch(error => {
              console.log(error);
            });
        }
        setIsLoggingIn(false);
      });
  };

  const openForgotPassword = async (trigger: any, values: any) => {
    if (isForgotPasswordDisabled) return;
    const result = await trigger(['email']);

    if (serverError) {
      setServerError('');
    }

    if (result) {
      const res = appDispatch(
        userApi.endpoints.getUserDetailByEmail.initiate(values.email),
      );

      res.then((val: any) => {
        if (val.data) {
          if (
            val.data.username.includes('google') ||
            val.data.username.includes('facebook')
          ) {
            if (!serverError) {
              //Err - Login with Google or Facebook
              setServerError('BeeMG-ERR066');
            }
          } else {
            Auth.forgotPassword(values.email)
              .then(result => {
                if (result.CodeDeliveryDetails.DeliveryMedium === 'EMAIL') {
                  if (!navigateLink) {
                    setNavigateLink(
                      '/resetPassword/' + values.email + '/email',
                    );
                    setNavigateScreen(true);
                  }
                } else {
                  if (!navigateLink) {
                    setNavigateLink(
                      '/resetPassword/' + values.email + '/phone',
                    );
                    setNavigateScreen(true);
                  }
                }
              })
              .catch(e => {
                //limit exceeds exception
                if (
                  e?.code === 'LimitExceededException' ||
                  e?.message === 'LimitExceededException'
                ) {
                  if (!serverError) {
                    //Err - Too many attempts
                    setServerError('BeeMG-ERR101');
                  }
                } else if (!serverError) {
                  //Err - User not in BeeMG
                  setServerError('BeeMG-ERR036');
                }
              });
          }
        } else {
          if (!serverError) {
            //Err - User not in BeeMG
            setServerError('BeeMG-ERR036');
          }
        }
      });
    }

    setIsForgotPasswordDisabled(true);
    setTimeout(() => {
      setIsForgotPasswordDisabled(false);
    }, 8000);
  };

  return {
    token,
    cognitoKeys,
    cognitoKeysSuccess,
    linkData,
    onSubmit,
    serverError,
    openForgotPassword,
    setServerError,
    navigateLink,
    isLoggingIn,
    navigateScreen,
    setNavigateScreen,
    control,
    handleSubmit,
    formState: {errors},
    trigger,
    getValues,
    watch,
    isForgotPasswordDisabled,
  };
};
