/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Icon from '../../Components/base/icon/icon'
import { Button } from '../../Components'
import { useTermsAndConditionsHooks } from '../../Hooks/TermsAndConditionsHook'
import { ToolTip } from '../../Components/base/tooltip/tooltip'
import Footer from '../Footer'
import { PageTitle } from '../../Components/pageTitle'
import { useAppSelector } from '../../Store/hooks'
import { getCurrentUser } from '../../Services/userReducer'
import { getSignUpToken, changeTermsAndCondition, getIsSignUpTermsAccepted } from '../../Services/signUpReducer'
import { useDispatch } from 'react-redux'
import { useUpdateUserTermsPolicyMutation } from '../../Services/userPolicyAgreementsApi'
import { useEffect, useState } from 'react'
import { toastSuccess } from '../../Components/toast'
import Header from '../Header'

  /**
   * A component to display the terms and conditions of the application.
   * It retrieves the terms and conditions from the server and displays them
   * in a page with a print button.
   * If the user is not logged in, it will also display a cancel button.
   * If the user is logged in, it will also display a accept button.
   * If the user is not logged in and the terms and conditions have not been accepted,
   * it will display a accept button and a cancel button.
   * If the user is logged in and the terms and conditions have not been accepted,
   * it will display a accept button and a cancel button.
   * When the user clicks the accept button, it will update the user's terms and conditions
   * status in the server.
   * When the user clicks the cancel button, it will navigate to the login page.
   * When the user clicks the print button, it will print the terms and conditions.
   * @returns A JSX element to display the terms and conditions.
   */
function TermsAndConditions() {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useAppSelector(getSignUpToken);
  const currentUser = useAppSelector(getCurrentUser);

  const [showPrintIcon, setShowPrintIcon] = useState<boolean>(true);

  const { hidebutton, policies, navigateScreen, cancel } = useTermsAndConditionsHooks()
  const [agreeTermsAndPolicies, { isSuccess: userPolicyAgreed, isLoading: userPolicyLoading }] = useUpdateUserTermsPolicyMutation()
  const termsAndConditionStatus = useAppSelector(getIsSignUpTermsAccepted)
  const [toasterView, setToasterView] = useState(false)

  if (navigateScreen === "NAVIGATE_TO_LOGIN") {
    navigate('/')
  }
  
  /**
   * Print the terms and conditions page.
   * This function sets `showPrintIcon` to false and then calls the `window.print()` method.
   * It also sets up an event listener to reset `showPrintIcon` to true when the print event is finished.
   * This is necessary because the print icon is hidden while the print dialog is open.
   */
  const printPageArea = () => {
  setShowPrintIcon(false)  
  }

  useEffect(()=>{
    if(!showPrintIcon){
    window.print()
   
    window.addEventListener('afterprint', () => {
      setShowPrintIcon(true);
    });
    }
  },[showPrintIcon])

  
  useEffect(()=>{
    if(termsAndConditionStatus && toasterView){
      toastSuccess(t('termsAndConditionsAcceptedSuccessfully'))
      navigate('/signUp')
    }
  },[termsAndConditionStatus, toasterView])


  useEffect(() => {
    if (userPolicyAgreed) {
      dispatch(changeTermsAndCondition(true))
      toastSuccess(t('termsAndConditionsUpdatedSuccessfully'))
      navigate('/s/home')
    }
  }, [userPolicyAgreed])

  return (
    <div className="flex flex-col h-screen">
      {!currentUser && <Header />}
      <div className="flex-grow">
        <PageTitle title={t('termsAndConditions')}>
          <hr className="my-10 mx-auto block w-4/5" />
          <div id="printableArea" className="text-center">
            <div className="flex-row mx-[10%] justify-content">
              {showPrintIcon && (
                <div className="flex float-right justify-end" data-testid="printableArea" onClick={printPageArea}>
                  <ToolTip tip="print" keyId="print">
                    <Icon icon="PRINT" size="medium" height="large" />
                  </ToolTip>
                </div>
              )}
              {policies?.policyName} - {policies?.policyVersion}
              <span
                dangerouslySetInnerHTML={{
                  __html: policies ? policies?.description : '',
                }}
              ></span>
            </div>
          </div>
          <div className="flex">
            <span className="w-full flex-wrap flex justify-center gap-2 mt-5">
              {!hidebutton && (
                <>
                  <Button
                    data-testid="btn_accept"
                    id="btn_acceptTerms"
                    className='w-52 normal-case'
                    disabled={userPolicyLoading}
                    onClick={() => {
                      if (currentUser) {
                        agreeTermsAndPolicies(currentUser);
                      } else {
                        dispatch(changeTermsAndCondition(true));
                        setToasterView(true);
                      }
                    }}
                  >
                    {t('accept')}
                  </Button>
                  <Button
                    data-testid="btn_cancel"
                    id="btn_cancelTerms"
                    className='w-52 normal-case'
                    onClick={cancel}
                  >
                    {t('cancel')}
                  </Button>
                </>
              )}
            </span>
          </div>
        </PageTitle>
      </div>
      {!currentUser && <Footer />}
    </div>
  );
}
export default TermsAndConditions
