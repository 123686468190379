/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { HTMLAttributes, ReactNode } from 'react'
import useWindowDimensions from '../Components/base/carousel/whirligig/utils'
import { HelpLink } from '../Components/HelpLink'
import LangDropdownMenu from '../Components/LangDropdownMenu'
import { useGetSettingValue } from '../Services/settingReducer'
import Footer from './Footer'

import NewIcon from '../Components/base/icon/newIcons'
import useSignOutHook from '../Hooks/SignOutHook'
import { useLocation} from 'react-router-dom'
import groupImage4 from '../Assets/Images/Group4.png';
import groupImage7 from '../Assets/Images/Group7.png';
import groupImage5 from '../Assets/Images/Group5.png';
import groupImage6 from '../Assets/Images/Group6.png';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  bgColor: string;
  bgImage: string;
}

/**
 * A page component that renders the default background image and a
 * top-right corner with language dropdown and help link. The component
 * is designed to be used as a parent component for other pages.
 *
 * @param {Object} props
 * @param {ReactNode} props.children - The content to be rendered
 * inside the page
 */
function InitialPage1({ children ,bgColor ,bgImage}: Props) {
  const { height: screenHeight, width: screenWidth } = useWindowDimensions()
  const baseImageURL = useGetSettingValue('IMAGE_URL');
  const imageSize = (size: number) => `${(size)}px`
  const location = useLocation()

  const imageWidth = imageSize(((screenWidth)) / 2)
  const imageHeight = imageSize((screenHeight) / 2)
  const { signOutActions } = useSignOutHook()
  const getBgClass = (color: string) => {
    switch (color) {
      case 'yellow':
        return 'bg-BeeMG-intro-yellow'; // Use your specific Tailwind class for yellow
      case 'white':
        return 'bg-white'; // Tailwind class for white
      default:
        return 'bg-gray-100'; 
    }
  };

  return (
    <div
      className={`md:bg-center md:bg-cover bg-no-repeat h-screen flex flex-col overflow-hidden relative ${getBgClass(bgColor)}`}>
      {bgImage === 'group4' ? (
        <>
          <div className={`absolute -right-0 -top-0 w-2/5 h-auto`}>
            <img
              
              className="absolute top-0 right-0 object-cover"
              src={groupImage4}
              alt="group4"
            />
          </div>

          <div className={`absolute -left-0 -bottom-0 w-2/5 h-auto`}>
          <img
              className="absolute bottom-0 left-0 object-cover"
              src={groupImage7}
              alt="group7"
            />
          </div>
        </>
      ) : bgImage === 'group5' ? (
        <>
          <div className={`absolute -right-0 -top-0 w-2/5 h-auto`}>
            <img
              className="absolute top-0 right-0 object-cover"
              src={groupImage5}
              alt="group5"
            />
          </div>

          <div className={`absolute -left-0 -bottom-0 w-2/5 h-auto`}>
            <img
              className="absolute bottom-0 left-0 object-cover"
              src={groupImage6}
              alt="group6"
            />
          </div>
        </>
      )
        : (
          <>
            <div className={`absolute -right-0 -top-0 w-2/5 h-auto`}>
              <img
                className="absolute bottom-0 left-0 object-cover"
                src={groupImage5}
                alt="group5"
              />
            </div>
          </>
        )}

      <div
        className={`h-full w-full flex flex-1 overflow-y-auto items-center justify-center z-30`}>
        {children}
      </div>
      <div className="z-30">
      </div>
    </div>
  )
}

export default InitialPage1
