/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {yupResolver} from '@hookform/resolvers/yup';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {MeetingParticipant} from '../../models/meetingParticipant.model';
import {
  useGetAllCohostQuery,
  useCreateCohostForEventsMutation,
  useDeleteCohostForEventMutation,
  useGetAllParticipantByMeetingQuery,
} from '../../Services/meetingParticipantApi';
import {coHostValidationSchema} from '../../Utils/validation';

/**
 * Custom React hook for managing event co-hosts.
 *
 * Provides functionality for creating, deleting, and retrieving co-hosts,
 * as well as handling form submission and error states.
 *
 * @param {string} meetingId - The ID of the meeting for which to manage co-hosts.
 * @return {object} An object containing various co-host management functions and states.
 */

export const useEventCoHostHooks = (meetingId: string) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    formState: {errors},
  } = useForm<{email: string}>({
    resolver: yupResolver(coHostValidationSchema),
    defaultValues: {
      email: '',
    },
  });

  const {data: meetingCohosts, isSuccess: meetingCohostsSuccess} =
    useGetAllCohostQuery(meetingId, {skip: !meetingId});
  const [
    createCoHost,
    {error: addError, isSuccess: addedSuccess, isLoading: coHostLoading},
  ] = useCreateCohostForEventsMutation();
  const [deleteCoHost, {isSuccess: deletedSuccess}] =
    useDeleteCohostForEventMutation();
  const [coHostError, setCoHostError] = useState<string>();
  const {refetch: refetchMeetingParticipants} =
    useGetAllParticipantByMeetingQuery(meetingId ?? '', {
      skip: meetingId === undefined,
    });

  const onSubmit = ({email}: {email: string}) => {
    createCoHost({
      meetingId: meetingId,
      email: email.trim().toLocaleLowerCase(),
    }).then((result: any) => {
      refetchMeetingParticipants();
      if (result?.error) {
        setCoHostError(result.error.data.message);
      } else {
        setCoHostError('');
      }
    });
  };

  const onDelete = (coHost: MeetingParticipant) => {
    deleteCoHost({meetingId: meetingId, coHostId: coHost.id}).then(() => {
      refetchMeetingParticipants();
      setCoHostError('');
    });
  };

  return {
    deletedSuccess,
    addedSuccess,
    coHostLoading,
    meetingCohostsSuccess,
    meetingCohosts,
    register,
    errors,
    handleSubmit,
    coHostError,
    setCoHostError,
    reset,
    getValues,
    onSubmit,
    onDelete,
    addError,
    control,
  };
};
