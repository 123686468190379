/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { AccordionBorder, AccordionItem, AccordionPanel } from "../../Components/base/accordion/accordion"
import { Ledger } from "../../models/ledger.model"
import CardImagePlaceHolder from "../EventsCard/CardImagePlaceHolder"
import CardTitle from "../EventsCard/CardTitle"
import { EventCard } from "../EventsCard/EventCard"
import DisplayTransactionDate from "./DisplayTransactionDate"
import DisplayTransactionDescription from "./DisplayTransactionDescription"
import DisplayTransactionStatus from "./DisplayTransactionStatus"
import StatusRed from "./StatusRed"
import TransactionAmount from "./TransactionAmount"
import { DisplayFormattedDate, DisplayFormattedTime } from "../../Components/formattedDateAndTime"

/**
 * A component to display in-progress transaction details in the transaction history.
 * @param {Ledger} transaction - The transaction details to display.
 * @returns {JSX.Element} - The component element.
 */
export default function InProgressCard({ transaction }: { transaction: Ledger }) {
    const { t } = useTranslation()
    const { transactionType } = useParams()

    if (transactionType === 'debit' || transactionType === 'credit') {
        return <></>
    }
    return (
        <AccordionBorder keyId={transaction.id}>
            <AccordionItem toggle={`panel-${transaction.id}`} color="card">
                <EventCard>
                    <EventCard.Image>
                        {transaction?.meeting?.id && <CardImagePlaceHolder images={transaction?.meeting?.meetingDetails?.image} />}
                        {transaction?.meetingRequest?.id &&
                            <CardImagePlaceHolder images={transaction?.meetingRequest?.guest?.image} />
                        }
                    </EventCard.Image>
                    <EventCard.Title>
                        {transaction?.meeting &&
                            <CardTitle
                                keyId={transaction.id}
                                meetingTitle={
                                    transaction?.meeting?.meetingDetails?.title &&
                                        transaction?.meeting?.meetingDetails?.title.length > 40
                                        ? `${transaction?.meeting?.meetingDetails?.title.slice(
                                            0,
                                            40,
                                        )}...`
                                        : transaction?.meeting?.meetingDetails?.title
                                }
                            />}
                        {transaction?.meetingRequest?.guest?.firstName &&
                            <CardTitle keyId={transaction.id} meetingTitle={`Meeting with ${transaction.meetingRequest?.guest?.firstName}`} />
                        }
                    </EventCard.Title>
                    {transaction.display_meetingDate && <EventCard.SubTitle><div className="font-normal">{t('eventOn')}{' '}<DisplayFormattedDate displayDate={transaction.display_meetingDate}/>{' '}-{' '}
                    <DisplayFormattedTime displayFromTime={transaction.meeting.fromDateTime}  displayToTime={transaction.meeting.toDateTime} />
                    </div></EventCard.SubTitle>}
                    <EventCard.SubTitle1>
                        <DisplayTransactionDate transaction={transaction} />
                    </EventCard.SubTitle1>
                    <EventCard.Actions>
                        <div className="flex md:flex-col text-end items-end gap-3 md:gap-0">
                            <TransactionAmount transaction={transaction} symbol={' '} />
                            <StatusRed> {t('inProgress')} </StatusRed>
                        </div>
                    </EventCard.Actions>
                </EventCard>
            </AccordionItem>
            <AccordionPanel id={`panel-${transaction.id}`}>
                <div className="w-full">
                    <hr />
                    <div className="flex flex-col w-full mx-auto items-center justify-between text-sm md:text-base">
                        <div className="flex flex-col md:flex-row text-black w-full justify-between items-center text-normal">
                            <div className="w-full md:w-auto md:order-1 flex justify-center">
                                <DisplayTransactionStatus transaction={transaction} />
                            </div>
                            <div className="w-full md:w-auto md:order-2 flex justify-end md:pr-4">
                                <DisplayTransactionDescription transaction={transaction} />
                            </div>
                        </div>
                    </div>
                </div>
            </AccordionPanel>
        </AccordionBorder>
    )
}