/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useState } from 'react'
import { Modal, ModalCloseButton, ModalHeader, ModalBody, ModalFooter } from '../../Components/base/modal/modal'
import { MeetingDetails } from '../../models/meetingDetails.model'
import { Meeting } from '../../models/meeting.model'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCurrentUser } from '../../Services/userReducer'
import { useNavigate } from 'react-router-dom'
import Field from '../../Components/base/field/field'
import Button from '../../Components/base/button/button'
import { DisplayFormattedDate, DisplayFormattedTime } from '../../Components/formattedDateAndTime'

interface _FreeEventAnonymousModalProps {
    isOpen: boolean
    toggle: (e?: any) => void
    meetingDetails?: MeetingDetails
    meeting?: Meeting
    handleRegister: (meeting: any, joinAnonymous: boolean) => void
}

/**
 * A modal component for free events registration.
 * It includes a checkbox to join the event as anonymous, and a button to register.
 * If the user is not anonymous and joins as anonymous, it displays a button to update anonymous profile.
 * @param {object} props - Component props
 * @param {boolean} props.isOpen - Whether the modal should be open or not
 * @param {function} props.toggle - Callback function to handle the modal toggle
 * @param {object} props.meeting - The meeting object
 * @param {object} props.meetingDetails - The meeting details object
 * @param {function} props.handleRegister - Callback function to handle the register button
 */
export default function FreeEventAnonymousModal(props: _FreeEventAnonymousModalProps) {
    const { isOpen, toggle, meeting, meetingDetails, handleRegister } = props
    const [joinAnonymous, setJoinAnonymous] = useState(false)
    const { t } = useTranslation()
    const currentUser = useSelector(getCurrentUser);
    const navigate = useNavigate()
    return (
        <div>
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                closeOnClickOutside={false}
                animate={true}>
                <ModalCloseButton toggle={toggle} />
                <ModalHeader>
                    <div className='flex flex-col gap-y-2 md:w-[30rem] w-[18rem] text-black'>
                        <span data-testid="registerModal_title" className='text-left text-ellipsis overflow-hidden'>
                            {t('registerFor')} {meetingDetails?.title}
                        </span>
                        {meeting && <span data-testid="registerModal_dateTime" className='text-sm font-normal text-left'>
                            <DisplayFormattedDate displayDate={meeting.fromDateTime}/>{" "} <DisplayFormattedTime displayFromTime={meeting.fromDateTime}  displayToTime={meeting.toDateTime} />
                        </span>}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className='flex flex-row items-center text-sm font-normal text-gray-600'>
                        <Field
                            type="checkbox" name="onlineList" id="input_onlineList"
                            onChange={(e: any) => {
                                if (e.target.checked === true) {
                                    setJoinAnonymous(true)
                                } else {
                                    setJoinAnonymous(false)
                                }
                            }}
                        />
                        <label htmlFor='input_onlineList' id="chk_otherPeopleList" data-testid="btn_anonymous">
                            {t('joinAnonymous')}
                        </label>
                        {joinAnonymous && !currentUser.anonymous &&
                            <div className='ml-8 md:ml-0'>
                                <Button size="sm" rounded color="join" onClick={() => navigate(`/s/profile`,{ state: { isAnon: true } })}><p id="btn_anonymousProfile" data-testid="btn_anonymousProfile" className="text-xs md:text-sm ">{t('updateAnonymousProfile')}</p></Button>
                            </div>}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="px-5 py-1 md:px-8"
                        disabled={(!currentUser.anonymous && joinAnonymous)}
                        size="sm" color="payNow"
                        id="btn_modalRegister"
                        data-testid="btn_modalRegister"
                        onClick={() => handleRegister(meeting, joinAnonymous)}>
                        {t('register')}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
