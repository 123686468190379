/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../Components'
import useToggle from '../../Components/_utils/useToggle'
import Icon from '../../Components/base/icon/icon'
import Loading from '../../Components/base/loading/loading'
import { ToolTip } from '../../Components/base/tooltip/tooltip'
import { toastError, toastSuccess } from '../../Components/toast'
import { useViewEventsDisplayEventCard, useViewEventsUpcomingHooks, useViewReadMore } from '../../Hooks/UpcomingEvents'
import { Meeting } from '../../models/meeting.model'
import { MeetingDetails } from '../../models/meetingDetails.model'
import { useHandleMeetingNavigation } from '../LiveKit/hooks/livekitWebOnlyHooks'
import { FavoriteIcon } from './FavoriteIcon'
import FreeEventAnonymousModal from './FreeEventAnonymousModal'
import JoinAnonymousModal from './JoinAnonymousModal'
import { useCurrencyHook } from '../../Hooks/CurrencyHooks'
import useWindowDimensions from '../../Components/base/carousel/whirligig/utils'
import { DisplayFormattedDate, DisplayFormattedTime } from '../../Components/formattedDateAndTime'
import { isToday } from 'date-fns'
/**
 * @function DisplayEventCard
 * @description Displays the Upcoming Events Card. 
 * @param {MeetingDetails} meetingDetails - The meeting details object.
 * @param {Meeting} meeting - The meeting object.
 * @param {number} numberOfGuests - The number of guests.
 * @param {number} keyId - The keyId of the meeting.
 * @returns {JSX.Element} The JSX element of the Upcoming Events Card.
 */
const DisplayEventCard = ({
  meetingDetails, meeting, numberOfGuests, keyId
}: { meetingDetails: MeetingDetails, meeting: Meeting, numberOfGuests: number, keyId: number }) => {
  const { t } = useTranslation()
  const [isPayNowDisabled, setIsPayNowDisabled] = useState(true);
  useEffect(() => {
    setIsPayNowDisabled(numberOfGuests === 0);
  }, [numberOfGuests]);
  const { width } = useWindowDimensions()
  const {
    displayButton,
    preference,
    toggleCard,
    setToggleCard,
    totalCalculatedCost,
    totalCalculatedCostApprox,
    payment,
    navigateScreenLink,
    handleRegister,
    registerSuccess,
    registerError,
    loading,
    serverError,
    razorpayOpen,registerLoading,
  } = useViewEventsDisplayEventCard({ meetingDetails, meeting, numberOfGuests })
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState(undefined), []);
  const { toggle, isOpen } = useToggle()
  const { toggle: toggleFreeEventAnonymousModal, isOpen: isFreeEventAnonymousModal } = useToggle()
  const { joinMeeting } = useHandleMeetingNavigation()
  const { formatCurrency } = useCurrencyHook();  
  useEffect(() => {
    if (registerSuccess) {
      toastSuccess(t('registeredSuccessfully'))
      toggleFreeEventAnonymousModal()
    }
    if (registerError) {
      toastError(t('limitReached'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerSuccess, registerError])
  useEffect(() => {
    forceUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateScreenLink])
  if (navigateScreenLink) {
    window.location.href = navigateScreenLink;
  }
    const isEventToday = (eventDateTime: Date | number | string) => {
    return isToday(new Date(eventDateTime));
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <div className={`py-2 ${keyId + 1} ${isEventToday(meeting.fromDateTime) ? 'font-semibold' : ' text-sm font-normal'}`}>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-x-2">
          {/* <div className="flex flex-col cursor-pointer text-sm font-normal gap-y-0.5">
            <FavoriteIcon
              meeting={meeting}
              meetingDetails={meetingDetails}
              keyId={keyId}
            />
            {toggleCard && <Icon icon="CLOCK_BLACK" size="small" />}
          </div> */}
          <div className="flex flex-col cursor-default gap-y-0.5">
            <div className='cursor-pointer'>
              <span
                onClick={() => setToggleCard(!toggleCard)}
                id={`btn_viewDate${keyId}`}
                data-testid={`meeting_date${keyId}`}>
              <DisplayFormattedDate displayDate={meeting.fromDateTime} />
              </span>
              {toggleCard && (
                <div data-testid={`minutes${keyId}`} id={`minutes${keyId}`}>
                  {meeting.duration} {t(`minutes`)}
                </div>
              )}
            </div>
            <div data-testid="meetingTime">
              <DisplayFormattedTime displayFromTime={meeting.fromDateTime}  displayToTime={meeting.toDateTime} />
              </div>
            <span data-testid="language">{meeting.language}</span>
            <FavoriteIcon
              meeting={meeting}
              meetingDetails={meetingDetails}
              keyId={keyId}
            />
          </div>
          <div className="flex flex-col cursor-pointer text-sm font-normal gap-y-0.5">
            {toggleCard && <Icon icon="CLOCK_BLACK" size="small" />}
          </div>
        </div>
        <div className=" my-auto ml-3">
          {width <= 400 && (
            <ToolTip
              tip="PleaseCheckWithYourBank"
              theme="BeeMG-gray"
              trigger="click">
              <Icon icon="QUESTION" size="x-small" />
            </ToolTip>
          )}
        </div>
        <div className="flex flex-col text-right text-sm font-normal gap-y-0.5">
          {meetingDetails.isFreeAudioMeeting === false &&
            numberOfGuests < 2 && (
              <>
                <div data-testid="meeting_currency">
                  <span id={`btn_costChk${keyId}`}>
                    {formatCurrency(meeting.cost_formatted, meeting.currency)}
                  </span>
                  <span> / {t(`person`)}</span>
                </div>
                {preference.currency !== meeting.currency && (
                  <div id={`btn_approxCostChk${keyId}`}>
                    ({t(`approx`)} {''}
                    {formatCurrency(
                      meeting.costLocal_formatted,
                      preference.currency,
                    )}
                    )
                  </div>
                )}
              </>
            )}
          {meetingDetails.isFreeAudioMeeting === false &&
            numberOfGuests >= 2 && (
              <>
                <div>
                  <ToolTip
                    tip={`${formatCurrency(
                      meeting.cost_formatted,
                      meeting.currency,
                    )} /${t('person')}`}
                    theme="BeeMG-gray">
                    <div id={`btn_guestCost${keyId}`}>
                      {t(`for`)} {numberOfGuests} {t('guests')}{' '}
                      <span>
                        {formatCurrency(
                          totalCalculatedCostApprox,
                          meeting.currency,
                        )}{' '}
                      </span>
                    </div>
                  </ToolTip>
                  {preference.currency !== meeting.currency && (
                    <div id={`btn_approxGuestCost${keyId}`}>
                      ({t(`approx`)}{' '}
                      <span>
                        {formatCurrency(
                          totalCalculatedCost,
                          preference.currency,
                        )}
                      </span>
                      )
                    </div>
                  )}
                </div>
              </>
            )}
          {displayButton === 'PAYNOW' && (
            <>
              {width <= 400 ? (
                <Button
                  className="w-full"
                  size="sm"
                  color="payNow2"
                  id={`btn_payNow_${keyId}`}
                  data-testid={`btn_payNow${keyId}`}
                  onClick={toggle}
                  disabled={isPayNowDisabled || razorpayOpen}>
                  {t('payNow')}
                </Button>
              ) : (
                <ToolTip tip="PleaseCheckWithYourBank" theme="BeeMG-gray">
                  <Button
                    className="w-full"
                    size="sm"
                    color="payNow2"
                    id={`btn_payNow_${keyId}`}
                    data-testid={`btn_payNow${keyId}`}
                    onClick={toggle}
                    disabled={isPayNowDisabled}>
                    {t('payNow')}
                  </Button>
                </ToolTip>
              )}
              {
                <JoinAnonymousModal
                  isOpen={isOpen}
                  toggle={toggle}
                  meetingDetails={meetingDetails}
                  meeting={meeting}
                  payment={payment}
                  RFMPayNow={false}
                  razorpayOpen={razorpayOpen}
                />
              }
            </>
          )}
          {displayButton === 'SOLDOUT' && (
            <Button
              className="w-full"
              data-testid={`btn_soldOut${keyId}`}
              size="sm"
              color="paid"
              disabled>
              {t('soldOut')}
            </Button>
          )}
          {serverError && (
            <Button className="w-full" size="sm" color="paid" disabled>
              {t(serverError)}
            </Button>
          )}
          {displayButton === 'PAID' && (
            <Button
              className="w-full"
              data-testid={`btn_paid${keyId}`}
              size="sm"
              color="paidUpcoming"
              disabled>
              {t('paid')}
            </Button>
          )}
          {displayButton === 'INVITED' && (
            <Button className="w-full" size="sm" color="paid" data-testid={`btn_invited${keyId}`} disabled>
              {t('invited')}
            </Button>
          )}
          {displayButton === 'JOIN' && (
            <Button
              className="w-full"
              data-testid={`btn_join${keyId}`}
              size="sm"
              rounded
              color="join"
              onClick={() => joinMeeting(meeting.id)}>
              {t('join')}
            </Button>
          )}
          {displayButton === 'COHOST' && (
            <Button
              className="w-full"
              data-testid="btn_co-Host"
              size="sm"
              color="coHost"
              disabled>
              {t('co-Host')}
            </Button>
          )}
          {displayButton === 'REGISTER' && (
            <>
              <Button
                id="btn_register"
                data-testid={`btn_eventRegister${keyId}`}
                className="w-full"
                size="sm"
                color="payNow"
                disabled= {registerLoading == true}
                onClick={toggleFreeEventAnonymousModal}>
                {t('register')}
              </Button>
              <FreeEventAnonymousModal
                isOpen={isFreeEventAnonymousModal}
                toggle={toggleFreeEventAnonymousModal}
                meetingDetails={meetingDetails}
                meeting={meeting}
                handleRegister={handleRegister}
              />
            </>
          )}
          {displayButton === 'REGISTERED' && (
            <Button className="w-full" size="sm" color="registered" data-testid={`btn_registered${keyId}`} disabled>
              {' '}
              {t('registered')}{' '}
            </Button>
          )}
          {displayButton === 'LIMITREACHED' && (
            <Button className="w-full" size="sm" color="payNow" data-testid={`btn_limitReached${keyId}`}disabled>
              {t('limitReached')}
            </Button>
          )}
          {!displayButton && ' '}
          <span
            id={`btn_seatLimit${keyId}`}
            data-testid="seatsAvailable"
            className="text-xxs">
            {t(`limitedTo`)} {meeting.noOfParticipants} {t('people')}
          </span>
        </div>
      </div>
      {/* {meetingDetails.meetings.length-1&& <hr className='my-2'/>} */}
    </div>
  )
}

/**
 * A React component that displays a list of upcoming events.
 *
 * @param {MeetingDetails} meetingDetails - The details of the meeting.
 * @return {JSX.Element} A JSX element containing the list of upcoming events.
 */
export function UpcomingEvents({ meetingDetails }: { meetingDetails?: MeetingDetails }) {
  const { t } = useTranslation()
  const { numberOfGuests, changeNumberOfGuests, currentUser, } = useViewEventsUpcomingHooks(meetingDetails);
  const { minLimit, setShowMoreLess, showMoreLess } = useViewReadMore({ meetingDetails });
  const [guestCount, setGuestCount] = useState<any>(numberOfGuests);
  return (
    <div className={`w-full rounded-2xl border p-3 py-2 lg:p-5 sm:mb-2 xs:mb-2 ${meetingDetails && meetingDetails?.meetings.length > 3 ? 'max-h-[90rem]' : 'max-h-[30rem]'} overflow-y-scroll no-scrollbar`}>
      <div className="font-medium flex flex-col gap-y-2">
        <div className='flex flex-row justify-between items-center'>
          {meetingDetails && meetingDetails?.meetings?.length > 0 && <p className="justify-start text-2xl text-gray-400 sm:text-lg font-semibold normal-case" data-testid='txt_upcoming'>{t(`upcoming`)}</p>}
          <div className='flex flex-1 justify-end'>
            {meetingDetails && meetingDetails?.meetings?.length > 0 && currentUser?.id !== meetingDetails?.host?.id && meetingDetails.isFreeAudioMeeting === false &&
              <div className="justify-end flex flex-row items-center">
                <label data-testid='guestsCount' className="text-gray-400 font-normal text-right text-sm w-full ml-5 mr-2">{t('guests')}</label>
                <input
                  className='flex w-1/5 rounded-lg p-input bg-white text-gray-400 placeholder:text-sm placeholder-gray-400 text-sm focus:outline-none focus:ring-1 focus:ring-BeeMG-yellow focus:border-transparent border border-gray-300 h-8 text-center'
                  id="input_noOfGuests" data-testid="input_noOfGuests" name="numberOfGuests"
                  value={(guestCount)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    const number = Number(value);
                    if (value === '' || (Number.isInteger(number) && number >= 1 && number <= 99)) {
                      changeNumberOfGuests(e);
                      setGuestCount(value);
                    }
                  }}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    if (value === '') {
                      changeNumberOfGuests(1);
                      setGuestCount(1);
                    }
                  }}
                  min={1}
                  max={99}
                  step={1}
                />
              </div>}
          </div>
        </div>
          {meetingDetails && meetingDetails?.meetings?.length === 0 &&
            <div className='text-center'><p data-testid='txt_NoEvents' className="text-3xl sm:text-lg font-medium">{t(`noUpcomingEvents`)}</p></div>
          }
        <div>
          {React.Children.toArray(meetingDetails?.meetings.map((meeting, index) => (
            <div key={meeting.id}>
              {index < minLimit &&
                <>
                  <DisplayEventCard
                    key={meeting.id} keyId={index} meetingDetails={meetingDetails} meeting={meeting} numberOfGuests={Math.floor(numberOfGuests)}
                  />
                  {index === meetingDetails.meetings.length - 1 ? '' : <hr className='my-2' />}
                </>
              }
            </div>
          )))}
          {meetingDetails && meetingDetails?.meetings.length > 3 &&
            <div onClick={() => setShowMoreLess(!showMoreLess)} id="showmore_click" data-testid="showmore_click" className="text-center uppercase cursor-pointer mt-2">
              {showMoreLess ? t('showMore') : t('showLess')}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

