/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {Feedback} from './../models/feedback.model';
import {Pagination} from '../models/pagination.model';
import {formatDateToTimezone} from '../Utils/datetime';
import {allApis} from './allApis';

/**
 * Transforms the feedback response by adding a display_createdAt property to each feedback item.
 *
 * @param {Pagination<Feedback>} response - The feedback response to be transformed
 * @return {Pagination<Feedback>} The transformed feedback response
 */

const feedbackResponseTransform = (response: Pagination<Feedback>) => {
  if (response.results) {
    response.results = response.results.map(feedback => {
      feedback.display_createdAt = formatDateToTimezone(
        feedback.createdAt.toString(),
      );
      return feedback;
    });
  }
  return response;
};

/**
 * Transforms an array of feedback objects by applying the feedbackCheckTransform function to each item.
 *
 * @param {Feedback[]} feedbacks - The array of feedback objects to be transformed
 * @return {Feedback[]} The transformed array of feedback objects
 */

export const feedbackTransform = (feedbacks: Feedback[]) => {
  if (feedbacks) {
    feedbacks = feedbacks.map(feedbackList => {
      return feedbackCheckTransform(feedbackList);
    });
  }
  return feedbacks;
};

/**
 * Transforms a feedback object by adding a display_createdAt property.
 *
 * @param {Feedback} feedback - The feedback object to be transformed
 * @return {Feedback} The transformed feedback object
 */

const feedbackCheckTransform = (feedback: Feedback) => {
  feedback.display_createdAt = formatDateToTimezone(
    feedback.createdAt.toString(),
  );
  return feedback;
};

export const feedbackApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    createFeedback: builder.mutation<Feedback, Partial<Feedback>>({
      query: feedback => {
        return {
          url: `feedback`,
          method: 'POST',
          body: feedback,
        };
      },
      invalidatesTags: (result, error, feedback) => [
        {type: 'FeedbackMeeting', id: feedback?.meeting?.id},
      ], // TODO: check if this is correct
    }),

    updateFeedback: builder.mutation<Feedback, Partial<Feedback>>({
      query: feedback => {
        return {
          url: `feedback`,
          method: 'PUT',
          body: feedback,
        };
      },
      invalidatesTags: [
        'FeedbackMeetingDetails',
        'FeedbackMeeting',
        'PastEventMeeting',
        'rfmMeetingFeedback',
      ],
      // invalidatesTags: (result, error, feedback) => {
      //   return [{ type: 'FeedbackMeeting', id: feedback?.meeting?.id },
      //   { type: 'FeedbackMeetingDetails', id: feedback?.meetingDetails?.id }]
      // }, // TODO: check if this is correct
    }),

    getFeedbackByMeetingId: builder.query<Pagination<Feedback>, any>({
      query: args => {
        const {meetingId, limit, skip} = args;
        return `feedback/allReview/${meetingId}/${limit}/${skip}`;
      },
      providesTags: ['PastEventMeeting'],
    }),

    getFeedbackByUserId: builder.query<Feedback[], {userId: string}>({
      query: args => {
        const {userId} = args;
        return `feedback/userReview/${userId}`;
      },
      providesTags: ['rfmMeetingFeedback'],
      transformResponse: (response: Feedback[]) => feedbackTransform(response),
    }),

    getByMeetingDetailsId: builder.query<Pagination<Feedback>, any>({
      query: args => {
        const {meetingDetailsId, limit, skip} = args;
        return `feedback/meetingDetails/${meetingDetailsId}/${limit}/${skip}`;
      },
      transformResponse: (res: any) => feedbackResponseTransform(res),
      providesTags: (result, error, meetingDetailsId) => [
        {type: 'FeedbackMeetingDetails', id: meetingDetailsId},
      ],
    }),

    getFeedbackCountAndAverage: builder.query<any, {meetingDetailsId: string}>({
      query: args => {
        const {meetingDetailsId} = args;
        return `feedback/meetingDetails/feedbackCountAndAverage/${meetingDetailsId}`;
      },
    }),

    getUserFeedbackByMeetingId: builder.query<Feedback, string>({
      query: meetingId => `feedback/user/${meetingId}`,
      providesTags: (result, error, meetingId) => [
        {type: 'FeedbackMeeting', id: meetingId},
      ],
    }),
  }),
});

export const {
  useGetByMeetingDetailsIdQuery,
  useUpdateFeedbackMutation,
  useGetUserFeedbackByMeetingIdQuery,
  useCreateFeedbackMutation,
  useGetFeedbackByMeetingIdQuery,
  useGetFeedbackByUserIdQuery,
  useGetFeedbackCountAndAverageQuery,
} = feedbackApi;
