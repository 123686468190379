/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { phone } from 'phone'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import google from '../../Assets/Images/google.svg'
import facebook from '../../Assets/Images/facebook-square.svg'
import { Button } from '../../Components'
import useToggle from '../../Components/_utils/useToggle'
import { DatePicker } from '../../Components/base/datepicker/datepicker'
import Field from '../../Components/base/field/field'
import Loading from '../../Components/base/loading/loading'
import { Modal, ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from '../../Components/base/modal/modal'
import { PhoneNumberField } from '../../Components/base/phone-number/PhoneNumberField'
import { toastError } from '../../Components/toast'
import { useSignUpHook } from '../../Hooks/SignUp'
import { useSignUpContentHook } from '../../Hooks/SignUpContent'
import { useGetCognitoKeysQuery } from '../../Services/envApi'
import { useGetSettingValue } from '../../Services/settingReducer'
import { changeToken, getIsSignUpTermsAccepted } from '../../Services/signUpReducer'
import { getCurrentUser } from '../../Services/userReducer'
import { useAppSelector } from '../../Store/hooks'
import { Invite } from '../../models/invite.model'
import { UserDetail } from '../../models/userDetail.model'
import InitialPage from '../InitialPage'
import BeemgPrimaryLogo from '../../Assets/NewIcons/beemg-logos-primary.svg';

import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

/**
 * @description SignUpContent is a component which renders the content of Sign up page.
 * It takes the required props like invite, formVal, siteKey, ipAddressData, userData.
 * It uses the useSignUpContentHook to get the values from the form and the functions to handle the form submission.
 * It renders the form with input fields for first name, last name, phone number, email, password, date of birth and gender.
 * It also renders the Google reCAPTCHA and a button to submit the form.
 * If the invite is present, it renders a button to sign up with Google.
 * It also renders a link to sign in if the user is already a member.
 * @param invite - The invite object which contains the invite details.
 * @param formVal - The initial values of the form.
 * @param siteKey - The site key for the Google reCAPTCHA.
 * @param ipAddressData - The ip address data of the user.
 * @param userData - The user data which is used to prefill the form.
 * @returns The SignUpContent component.
 */
function SignUpContent({
  siteKey,
  ipAddressData,
}: {
  siteKey?: string
  ipAddressData?: any
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  // const dispatch = useDispatch()
  const linkData = useGetSettingValue('WEB_LINK');
  const termsAndConditionStatus = useAppSelector(getIsSignUpTermsAccepted)

  const [passwordError, setPasswordError] = useState('');
  const { toggle, isOpen } = useToggle()
  const { data: cognitoKeys, isLoading: cognitoKeysLoading } = useGetCognitoKeysQuery()

  // const { token } = useParams()
  // dispatch(changeToken(token))

  const {
    recaptchaValue,
    serverError,
    onChange,
    signUp,
    maxDob,
    minDob,
    handleSubmit,
    register,
    watch,
    errors,
    control,
    getValues,
    setServerError
  } = useSignUpContentHook(
    ipAddressData
  )
  
  useEffect(() => {
    if (serverError) {
      toastError(t(serverError))
      setServerError('')
      // navigate("/")
    }
  }, [serverError])

  if (errors.password) {
    const content = (errors.password as any).message?.split(",");

    if (content && content?.length > 0) {
      let errorMessage = '<ul>';

      content.forEach((errorContent: any) => {
        if (errors.password) {
          errorMessage += '<li>' + t(errorContent) + '</li>';
        }
      })

      errorMessage += '</ul>';

      if (passwordError !== errorMessage) {
        setPasswordError(errorMessage);
      }
    }
  } else {
    if (passwordError) {
      setPasswordError('');
    }
  }

  const handleCheckPhoneNumber = (phoneNumber: string): boolean => !phone('+' + watch('phoneNumber')).isValid
  if(!termsAndConditionStatus){
    navigate('/termsAndConditions')
    return <></>
  }
  if (cognitoKeysLoading) {
    return <Loading />
  }
  return (
    <div>
        <form onSubmit={handleSubmit(signUp)} autoComplete="off">
          <div className="mt-2 max-w-[30rem]">
            <div className="grid grid-cols-4 gap-2">
              <div className="md:col-span-2 col-span-4">
                <Field className="capitalize"
                  {...register('firstName')}
                  error={errors?.firstName?.message}
                  name="firstName"
                  data-testid="input_firstName"
                  type="text"

                  placeholder={t('firstName') + ' *'}
                  maxLength={25}
                />
              </div>
              <div className="md:col-span-2 col-span-4">
                <Field className="capitalize"
                  {...register('lastName')}
                  error={errors?.lastName?.message}
                  name="lastName"
                  data-testid="input_lastName"
                  type="text"
                  placeholder={t('lastName') + ' *'}
                  maxLength={25}
                />
              </div>
              <div id="input_countryCode" data-testid="input_countryCode" className="md:col-span-2 col-span-4">
                <Controller
                  control={control} name={"phoneNumber"} render={({ field: { onChange, value, name, ref } }) => (
                    <PhoneNumberField
                      value={value} data-testid="input_phoneNo" placeholder={t('phoneNo') + ' *'} onChange={(value: any) => onChange(value)}
                    />
                  )}
                />
              </div>
              <span id="error_phoneNo" data-testid="error_phoneNo" className='text-red-500 text-sm col-span-4'>{watch('phoneNumber') && handleCheckPhoneNumber(watch('phoneNumber')) ? t('enterValidCountryCodeAndPhoneNo')
                : t(errors?.phoneNumber?.message as string)}</span>

              <div className="col-span-4 w-full">
                <Field
                  {...register('email')}
                  error={errors?.email?.message}
                  data-testid="input_email"
                  name="email"
                  type="email"
                  placeholder={t('enterValidEmailID') + ' *'}
                  id="input_email"
                />
              </div>
              <div className="col-span-4 w-full">
                <Field
                  {...register('password')}
                  name="password"
                  type="password"
                  data-testid="input_password"
                  placeholder={t('password') + ' *'}
                />
                {passwordError &&
                  <span
                    id="error_password" data-testid="error_password" className="text-red-500 text-sm"
                    dangerouslySetInnerHTML={{ __html: passwordError, }}
                  ></span>
                }
              </div>
              <div className="md:col-span-4 col-span-2 font-semibold">
                {maxDob && minDob && (<DatePicker
                  label={t('dateOfBirth')} name="dateOfBirth" control={control} error={errors.dateOfBirth?.message} minDate={minDob}
                  maxDate={maxDob} placeholderText='yyyy-mm-dd'
                />)}
                {/* <small className="text-blue-900 ml-1 mt-1 hidden md:block">{t(`TheMinimumEntryAgeIs18`)}</small> */}
              </div>
              <div className="md:col-span-4 col-span-2 font-semibold">
                <Field
                  {...register('gender')} label={t('gender')} name="gender" data-testid="input_gender" type="select"
                  error={errors.gender?.message}
                >
                  <option value="Male" key="Male">{t('male')}</option>
                  <option value="Female" key="Female">{t('female')}</option>
                  <option value="Others" key="Others">{t('others')}</option>
                </Field>
              </div>
              <div className="text-blue-900 ml-1 col-span-4 text-sm font-semibold block md:hidden">{t(`TheMinimumEntryAgeIs18`)}</div>
              <div className="col-span-4 flex justify-center">
                {siteKey && (
                  <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
                    <GoogleReCaptcha onVerify={onChange} />
                  </GoogleReCaptchaProvider>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center " >
            <Button
              className="w-72 rounded-lg mt-4 mb-2"
              color="save"
              id="btn_next1"
              data-testid="btn_next1"
              disabled={!recaptchaValue  || handleCheckPhoneNumber(watch('phoneNumber'))}
              submit
            >
              <div>{t('next') + ' 1/4 '}</div>
            </Button>

            {/* <div>
                <a
                  href={`https://${cognitoKeys.domain}/oauth2/authorize?response_type=token&client_id=${cognitoKeys.userPoolClientId}&identity_provider=Facebook&redirect_uri=${linkData}loginSuccessful/`}
                >
                  <Button
                    color="facebook"
                    id="btn_signInWithFacebook"
                    // className="flex justify-around px-5 w-44 md:w-64 mb-1 md:mb-2"
                  >
                    <div className="flex items-center">
                      {t('signInWith')}{' '}
                      <img
                        className="ml-2"
                        width={13}
                        height={13}
                        src={facebook}
                        alt=""
                      ></img>
                    </div>
                  </Button>
                </a>
              </div> */}
              <div></div>
            <div>
              <a
                href={`https://${cognitoKeys.domain}/oauth2/authorize?response_type=token&client_id=${cognitoKeys.userPoolClientId}&identity_provider=Google&redirect_uri=${linkData}loginSuccessful/`}
              >
                <Button
                  color="google"
                  id="btn_signInWithGoogle"
                  data-testid="btn_signInWithGoogle"
                >
                  <div className="flex items-center">
                    {t('signUpWith')}{' '}
                    <img
                      className="ml-2"
                      width={12}
                      height={12}
                      src={google}
                      alt=""
                    ></img>
                  </div>
                </Button>
              </a>
            </div>
          </div>
          <span
            className="flex justify-center hover:underline cursor-pointer"
            id="btn_alreadyMember"
          >
            <Link to="/signIn">{t('alreadyAMember')}?</Link>
          </span>
        </form>
      <Modal isOpen={isOpen} toggle={toggle} closeOnClickOutside={false}>
        <ModalCloseButton toggle={toggle} />
        <ModalHeader>{t('confirmPhoneNumber')}</ModalHeader>
        <ModalBody>
          {getValues().phoneNumber && (
            <span className="flex">
              <span data-testid="txt_confirm">{t('confirmThatYourPhoneNumberIs')}</span>
              <span className="ml-1 font-semibold">
                + {getValues().phoneNumber}
              </span>
              ?
            </span>
          )}
        </ModalBody>
        <ModalFooter>
          <Button id="btn_close" data-testid="btn_close" onClick={toggle} color="footerButton">{t('close')}</Button>
          <Button
            id="btn_confirm"
            data-testid="btn_confirm"

            color="footerButton"
          >
            {t('confirm')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

/**
 * The SignUp component is the entry point for the sign-up process.
 * It renders a form for the user to fill in their information,
 * and handles the submission of the form and the verification
 * of the user's email address and phone number.
 *
 * If the user is already signed in, it redirects them to the
 * home page. If the user's email address is already registered,
 * it displays an error message. If the token is invalid, it
 * displays an error message.
 *
 * @param token - The token from the invitation link.
 *
 * @returns A JSX element containing the sign-up form and any
 * error messages.
 */
function SignUp() {
  // const { token } = useParams()
  const { t } = useTranslation()
  const { pathname } = useLocation();

  window.localStorage.clear()
  const navigate = useNavigate()

  const currentUser = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (currentUser && pathname !== '/s/home') {
      navigate('/s/home')
    }
  }, [currentUser])

  const {
    loading,
    siteKey,
    ipAddressData,
  } = useSignUpHook()
  if (loading ) {
    return <Loading />
  }

  return (
    <InitialPage>
      <div className={`flex pl-0 justify-center lg:w-1/3`} >
        <div
          className="flex my-auto flex-col p-3 rounded-lg md:opacity-90 bg-none"
        >
          <img src={BeemgPrimaryLogo} alt="Beemg Logo" className='h-32 w-32 mx-auto' />
          <span className="text-4xl sm:text-normal uppercase font-bold mb-4 text-center">{t('signUp')}</span>
          {/* {userData && (
            <span id="error_emailReg" data-testid="chk_emailReg" className="flex justify-center text-red-500">
              {t('emailAlreadyRegistered')}
            </span>
          )} */}
          {/* {!invite && (
            <span id="error_invalidToken" className="flex justify-center text-red-500">{t('invalidToken')}</span>
          )} */}
          <SignUpContent
            siteKey={siteKey}
            ipAddressData={ipAddressData}
          />
        </div>
      </div>
    </InitialPage>
  )
}

export default SignUp
