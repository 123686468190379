/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {formatDateToTimezone} from '../Utils/datetime';
import {MeetingParticipant} from '../models/meetingParticipant.model';
import {Meeting} from './../models/meeting.model';
import {allApis} from './allApis';

/**
 * Creates a meeting participant API with endpoints for managing meeting participants, including creating, updating, and retrieving participant data.
 *
 * @param {object} allApis - The API object used to inject endpoints.
 * @return {object} An object containing the meeting participant API endpoints.
 */

export const meetingParticipantApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    createParticipant: builder.mutation<MeetingParticipant, Partial<Meeting>>({
      query: meeting => ({
        url: `meetingParticipant/freeEvent`,
        method: 'POST',
        body: meeting,
      }),
      invalidatesTags: (result, error, meeting) => [
        {type: 'MeetingRegister', id: meeting?.meetingDetails?.id},
        'MeetingRegisterAllMeeting',
        'MeetingRegisterForScheduledMeetings',
        'nextEventHeader',
      ],
    }),
    // getByMeetingWhoJoined: builder.query<MeetingParticipant[], string>({
    //   query: meetingId => `meetingParticipant/meetingJoin/${meetingId}`,
    // }),

    // getByMeetingAll: builder.query<MeetingParticipant[], string>({
    //   query: meetingId => `meetingParticipant/participants/${meetingId}`,
    // }),

    getAllParticipantByMeeting: builder.query<MeetingParticipant[], string>({
      query: meetingId => `meetingParticipant/allParticipants/${meetingId}`,
      transformResponse: (response: MeetingParticipant[]) => {
        const res = response.map<MeetingParticipant>(meetingParticipant => {
          meetingParticipant.display_createdAt = formatDateToTimezone(
            meetingParticipant.createdAt.toString(),
          );
          return meetingParticipant;
        });
        return res;
      },
    }),

    getAllInvitedGuests: builder.query<MeetingParticipant[], string>({
      query: ledgerId => `meetingParticipant/getAllInvitedGuests/${ledgerId}`,
      providesTags: (result, error, ledgerId) => [
        {type: 'CancellationRequest', id: ledgerId},
        'PaidInviteList',
        'EditInvitedList',
      ],
    }),
    updateConfirmationStatus: builder.mutation<MeetingParticipant, any>({
      query: args => {
        const {meetingParticipantId, value} = args;
        return {
          url: `meetingParticipant/updateGuestStatus/${meetingParticipantId}/${value}`,
          method: 'POST',
        };
      },
      invalidatesTags: ['AttendingMeeting'],
    }),
    updateNewPaidGuestEmail: builder.mutation<MeetingParticipant[], any>({
      query: args => {
        const {meetingParticipantId, email} = args;
        return {
          url: `meetingParticipant/guest/updateEmail/${meetingParticipantId}/${email}`,
          method: 'POST',
        };
      },
      invalidatesTags: ['EditInvitedList'],
    }),

    // update: builder.mutation<MeetingParticipant, Partial<MeetingParticipant>>({
    //   query: meetingParticipant => {
    //     return {
    //       url: `meetingParticipant/${meetingParticipant.id}`,
    //       method: 'PUT',
    //       meetingParticipant,
    //     }
    //   },
    // }),
    cancelParticipantFreeEvent: builder.mutation<boolean, string>({
      query: meetingParticipantId => ({
        url: `meetingParticipant/cancelFreeEvent/${meetingParticipantId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AttendingMeeting', 'ReRegisterAfterCancel'],
    }),

    createCohostForEvents: builder.mutation<
      MeetingParticipant[],
      {meetingId: string; email: string}
    >({
      query: ({meetingId, email}) => {
        return {
          url: `meetingParticipant/cohost/${meetingId}`,
          method: 'POST',
          body: {email},
        };
      },
      invalidatesTags: ['CoHost'],
    }),

    getAllCohost: builder.query<MeetingParticipant[], string>({
      query: meetingId => `meetingParticipant/cohost/${meetingId}`,
      providesTags: ['CoHost', 'DeleteCoHost'],
    }),

    deleteCohostForEvent: builder.mutation<
      boolean,
      {meetingId: string; coHostId: string}
    >({
      query: ({meetingId, coHostId}) => {
        return {
          url: `meetingParticipant/cohost/${meetingId}/${coHostId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['DeleteCoHost'],
    }),
  }),
});
export const {
  useUpdateNewPaidGuestEmailMutation,
  useGetAllInvitedGuestsQuery,
  useCancelParticipantFreeEventMutation,
  useUpdateConfirmationStatusMutation,
  useCreateParticipantMutation,
  useGetAllParticipantByMeetingQuery,

  useCreateCohostForEventsMutation,
  useDeleteCohostForEventMutation,
  useGetAllCohostQuery,
} = meetingParticipantApi;
