/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

// import { lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next';
import { MeetingDetails } from '../../models/meetingDetails.model'
// import Loading from '../../Components/base/loading/loading'
import ReactQuill from 'react-quill'
import { useEffect, useState } from 'react';

// const ReactQuill = lazy(() => import("react-quill"));

interface _ReactQuillEditorProps {
    meetingDetails: MeetingDetails | undefined
    errors: any
    watch: any
    setValue: any
    editorRequired?: boolean
}



/**
 * A React component that renders a Quill editor for editing event details.
 * The component also handles language changes and keeps the editor content
 * in sync with the form value.
 *
 * @prop {MeetingDetails | undefined} meetingDetails - The meeting details to be edited.
 * @prop {any} errors - The form error values.
 * @prop {any} watch - The form watch function.
 * @prop {any} setValue - The form set value function.
 * @prop {boolean} [editorRequired=false] - A flag to indicate whether the editor is required.
 *
 * @return {JSX.Element} The Quill editor component.
 */
export default function ReactQuillEditor(props: _ReactQuillEditorProps) {
    const { meetingDetails, errors, watch, setValue, editorRequired=false  } = props
    const { t, i18n } = useTranslation();
    const editorModules = {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ 'font': ['monospace'] }],
            ]
        }
    };
    const formats = ['background', 'bold', 'color', 'font', 'code', 'italic', 'link', 'size', 'strike', 'script', 'underline', 'blockquote', 'header', 'indent', 'list', 'align', 'direction', 'code-block', 'formula']
    const editorContent = watch("description");
    const [key, setKey] = useState(0)

    useEffect(() => {
        /**
         * A function to handle language change events by forcing the component to
         * remount with a new key. This is necessary because the Quill editor is not
         * aware of language changes and needs to be remounted in order to update the
         * editor content with the new language.
         */
        const handleLanguageChange = () => {
            setKey(prevKey => prevKey + 1) // Force a remount by changing the key
        }

        i18n.on('languageChanged', handleLanguageChange)

        return () => {
            i18n.off('languageChanged', handleLanguageChange)
        }
    }, [i18n])

    return (
       <>
        <div className='relative border-t rounded-xl mb-8 max-w-[40rem]'>
            {/* <Suspense fallback={<Loading />}> */}
                <ReactQuill
                    key={key}
                    theme="snow"
                    value={editorContent || meetingDetails?.description || ''}
                    onChange={(_, __, ___, editor) => {
                        setValue('description', editor.getHTML())
                    }}
                    id="input_eventDetails"
                    placeholder={t('eventDetails') + ' *'}
                    modules={editorModules}
                    formats={formats}
                    preserveWhitespace
                />
            {/* </Suspense> */}
        </div>
        <div className={
                editorRequired === true
                ? 'mt-16 md:mt-10': 'mt-3'}>
            {errors?.description?.message && watch('description') !== false &&(
                <span id="error_description" role="alert" className={`text-sm text-red-500 mt-1`}>
                    {t((errors?.description as any).message)}
                </span>
            )}
            {editorContent?.trim().length > 2000 && (
                <span data-testid="error_maxDescription" id="error_maxDescription" role="alert" className={`text-sm text-red-500 mt-1`}>
                    {t(`eventDescriptionShouldBeMax2000CharactersLong`)}
                </span>
            )}
        </div>
       </>
    )
}