/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {Category} from '../models/category.model';
import {allApis} from './allApis';

/**
 * Defines the endpoints for category API.
 *
 * @param {object} builder - The builder object used to define the endpoints.
 * @return {{ getCategoriesMain: query, getSubCategory: query }} - An object containing the defined endpoints.
 */

export const categoryApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getCategoriesMain: builder.query<Category[], void>({
      query: () => 'category',
      // providesTags: ['Category']
    }),
    getSubCategory: builder.query<Category[], string>({
      query: id => `category/subCategory/${id}`,
      // providesTags: ['Category']
    }),
    // getUserCategories: builder.query<Category, void>({
    //   query: () => "category/user",
    //   providesTags: ['Category']
    // })
  }),
});
export const {useGetCategoriesMainQuery, useGetSubCategoryQuery} = categoryApi;
