/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {useState, useEffect} from 'react';
import {Pagination} from '../models/paginationReact.model';

interface PageNumberDisplay {
  displayStartPageNumber: number;
  displayEndPageNumber: number;
}

export interface IPaginationElement {
  pagination: Pagination & PageNumberDisplay;
  nextPage: () => void;
  prevPage: () => void;
  goToPage: (pageNumber: number) => void;
  firstPage: () => void;
  lastPage: () => void;
}

/**
 * A custom React hook for managing pagination of data fetched from an API.
 *
 * It provides the current page data, loading state, refetch function, pagination data, and success status.
 *
 * @param {any} useQuery - A query hook from a data fetching library (e.g. React Query).
 * @param {T} [filter] - Optional filter parameters to pass to the query hook.
 * @return {Object} An object containing the page data, loading state, refetch function, pagination data, success status, and pagination element.
 */

function useFetchDataHooks<T>(useQuery: any, filter?: T) {
  const [pagination, setPagination] = useState<Pagination & PageNumberDisplay>({
    pageIndex: 0,
    pageSize: 5,
    totalElements: 0,
    totalPages: 0,
    sortBy: '',
    displayStartPageNumber: 0,
    displayEndPageNumber: 0,
  });

  const {
    data: pageData,
    isFetching: isPageLoading,
    refetch: pageDataRefetch,
    isSuccess: pageDataSuccess,
  } = useQuery({
    ...filter,
    pageSize: pagination.pageSize,
    pageIndex: pagination.pageIndex,
  });

  useEffect(() => {
    if (pageData?.page) {
      const totalPages = Math.ceil(
        pageData?.page?.totalElements / pageData?.page?.pageSize,
      );

      let displayStartPageNumber =
        pageData?.page?.pageIndex - 1 > 0 ? pageData?.page?.pageIndex - 1 : 1;
      let displayEndPageNumber =
        pageData?.page?.pageIndex + 3 < totalPages
          ? pageData?.page?.pageIndex + 3
          : totalPages;
      const diff = displayEndPageNumber - displayStartPageNumber;
      if (diff < 4) {
        if (displayStartPageNumber - (4 - diff) > 0) {
          displayStartPageNumber = displayStartPageNumber - (4 - diff);
        } else {
          displayEndPageNumber = displayEndPageNumber + (4 - diff);
        }
      }

      setTimeout(() => {
        setPagination({
          ...pagination,
          totalPages,
          totalElements: pageData?.page?.totalElements,
          displayStartPageNumber,
          displayEndPageNumber,
        });
      }, 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData]);

  return {
    pageData,
    isPageLoading,
    pageDataRefetch,
    setPagination,
    pageDataSuccess,
    pagination: {pagination} as IPaginationElement,
  };
}

export default useFetchDataHooks;
