/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { HTMLAttributes, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface Props extends HTMLAttributes<HTMLDivElement> {
  type?: string,
  children?: ReactNode
  className?: string
}

/**
 * A component that displays a message when there are no sessions of a certain type.
 *
 * This component takes three props: type, children, and className. The type prop determines
 * which message to display, and the children prop is used to pass in a custom message. The
 * className prop is used to set the class name of the container div.
 *
 * The component will display a message based on the type prop as follows:
 * - 'events': "You have no active events"
 * - 'creators': A message saying that there are no upcoming events and that the user will
 *   start seeing events as soon as something comes up.
 * - 'transactions': "No transactions"
 * - 'noDebit': "No debit transactions"
 * - 'noCredit': "No credit transactions"
 * - 'feedbacks': "No feedbacks"
 * - 'pastEvents': "You have no past events"
 * - 'collobraters': A message saying that there are no results found for the name and that
 *   the user should try a different search term.
 *
 * If the type prop is not one of the above, the component will display the children prop
 * as the message.
 *
 * @param {Object} props The component props.
 * @param {string} [props.type] The type of sessions to display a message for.
 * @param {ReactNode} [props.children] The custom message to display.
 * @param {string} [props.className] The class name to set on the container div.
 * @returns {JSX.Element} The rendered component.
 */
export function NoSession({ type, children, className = "justify-center" }: Props) {
  const { t } = useTranslation()

  let displayText = children;

  if (type === 'events') {
    displayText = (<div className='text-gray-400'>{t('youHaveNoActiveEvents')} </div>)
  } else if (type === 'creators') {
    displayText = (
      <div className='flex flex-col gap-y-2 items-start justify-start'>
        <div className="text-2xl font-medium text-gray-500">{t('noUpcomingEvents')}</div>
        <div className="text-sm font-medium text-gray-400">{t('youWillStartSeeingYourEventsAsSoonAsSomethingComesUp')}</div>
      </div>
    );
  } else if (type === 'transactions') {
    displayText = (<div className='text-gray-400 my-72'>{`${t('noTransactions')}`}</div>)
  } else if (type === 'noDebit') {
    displayText = (<div className='text-gray-400 my-72'>{`${t('noDebitTransactions')}`} </div>)
  }else if (type === 'noCredit') {
    displayText =(<div className='text-gray-400 my-72'>{ `${t('noCreditTransactions')}`} </div>)
  }else if (type === 'feedbacks') {
    displayText = (<div className='text-gray-400 my-52'>{`${t('noFeedbacks')}`}</div>)
  }
  else if (type === 'pastEvents') {
    displayText = (<div className='text-gray-400'>{t('youHaveNoPastEvents')} </div>)
  }
  else if (type === 'collobraters') {
    displayText = (
    <div className='flex flex-col gap-y-2 justify-start items-start md:-ml-36'>
      <div className="text-2xl font-medium text-gray-500">{t('noResultsFoundForTheName')}</div>
      <div data-testid="txt_collabraters" className="text-sm font-medium text-gray-400">{t('pleaseTryADifferentSearchTerm')}</div>
    </div>
  );
  } else if (type === 'requests') {
    displayText = (<div className='text-gray-400'>{t('youHaveNoActiveRequests')} </div>)
  }

  return (
    <div data-testid="no_meetings" id="txt_noSessions" className={`flex text-xl font-medium my-2 ${className}`}>
      {displayText}
    </div>
  )
}
export default NoSession
