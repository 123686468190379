/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {CollaboratorsProps} from '../Components/Collaborators/collaboratorsCard';

/**
 * A custom React hook for sorting collaborators based on their match value.
 *
 * @param {CollaboratorsProps} props - The props object containing the users to be sorted.
 * @return {object} An object containing the sorted collaborators.
 */

export const useCollaboratorMatchHooks = (props: CollaboratorsProps) => {
  const sortedCollaborators = props.users
    ? [...props.users].sort((a, b) => b.match - a.match)
    : [];
  return {sortedCollaborators};
};
