/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

export const Config = {
  API_URL: '/api',
  WS_URL: '/socket',
  LIVEKIT_URL: 'wss://meeting.beemg.com',
  LIVEKIT_TOKEN: 'https://meeting.beemg.com',
};

