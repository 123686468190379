/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { debounce, isEmpty } from "lodash"
import { useEffect, useRef, useState } from "react"
import {  FieldArrayWithId, FormProvider, UseFieldArrayAppend, useController, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Button } from "../../Components"
import useToggle from "../../Components/_utils/useToggle"
import Field from "../../Components/base/field/field"
import Icon from "../../Components/base/icon/icon"
import { Modal } from '../../Components/base/modal/modal'
import { ToolTip } from "../../Components/base/tooltip/tooltip"
import { RequestForMeetingForm, useRequestForMeetingHooks } from "../../Hooks/RequestForMeetingHook"
import { tr } from "../../Utils/helper"
import { Preference } from "../../models/preference.model"
import { MeetingTimer } from "../MeetingRequest/MeetingTimer"
import { useCurrencyHook } from '../../Hooks/CurrencyHooks'
import { useCheckMeetingExists } from '../../Hooks/CheckMeetingExists'
import { getCurrentUser } from "../../Services/userReducer"
import { useSelector } from "react-redux"
import {AnonymousToggleModal} from './AnonymousToggleModal'
import  CloseIcon  from '../../Assets/icons/beeMG-icons-close.svg'

interface _RequestForMeetingProps {
  closeModal: (e: 'YES' | "CLOSE") => void
  onSendRequest: (formData: RequestForMeetingForm) => void
  hostId: string
}

/**
 * Component to handle the request for meeting functionality.
 *
 * @param {Object} props The component props.
 * @param {function} props.closeModal Function to close the modal.
 * @param {function} props.onSendRequest Function to send the request.
 * @param {string} props.hostId The host id.
 * @returns {JSX.Element} The component JSX.
 */
export function RequestForMeeting(props: _RequestForMeetingProps) {
  const { closeModal, onSendRequest, hostId } = props
  const { t } = useTranslation()
  const currentUser = useSelector(getCurrentUser)
  const [meetingExistsDisable, setMeetingExistsDisable] = useState(false)
  const { toggle } = useToggle()

  const [selectedDates2Errors, setSelectedDates2Errors] = useState<boolean>(false);
  const [selectedDates3Errors, setSelectedDates3Errors] = useState<boolean>(false);
  const [sendBtnDisabled, setSendBtnDisabled] = useState<boolean>(false);


  const {
    dateOptionsFields,
    appendOption,
    removeOption,
    preference,
    minCost,
    requestCount,
    handleSubmit,
    setValue,
    register,
    errors,
    submit,
    watch,
    getValues,
    trigger,
    resetForm,
    RFM_SCHEDULE_DURATION,
    control,
    formMethods,
  } = useRequestForMeetingHooks({hostId})

  const {checkMeetingExists} = useCheckMeetingExists()
  const anonymousToggleRef = useRef(null);

  /**
   * Function to get the state of the Anonymous toggle modal(Child component)
   * and set the form value and send button disabled state accordingly.
   * @param {boolean} data - The state of the Anonymous toggle modal.
   */
  const handleToggleAnonymous = (data:boolean) => { //Using this to get the state of the component from the Anonymous toggle modal(Child component)
    setValue('isRequestAnonymous', data);
    setSendBtnDisabled(data);
  };

  /**
   * Resets the state of the component in the Anonymous toggle modal(Child component).
   */
  const handleAnonymousReset = () => {//Using this to reset the state of the component in the Anonymous toggle modal(Child component)
    if (anonymousToggleRef.current) {
      (anonymousToggleRef.current as any).resetState();
    }
  };

  return (
    <Modal
      isOpen={true}
      toggle={toggle}
      closeOnClickOutside={true}
      animate={true}
      position="semiLarge">
      <div
        className="flex justify-end cursor-pointer"
        id="close"
        onClick={e => closeModal('CLOSE')}>
        <ToolTip tip="close" keyId="closeIcon">
          <img src={CloseIcon} alt="close" width={20} height={20} />
        </ToolTip>
      </div>
      <div className="flex flex-col p-3 w-full">
        {requestCount !== 1 && (
          <div>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(submit)}>
                <div>
                  <div className="flex flex-col gap-y-2 w-full md:px-8 relative rounded-2xl">
                    <div className="flex w-full justify-between items-center">
                      <div className="text-xl text-left font-normal border-b w-full pb-3">
                        {t('privateSessionRequest')}
                      </div>
                    </div>
                    <span>
                      <div>
                        {getValues()?.selectedDates?.map((field, index) => {
                          return (
                            <DisplayOption
                              key={`option${index}`}
                              field={field}
                              index={index}
                            />
                          )
                        })}
                      </div>
                      <div className="h-2" />
                      <div
                        className={`${
                          dateOptionsFields.length >= 3 ? '' : 'border-t-2'
                        } py-3`}>
                        <AddMoreOptions
                          dateOptionsFields={dateOptionsFields}
                          appendOption={appendOption}
                        />
                      </div>

                      {/* Purpose for your private session */}
                      <DisplayDetailsField />
                      <div className="h-4" />

                      {/* Current user timezone */}
                      <div className="grid grid-cols-3 lg:grid-cols-5 gap-4 items-center">
                        <div className="col-span-1 lg:col-span-2 font-normal">
                          {t(`timeZone`)}
                        </div>
                        <div className="col-span-2 md:col-span-1 lg:col-span-2">
                          <DisplayCurrentUserTimezoneField hostId={hostId}/>
                        </div>
                      </div>

                      {/* host timezone */}
                      <div className="grid grid-cols-3 lg:grid-cols-5 gap-4 items-center">
                        <div className="col-span-1 lg:col-span-2 font-normal">
                          {t(`hostTimeZone`)}
                        </div>
                        <div className="col-span-2 md:col-span-1 lg:col-span-2">
                          <DisplayHostTimezoneField hostId= {hostId} />
                        </div>
                      </div>

                      {/* users */}
                      <div className="grid grid-cols-3 lg:grid-cols-5 gap-4 items-center">
                        <div className="col-span-1 lg:col-span-2 font-normal">
                          {t(`users`)}
                        </div>
                        <div className="col-span-2 md:col-span-1 lg:col-span-2">
                          <DisplayUsersField />
                        </div>
                      </div>

                    {/* unitPrice */}
                  <div className="grid grid-cols-3 md:col-span-5 lg:grid-cols-5 gap-4 gap-y-0 items-center relative">
                    <div className="col-span-1 lg:col-span-2 font-normal">{t('price')}</div>
                    <div className="col-span-2 md:col-span-1 lg:col-span-2">
                      <DisplayUnitPriceField  minAmount ={minCost}/>
                    </div>
                    <div className="absolute right-12 top-2" >
                      <div className="flex items-center">
                        <span className="text-xs mr-2">/ {t(`user`)}</span>
                        <ToolTip theme='BeeMG-gray' tip='theminimumAmountCanBeOf' trElements={{ minPrice: minCost?.toFixed(), currency: preference.currency }} keyId='reset'>
                        <Icon icon="QUESTION" size="x-small" />
                        </ToolTip>
                      </div>
                  </div>
                  </div>               

                      {/* Total price */}
                      <div className="grid grid-cols-3 lg:grid-cols-5 gap-4 items-center mt-2">
                        <div className="col-span-1 lg:col-span-2 font-normal">
                          {t('total')}
                        </div>
                        <div className="col-span-2 md:col-span-1 lg:col-span-2">
                          <DisplayCalculatedPrice preference={preference} />
                        </div>
                      </div>

                      <AnonymousToggleModal
                        onToggleAnonymous={handleToggleAnonymous}
                        ref={anonymousToggleRef}
                      />

                      <div className="sm:px-0 flex justify-end gap-x-3 mt-4">
                        <Button
                          color="transparent"
                          autoFocus
                          id="btn_reset"
                          data-testid="btn_reset"
                          onClick={() => {
                            setMeetingExistsDisable(false)
                            setSelectedDates2Errors(false)
                            setSelectedDates3Errors(false)
                            handleAnonymousReset()
                            resetForm()
                          }}
                          size="md">
                          {t('reset')}
                        </Button>
                        <Button
                          color="filterData"
                          className="w-52"
                          disabled={sendBtnDisabled && !currentUser?.anonymous}
                          onClick={async () => {
                            const result = await trigger()
                            if (
                              result &&
                              !meetingExistsDisable &&
                              !selectedDates2Errors &&
                              !selectedDates3Errors
                            ) {
                              onSendRequest(getValues())
                              closeModal('YES')
                            }
                          }}
                          data-testid="btn_send"
                          id="btn_send"
                          size="md">
                          {t('sendRequestRFM')}
                        </Button>
                      </div>
                    </span>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        )}
      </div>
    </Modal>
  )

  /**
   * A component to display one option of the selected dates.
   * @param {Object} field - The field of the selected dates.
   * @param {number} index - The index of the selected date.
   * @returns {JSX.Element} The component to display one option of the selected dates.
   */
  function DisplayOption({ field, index }: { field: FieldArrayWithId<RequestForMeetingForm, "selectedDates", "id">, index: number }) {
    const { meetingExists, setMeetingExists, errorMessage } = useRequestForMeetingHooks({ field, index });
    
    const {field: fieldStartDate} = useController({ name: `selectedDates.${index}.startDate`, control: control });
    const {field: fieldEndDate} = useController({ name: `selectedDates.${index}.endDate`, control: control });

    const meetingTimerRef = useRef<any>();

    useEffect(() => {
      if (meetingExists) {
        setMeetingExistsDisable(meetingExists);
      }
    }, [meetingExists])

    useEffect(() => {

      if (meetingTimerRef?.current && field.startDate && field.endDate) {
        meetingTimerRef.current?.setStartDateAndEndDate({
          startDateTime: field.startDate,
          endDateTime: field.endDate,
        })
      }
    }, [field.startDate, field.endDate])
    

    /**
     * Check if two date strings are the same.
     * @param {string} [dateStrA] - The first date string.
     * @param {string} [dateStrB] - The second date string.
     * @returns {boolean} True if dateStrA and dateStrB are the same, false otherwise.
     */
    const isSame = (dateStrA?: string, dateStrB?: string) => {
      if (!dateStrA) {
        return false
      }
      if (!dateStrB) {
        return false
      }
      const dateA = new Date(dateStrA)
      dateA.setSeconds(0, 0)

      const dateB = new Date(dateStrB)
      dateB.setSeconds(0, 0)

      return dateA.toISOString() === dateB.toISOString()
    }

    useEffect(() => {
      const subscription = watch((value, { name, type }) => {
        if (name?.startsWith('selectedDates')) {
          const selectedDates = value.selectedDates ?? []

          if (selectedDates.length > 1) {
            const dateStr1 = selectedDates[0]?.startDate
            const dateStr2 = selectedDates[1]?.startDate
            const dateStr3 = selectedDates[2]?.startDate

            setSelectedDates2Errors(isSame(dateStr1, dateStr2))
            setSelectedDates3Errors(
              isSame(dateStr1, dateStr3) || isSame(dateStr2, dateStr3),
            )
          }
        }

      })
      return () => subscription.unsubscribe()
    }, [watch])


    const handleUpdate = useRef(
      debounce(async (selectedStartDateTime, selectedEndDateTime, index) => {
        setValue(`selectedDates.${index}.startDate`, selectedStartDateTime)
        setValue(`selectedDates.${index}.endDate`, selectedEndDateTime)
        checkMeetingExists(selectedStartDateTime, selectedEndDateTime, (result) => {
          if (meetingExists !== result) {
            setMeetingExists(result)
            setMeetingExistsDisable(result);
          }
        })
      }, 2000)
    ).current;


    useEffect(() => {
      return () => {
        handleUpdate.cancel();
      };
    }, [handleUpdate]);

    return (
      <div className="">
        <div
          className={`relative flex flex-wrap md:flex-row items-center justify-between gap-y-5 flex-col w-full${
            !meetingExists &&
            errors?.selectedDates &&
            (errors?.selectedDates[index]?.endDate?.message ||
              errors?.selectedDates[index]) &&
            ' border rounded-lg border-red-500 p-1 md:p-2 my-1'
          }`}>
          <div className="flex flex-row items-center justify-between w-full">
            <MeetingTimer
              ref={meetingTimerRef}
              keyId={`session${index + 1}`}
              duration={60}
              RFMLayout={true}
              index={index + 1}
              onFinalSelection={(
                selectedStartDateTime: string,
                selectedEndDateTime: string,
              ) => {
                fieldStartDate?.onChange(selectedStartDateTime)
                fieldEndDate?.onChange(selectedEndDateTime)

                checkMeetingExists(
                  selectedStartDateTime,
                  selectedEndDateTime,
                  result => {
                    if (meetingExists !== result) {
                      setMeetingExists(result)
                      setMeetingExistsDisable(result)
                    }
                  },
                )
              }}
            />

            {dateOptionsFields.length > 1 && (
              <span
                onClick={() => {
                  removeOption(index)
                }}
                id="close_icon"
                data-testid="close_icon"
                className=" ml-4 items-center xs:absolute sm:absolute md:relative right-0 top-0 ">
                  <img src={CloseIcon} alt="close" width={15} height={15} />
              </span>
            )}
          </div>
        </div>
        <div className="flex justify-start">
          {/* Meeting already exists error */}
          {meetingExists && (
            <span id="error_meetingDateExists" data-testid="err_existMeet" className="text-red-500 text-sm">
              {t('youAlreadyHaveMeetingInThisTimeSlot')}
            </span>
          )}
        
          {errorMessage && (
            <span
              id="error_startAt"
              data-testid="error_startAt"
              className="text-red-500">
              {t(errorMessage)}
            </span>
          )}
          <div className="flex gap-x-5">
            {/* Show all start date error messages */}
            {!meetingExists &&
              errors?.selectedDates &&
              errors?.selectedDates[index]?.startDate?.message && (
                <span
                  id="error_endAt2"
                  data-testid="error_startDate"
                  className="text-red-500 text-sm">{`${tr(
                  errors?.selectedDates[index]?.startDate?.message,
                )}`}</span>
              )}

            {!meetingExists &&
              errors?.selectedDates &&
              errors?.selectedDates[index]?.endDate?.message &&
              errors?.selectedDates[index]?.endDate?.message !== 'required' &&
              errors?.selectedDates[index]?.startDate?.message !== 'youHaveChosenPastFromTime' &&
              errors?.selectedDates[index]?.endDate?.message !== 'startTimeAndEndTimeCannotBeSame' &&
              errors?.selectedDates[index]?.endDate?.message !== 'endTimeShouldBeGreaterThanStartTime' &&
              errors?.selectedDates[index]?.endDate?.message !== 'eventDurationMaxMinutes' && (
                <span
                  id="error_endAt2"
                  data-testid="error_endAt2"
                  className="text-red-500 text-sm">{`${tr(
                  errors?.selectedDates[index]?.endDate?.message,
                )}`}</span>
              )}

              {/* Max duration error message */}
            {!meetingExists &&
              errors?.selectedDates &&
              errors?.selectedDates[index]?.endDate?.message &&
              errors?.selectedDates[index]?.endDate?.message !== 'required' &&
              errors?.selectedDates[index]?.startDate?.message !== 'youHaveChosenPastFromTime' &&
              errors?.selectedDates[index]?.endDate?.message !== 'youHaveChosenPastToTime' && (
                <span
                  id="error_endAt2"
                  data-testid="error_maxDuration"
                  className="text-red-500 text-sm">
                  {tr((errors?.selectedDates[index]?.endDate as any)?.message, {
                    maxDuration: RFM_SCHEDULE_DURATION,
                  })}
                </span>
              )}

            {/* Already selected the same date */}
            {!meetingExists &&
              isEmpty(errors?.selectedDates ?? {}) &&
              selectedDates2Errors &&
              index === 1 && (
                <span
                  id="error_endAt2"
                  data-testid="error_endAt2"
                  className="text-red-500 text-sm">{`${tr(
                  'youHaveAlreadyChosenSameTimeSlot',
                )}`}</span>
              )}

            {/* Already selected the same date */}
            {!meetingExists &&
              isEmpty(errors?.selectedDates ?? {}) &&
              selectedDates3Errors &&
              index === 2 && (
                <span
                  id="error_endAt2"
                  data-testid="error_endAt2"
                  className="text-red-500 text-sm">{`${tr(
                  'youHaveAlreadyChosenSameTimeSlot',
                )}`}</span>
              )}
          </div>
        </div>
      </div>
    )
  }
}

interface _DisplayCalculatedPriceProps {
  preference: Preference
}

export default function DisplayCalculatedPrice(
  props: _DisplayCalculatedPriceProps,
) {
  const {preference} = props
  const {getValues, watch} = useFormContext()
  const [calculatedCost, setCalculatedCost] = useState<number>(0)
  const {formatCurrency} = useCurrencyHook()

  useEffect(() => {
    if (getValues().unitPrice && getValues().participants) {
      setCalculatedCost(
        Number(getValues().unitPrice) * Number(getValues().participants),
      )
    } else {
      setCalculatedCost(0)
    }
  }, [getValues()])

  useEffect(() => {
    const subscription = watch((value, {name}) => {
      if (name === 'participants' || name === 'unitPrice') {
        setCalculatedCost(Number(value.unitPrice) * Number(value.participants))
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <div className="flex gap-1 items-center">
      <span id="input_totalPrice">
        {formatCurrency(
          !isNaN(calculatedCost) ? calculatedCost : 0,
          preference.currency,
        )}
      </span>
      <span className="text-xs"></span>
    </div>
  )
}

interface _AddMoreOptions {
  dateOptionsFields: FieldArrayWithId<RequestForMeetingForm, "selectedDates", "id">[]
  appendOption: UseFieldArrayAppend<RequestForMeetingForm, "selectedDates">
}

export function AddMoreOptions(props: _AddMoreOptions) {
  const { dateOptionsFields, appendOption } = props
  const { t } = useTranslation()
  if (dateOptionsFields.length >= 3) {
    return <></>
  }
  return (
    <>
      <span
        className="text-xs text-gray-400 uppercase hover:underline cursor-pointer my-4 font-medium"
        id="btn_addMeeting"
        data-testid="btn_addMeeting"
        onClick={() => {
          appendOption({} as any)
        }}
      >
        + {t('addMoreOptions')} +
      </span>
    </>
  )
}

function DisplayUnitPriceField({minAmount}: {minAmount: number| undefined}) {
  const {
    register,
    formState: {errors},
  } = useFormContext()
  const {formatCurrencySymbol} = useCurrencyHook()
  const {preference} = useRequestForMeetingHooks({hostId: ''})
  const {t} = useTranslation()


  return (
    <Field
      {...register('unitPrice')}
      error={tr((errors?.unitPrice as any)?.message, {minPrice: minAmount})}
      name="unitPrice"
      type="number"
      data-testid="input_unitPrice"
      id="input_unitCost"
      placeholder={t('price') + ' *'}
      restrict="[-+.0-9a-zA-Z]*"
      min={0}
      icon={
        <span className="flex justify-center items-center border-x-2 py-2 bg-gray-200 rounded-l-lg h-fit w-full">
          <span className="text-xs text-gray font-medium">
            {formatCurrencySymbol(preference.currency)}
          </span>
        </span>
      }
      onKeyPress={event => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault()
        }
      }}
    />
  )
}

function DisplayDetailsField() {
  const {
    register,
    formState: {errors},
  } = useFormContext()
  const {t} = useTranslation()

  return (
    <Field
      {...register('details')}
      error={errors?.details?.message}
      name="details"
      type="textarea"
      id="input_details"
      data-testid="input_details"
      placeholder={t(`purposeForYourprivateSession`) + ' *'}
    />
  )
}

function DisplayUsersField() {
  const {
    register,
    formState: {errors},
  } = useFormContext()
  const {t} = useTranslation()
  const {MAX_PARTICIPANTS} = useRequestForMeetingHooks({hostId: ''})

  return (
    <Field
      error={tr((errors?.participants as any)?.message, {
        maxParticipants: MAX_PARTICIPANTS,
      })}
      {...register('participants')}
      name="participants"
      type="number"
      id="input_participants"
      data-testid="input_participants"
      restrict="[-+.0-9a-zA-Z]*"
      min={1}
      max={MAX_PARTICIPANTS}
      placeholder={t('users') + ' *'}
      onKeyPress={(event: any) => {
        if (!`${event.target.value}${event.key}`.match(/^[0-9]{0,2}$/)) {
          event.preventDefault()
          return false
        }
      }}
    />
  )
}

function DisplayHostTimezoneField({ hostId }: { hostId: string }) {
  const {t} = useTranslation()
  const {hostData} = useRequestForMeetingHooks({hostId: hostId})
  return (
    <Field
      defaultValue={hostData?.preference?.timezone}
      name="timezone"
      id="input_hostTimezone"
      data-testid="input_hostTimezone"
      type="text"
      placeholder={t('timezone')}
      disabled
    />
  )
}

function DisplayCurrentUserTimezoneField({ hostId }: { hostId: string }) {
  const {t} = useTranslation()
  const {preference} = useRequestForMeetingHooks({hostId: hostId})

  return (
    <Field
      defaultValue={preference.timezone}
      name="timezone"
      id="input_timezone"
      data-testid="input_timezone"
      type="text"
      placeholder={t('timezone')}
      disabled
    />
  )
}