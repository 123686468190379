/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import Icon from "../../Components/base/icon/icon";
import { useFavoriteIconHook } from "../../Hooks/FavoriteIconHook";
import { Meeting } from "../../models/meeting.model";
import { MeetingDetails } from "../../models/meetingDetails.model";

/**
 * A reusable component that displays a favorite icon and handles favorite functionality for a meeting.
 *
 * @param {MeetingDetails} meetingDetails - The meeting details object containing meeting details.
 * @param {Meeting} meeting - The meeting object containing meeting details.
 * @param {number} [keyId] - An optional keyId for testing purposes.
 * @return {JSX.Element} The rendered FavoriteIcon component.
 */
export function FavoriteIcon({ meetingDetails, meeting, keyId }: { meetingDetails: MeetingDetails, meeting: Meeting, keyId?: number }) {

    const { addFavorite, currentUser } = useFavoriteIconHook()

    return <>
        {meeting?.favorite === "Yes" && currentUser?.id !== meetingDetails?.host?.id ?
            <span id={`btn_favYes${keyId}`} data-testid={`btn_favYes${keyId}`}>
                <Icon icon="FAVOURITES_fill" size="small" stroke="red" onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    addFavorite({ meeting })
                }} /> </span> :
            currentUser?.id !== meetingDetails?.host?.id ?
                <span id={`btn_favNo${keyId}`} data-testid={`btn_favNo${keyId}`}> <Icon icon="FAVOURITES" size="small" onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    addFavorite({ meeting })
                }} /> </span> : <Icon icon="CALENDER_BLACK" size="small" height="small" />
        }
    </>
}