import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccordionBorder, AccordionItem, AccordionPanel } from "../../Components/base/accordion/accordion"
import CardImagePlaceHolder from '../EventsCard/CardImagePlaceHolder';
import CardTitle from '../EventsCard/CardTitle';
import { EventCard } from '../EventsCard/EventCard';
import { DisplayFormattedDate, DisplayFormattedTime } from "../../Components/formattedDateAndTime"
import TransactionAmount from './TransactionAmount';
import StatusGreen from './StatusGreen';
import { Button } from '../../Components';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../Components/base/modal/modal';
import HostCreditCardDetails from './HostCreditCardDetails';
import HostCreditDetailsCollapse from './HostCreditDetailsCollapse';

interface HostCreditProps {
  hostCreditData: {
    meeting_id: string;
    total_amount: string;
    total_transactions: string;
    title: string,
    toDateTime: string,
    fromDateTime: string
    image: any
  };
}

const HostCreditCard: React.FC<HostCreditProps> = ({ hostCreditData }) => {
  const { t } = useTranslation();
  console.log("hostCreditData---3", hostCreditData, hostCreditData.meeting_id)

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const openModal = () => setIsOpen(true);
  return (
    <>
      <AccordionBorder
        keyId={hostCreditData.meeting_id}
        id={hostCreditData.meeting_id}
        key={hostCreditData.meeting_id}
      >
        <AccordionItem
          keyId={hostCreditData.meeting_id}
          toggle={`panel-${hostCreditData.meeting_id}`}
          color="card"
        >
          <EventCard keyId={hostCreditData.meeting_id}>
            <EventCard.Image>
              <CardImagePlaceHolder
                images={hostCreditData?.image}
              />
            </EventCard.Image>
            <EventCard.Title><CardTitle
                keyId={hostCreditData.meeting_id}
                meetingTitle={hostCreditData.title}
              /></EventCard.Title>
            <EventCard.SubTitle>
              <div >
                {t('eventOn')}{' '}
                <DisplayFormattedDate
                  displayDate={hostCreditData.fromDateTime}
                />{' '}
                -{' '}
                {/* <DisplayFormattedTime
                  displayFromTime={transaction?.meeting?.fromDateTime}
                  displayToTime={transaction?.meeting?.toDateTime}
                /> */}
              </div>
            </EventCard.SubTitle>
            <div className="flex flex-col p-4">

              <CardTitle keyId={hostCreditData.meeting_id} meetingTitle={t('hostCreditData')} />
              <div className="font-normal">
                <span>{t('meetingId')}: {hostCreditData.meeting_id}</span>
              </div>
              <div className="font-normal">
                <span>{t('totalAmount')}: {hostCreditData.total_amount}</span>
              </div>
              <div className="font-normal">
                <span>{t('totalTransactions')}: {hostCreditData.total_transactions}</span>
              </div>
            </div>
            <EventCard.Actions>
              <div className="flex md:flex-col text-end items-end gap-3 md:gap-0">
                <StatusGreen>
                  <span className="flex">
                    <span>{hostCreditData.total_amount}</span>
                    {/* <TransactionAmount transaction={transaction} symbol={'+'} /> */}
                  </span>
                </StatusGreen>
              </div>
            </EventCard.Actions>
          </EventCard>
        </AccordionItem>
        <AccordionPanel id={`panel-${hostCreditData.meeting_id}`}>
        <div className="flex flex-col w-full mx-auto items-center justify-center text-sm md:text-base">
        <div className="flex flex-row text-black w-full justify-center items-center text-normal">
          <span
            className="text-center cursor-pointer text-blue-500 hover:text-blue-700 hover:underline"
            onClick={openModal}
          >
            {t('viewDetails')}
          </span>
        </div>
      </div>
        </AccordionPanel>    
      </AccordionBorder>

      <Modal
          isOpen={isOpen}
          toggle={toggle}
          position="large"
          closeOnClickOutside={false}
        >
          <ModalBody>
          {/* <HostCreditCardDetails MeetingId={hostCreditData.meeting_id} />  */}
          <HostCreditDetailsCollapse  MeetingId={hostCreditData.meeting_id}/>
        </ModalBody>
        <ModalFooter>
          <Button color="transparent" onClick={toggle}>{t('close')}</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default HostCreditCard;
