/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useCreatorHooks } from '../../Hooks/CreatorHook'
import { isNotEmptyArray } from '../../Utils/helper'
import { CreatorLocalFilter, CreatorServerFilter } from '../../models/creatorFilter.model'
import NoSession from '../NoSession'
import Loading from '../base/loading/loading'
import CreatorEventsList from './creatorEventsList'

/**
 * A component that renders a list of creator events, divided into different sections
 * based on the meeting start time and whether the event is free or not.
 *
 * @param {{ creatorLocalFilter?: CreatorLocalFilter, creatorServerFilter?: CreatorServerFilter }}
 * @returns {JSX.Element}
 */
const CreatorEvents = ({ creatorLocalFilter, creatorServerFilter }: { creatorLocalFilter?: CreatorLocalFilter, creatorServerFilter?: CreatorServerFilter }) => {

  const {
    isLoading,
    filteredFavoriteMeeting,
    filteredCurrentMeeting,
    filteredNext6hrsMeeting,
    filteredNextDayMeeting,
    filteredWeekendMeeting,
    filteredFestiveMeeting,
    filteredTrendingMeeting,
    filteredCountryMeeting,
    filteredPopularGroupMeeting,
    filteredKidsMeeting,
    upcomingEvents,
    isFiltering,
    noSession
  } = useCreatorHooks(creatorServerFilter, creatorLocalFilter)


  if (isLoading) {
    return (
      <div className="mt-6">
        <Loading />
      </div>
    )
  }

  if (noSession && !isFiltering) {
    return <NoSession type="creators" className='justify-start pl-4 lg:pl-[10%] mb-2' />
  }

  return (
    <div className='pl-4 lg:pl-[10%] flex flex-col gap-y-8'>
      {isNotEmptyArray(filteredFavoriteMeeting) && <CreatorEventsList title={'favorite'} meetings={filteredFavoriteMeeting} />}
      {isNotEmptyArray(filteredCurrentMeeting) && <CreatorEventsList title={'liveEvents'} meetings={filteredCurrentMeeting} />}
      {isNotEmptyArray(filteredNext6hrsMeeting) && <CreatorEventsList title={'startingInNext6Hours'} meetings={filteredNext6hrsMeeting} />}
      {isNotEmptyArray(filteredNextDayMeeting) && <CreatorEventsList title={'laterToday'} meetings={filteredNextDayMeeting} />}
      {isNotEmptyArray(filteredWeekendMeeting) && <CreatorEventsList title={'weekendPlans'} meetings={filteredWeekendMeeting} />}
      {isNotEmptyArray(filteredFestiveMeeting) && <CreatorEventsList title={'festiveSeasonPlans'} meetings={filteredFestiveMeeting} />}
      {isNotEmptyArray(filteredTrendingMeeting) && <CreatorEventsList title={'topTrending'} meetings={filteredTrendingMeeting} />}
      {isNotEmptyArray(filteredCountryMeeting) && <CreatorEventsList title={'popularInYourCountry'} meetings={filteredCountryMeeting} />}
      {isNotEmptyArray(filteredPopularGroupMeeting) && <CreatorEventsList title={'popularGroupEvents'} meetings={filteredPopularGroupMeeting} />}
      {isNotEmptyArray(filteredKidsMeeting) && <CreatorEventsList title={'kidsEvents'} meetings={filteredKidsMeeting} />}
      {isNotEmptyArray(upcomingEvents) && <CreatorEventsList title={'next10Days'} meetings={upcomingEvents} />}
    </div>
  )
}

export default CreatorEvents
