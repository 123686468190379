/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {useSelector} from 'react-redux';
import {useUpdateFavoriteMeetingMutation} from '../Services/favoriteApi';
import {getCurrentUser} from '../Services/userReducer';

/**
 * Custom React hook for managing the favorite icon functionality.
 *
 * @return {Object} An object containing the addFavorite function and the currentUser.
 */

export const useFavoriteIconHook = () => {
  const currentUser = useSelector(getCurrentUser);
  const [addFavorite] = useUpdateFavoriteMeetingMutation();

  return {addFavorite, currentUser};
};
