/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import Icon from "../../Components/base/icon/icon"

interface _StarRatingProps {
    rating: number
    iconSize?: "small" | "xx-small" | "x-small" | "medium" | "large" | undefined
}


/**
 * A component to display a star rating for a given rating.
 *
 * @param {Object} props - The component props.
 * @param {number} props.rating - The rating value.
 * @param {'small'|'xx-small'|'x-small'|'medium'|'large'|undefined} [props.iconSize] - The size of the rating icon.
 * @returns {JSX.Element} The star rating component.
 */
export default function StarRating(props: _StarRatingProps) {
    const { rating, iconSize } = props

    return (
        <div className="flex flex-row text-sm font-normal items-center">
            <span className="text-primary">
                <Icon icon="RATING_STAR" bgColor="#FFC200" size={iconSize ? iconSize : 'x-small'} stroke='transparent' />
            </span>
            <span className='ml-1'>
                {rating}
            </span>
        </div>
    )
}
