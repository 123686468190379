/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {meetingDetailsApi} from './meetingDetailsApi';
import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../Store';
import {MeetingDetails} from '../models/meetingDetails.model';

interface MyEventsState {
  myEvents: {[key: string]: MeetingDetails};
}

const initialStateValue: MyEventsState = {
  myEvents: {},
};

/**
 * Handles the extra reducers for the myEventsSlice, including
 *  - getAllMeetingDetails.matchFulfilled: updates the state with the received meeting details
 *  - getMeetingDetails.matchFulfilled: updates the state with the received meeting details
 *
 * @param {object} builder - the builder object used to add matchers
 * @return {void}
 */

export const myEventsSlice = createSlice({
  name: 'myEvents',
  initialState: initialStateValue,
  reducers: {
    resetMyEvents: () => initialStateValue,
  },
  extraReducers: builder => {
    builder.addMatcher(
      meetingDetailsApi.endpoints.getAllMeetingDetails.matchFulfilled,
      (state, action) => {
        action.payload.data.forEach(
          meetingDetail => (state.myEvents[meetingDetail.id] = meetingDetail),
        );
      },
    );
    builder.addMatcher(
      meetingDetailsApi.endpoints.getMeetingDetails.matchFulfilled,
      (state, action) => {
        if (action.payload) {
          state.myEvents[action.payload.id] = Object.assign(
            state.myEvents[action.payload.id] ?? {},
            action.payload,
          );
        }
      },
    );
  },
});

export const getAllMyEvents = (
  state: RootState,
): {[key: string]: MeetingDetails} => state.myEvents.myEvents;
export const getMyEventById = (state: RootState, id: string): MeetingDetails =>
  state.myEvents.myEvents[id];

export const {resetMyEvents} = myEventsSlice.actions;
export default myEventsSlice.reducer;
