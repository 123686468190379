/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {Policies} from '../models/policy.model';
import {CancellationPolicy} from '../models/cancellationPolicy.model';
import {allApis} from './allApis';

/**
 * Defines the policy API endpoints.
 *
 * @param {object} builder - The builder object used to define the endpoints.
 * @return {{ getTermsAndConditions: query, getEventCreationPolicy: query, getCancellationPolicy: query }} - An object containing the defined endpoints.
 */

export const policyApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getTermsAndConditions: builder.query<Policies, void>({
      query: () => 'policies/terms/termsAndConditions',
    }),
    getEventCreationPolicy: builder.query<Policies, void>({
      query: () => 'policies/event/eventCreationPolicy',
    }),
    getCancellationPolicy: builder.query<CancellationPolicy[], void>({
      query: () => 'cancellationPolicy',
    }),
    // getCancellationPolicyByID: builder.query<CancellationPolicy, string>({
    //     query: (id) => `cancellationPolicy/${id}`
    // }),
  }),
});

export const {
  useGetTermsAndConditionsQuery,
  useGetCancellationPolicyQuery,
  useGetEventCreationPolicyQuery,
} = policyApi;
