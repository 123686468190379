/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useTranslation } from 'react-i18next'
import { AccordionBorder, AccordionItem, AccordionPanel } from '../../Components/base/accordion/accordion'
import { useAttendingRequestPendingHooks } from '../../Hooks/AttendingHooks'
import CardImagePlaceHolder from '../EventsCard/CardImagePlaceHolder'
import CardTitle from '../EventsCard/CardTitle'
import TextLink from '../EventsCard/TextLink'
import { useEffect } from 'react'
import { toastSuccess } from '../../Components/toast'
import { DisplayEventCost } from '../EventsCard/DisplayEventCost'
import { useViewReadMore } from '../../Hooks/UpcomingEvents'
import Icon from '../../Components/base/icon/icon'
import { EventCardRequest } from '../EventsCard/EventCardRequest'
import { DisplayFormattedDate, DisplayFormattedTime } from '../../Components/formattedDateAndTime'
import { getCurrentUser } from '../../Services/userReducer'
import { useSelector } from 'react-redux'

const style = {
  reminderIcon: `min-w-rightIcon flex flex-col items-center justify-center`,
  requestDetails: `max-w-md flex gap-1 text-xs font-normal items-baseline`,
  sm_requestDetails: `border-b max-w-md py-2 flex text-xs`,
}

/**
 * Displays a pending meeting request.
 *
 * @param {Object} meeting - the meeting request.
 *
 * @returns {ReactElement} the component.
 */
export function RequestPending({ meeting }: { meeting: any }) {
  const { t } = useTranslation()
  const { detailsLength, setDetailsLength, readMoreToLess, setReadMoreToLess } = useViewReadMore({ })
  const currentUser = useSelector(getCurrentUser)

  const { handlePendingDetails, handlePendingDelete, loadMeetingPendingRequest, deletePendingRequestSuccess, capitalizeFirstLetter } =
    useAttendingRequestPendingHooks()

  useEffect(() => {
    if (deletePendingRequestSuccess) {
      toastSuccess(t('requestDeletedSuccessfully'))
    }
  }, [deletePendingRequestSuccess, t])


  return (
    <div id={"div_" + meeting.id} data-testid={"div_" + meeting.id} onClick={() => handlePendingDetails(meeting.id)}>
      <AccordionBorder>
        <AccordionItem toggle="panel-4" color="card">
          <EventCardRequest>
            <EventCardRequest.Image><CardImagePlaceHolder images={currentUser?.image} keyId={`currentUserImage${meeting.id}`} /></EventCardRequest.Image>
            <EventCardRequest.Image1> <CardImagePlaceHolder images={meeting?.guest?.image} keyId={`guestImage${meeting.id}`} /></EventCardRequest.Image1>
            <EventCardRequest.Title> <CardTitle key={meeting.id} keyId={meeting.id} meetingTitle={`${t('meetingRequestSentTo')} ${capitalizeFirstLetter(meeting.guest.firstName) + ' ' + capitalizeFirstLetter(meeting.guest.lastName)}`} /></EventCardRequest.Title>
            <EventCardRequest.SubTitle>{t('requestedOn')}{' '}<DisplayFormattedDate displayDate={meeting.createdAt}/> </EventCardRequest.SubTitle>
            <EventCardRequest.Icons> <DisplayEventCost  keyId={meeting.id} meeting={meeting} /></EventCardRequest.Icons>
            <EventCardRequest.Actions>
              <div className="grid grid-cols-6 w-full">
                <div className="text-center col-span-5 cursor-pointer font-semibold text-sm text-gray-400">
                  <span className="text-gray-500 text-sm font-medium">{t('pending')}</span>
                  <div className="relative mt-1 flex justify-center">
                    <span
                      id={`btn_deleteRequest${meeting.id}`}
                      className="flex items-center justify-center rounded p-1 cursor-pointer w-32 bg-gray-100 text-gray-400 hover:bg-red-700 hover:text-white"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePendingDelete(meeting);
                      }}
                    >
                      <TextLink link="" type="cancelRequest" className='text-sm'>{t('deleteRequest')}</TextLink>
                    </span>
                  </div>
                </div>
                <div className='flex justify-end mx-auto'>
                  <div className='opacity-0'><Icon size="small" icon="REMINDER" /></div>
                </div>
              </div>
            </EventCardRequest.Actions>
          </EventCardRequest>

        </AccordionItem>
        <AccordionPanel id="panel-4" className=" border-t-2 mt-4 pt-4">
          {loadMeetingPendingRequest &&
          <div className="md:grid md:grid-flow-row flex flex-col grid-cols-12 gap-2 md:gap-0 text-sm lg:text-base border-transparent border-t hover:border-gray-300 pt-5 px-2 md:px-0 lg:pl-32 pl-12">
            <div className='col-span-5 lg:pr-28 pr-10'>
              <ul>
                <div data-testid="ttl_details" className="font-semibold text-left ">{t('details')}:</div>
                <li id="chk_details" data-testid="chk_details" className={style.requestDetails}>
                  <span>
                    {loadMeetingPendingRequest?.details?.length > 50 ?
                      <>
                        {readMoreToLess && <>{loadMeetingPendingRequest?.details.slice(0, detailsLength)} <span className='flex justify-end underline underline-offset-1 cursor-pointer' onClick={() => { setDetailsLength(loadMeetingPendingRequest?.details.length); setReadMoreToLess(!readMoreToLess) }}>{t('readMore')}</span></>}
                        {!readMoreToLess && <>{loadMeetingPendingRequest?.details.slice(0, detailsLength)} <span className='flex justify-end underline underline-offset-1 cursor-pointer' onClick={() => { setDetailsLength(37); setReadMoreToLess(!readMoreToLess) }}>{t('readLess')}</span></>}
                      </> : loadMeetingPendingRequest?.details}
                      {/* {t('participants')} {' '}: <span>{loadMeetingPendingRequest.participants}</span> */}
                  </span>
                </li>
                <li id="chk_participants" data-testid="chk_participants" className="text-xs font-normal">
                  {t('participants')} {' '}: <span>{loadMeetingPendingRequest.participants}</span>
                </li>
              </ul>
            </div>
            <div className=" col-span-7 flex md:text-center">
              <ul>
                <div data-testid="txt_meetingRequest" className="font-semibold text-left">{t('requestedSession')}:</div>
                {loadMeetingPendingRequest.startAt1DateTime && (
                  <li id="select_option1" data-testid="select_option1" className={style.requestDetails}>
                    <span className='text-xs font-normal'>
                      <DisplayFormattedDate displayDate={loadMeetingPendingRequest.display_startAt1Date} />
                    </span>{' '}|{' '}
                    <span className='text-xs font-normal'>
                      <DisplayFormattedTime displayFromTime={loadMeetingPendingRequest.startAt1DateTime} displayToTime={loadMeetingPendingRequest.endAt1DateTime} />
                    </span>
                  </li>
                )}
                {loadMeetingPendingRequest.startAt2DateTime && (
                  <li id="select_option2" data-testid="select_option2" className={style.requestDetails}>
                    <span className='text-xs font-normal'>
                      <DisplayFormattedDate displayDate={loadMeetingPendingRequest.display_startAt2Date} />
                      </span>{' '}|{' '}
                    <span className='text-xs font-normal'>
                      <DisplayFormattedTime displayFromTime={loadMeetingPendingRequest.startAt2DateTime} displayToTime={loadMeetingPendingRequest.endAt2DateTime} />
                      </span>
                  </li>
                )}
                {loadMeetingPendingRequest.startAt3DateTime && (
                  <li id="select_option3" data-testid="select_option3" className={style.requestDetails}>
                    <span className='text-xs font-normal'>
                      <DisplayFormattedDate displayDate={loadMeetingPendingRequest.display_startAt3Date} />
                      </span>{' '}|{' '}
                    <span className='text-xs font-normal'>
                      <DisplayFormattedTime displayFromTime={loadMeetingPendingRequest.startAt3DateTime} displayToTime={loadMeetingPendingRequest.endAt3DateTime} />
                      </span>
                  </li>
                )}
              </ul>
            </div>
          </div>
          
          }
        </AccordionPanel>
      </AccordionBorder>
    </div>
  )
}
