/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {BlockedRequests} from '../models/blockedRequests.model';
import {allApis} from './allApis';

/**
 * Defines the endpoints for blocked request API.
 *
 * @param {object} builder - The builder object used to define the endpoints.
 * @return {{ blockRequest: mutation, getBlockedUserById: query }} - An object containing the defined endpoints.
 */

export const blockRequestApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    // block Request API
    blockRequest: builder.mutation<BlockedRequests, Partial<BlockedRequests>>({
      query: blockedRequest => ({
        url: `blockedRequests`,
        method: 'POST',
        body: blockedRequest,
      }),
      invalidatesTags: ['blockedRequests'],
    }),

    getBlockedUserById: builder.query<BlockedRequests, string>({
      query: id => `blockedRequests/${id}`,
    }),
  }),
});
export const {useBlockRequestMutation, useGetBlockedUserByIdQuery} =
  blockRequestApi;
