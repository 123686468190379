/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import { CancellationPolicy } from './cancellationPolicy.model';
import { CommonEntity } from './commonEntity.model';
import { Image } from './image.model'; 
import { Meeting } from './meeting.model';
import { User } from './user.model';

export enum VideoType {
    Audio = 'Audio',
    Both = 'Audio And Video'
}

export interface MeetingDetails extends CommonEntity {
    title: string;
    description: string;
    help: string[];
    categories: string[];
    autoDelete: boolean;
    kidFriendly: boolean;
    kidsOnly: boolean;
    video: string;
    // videoBaseURL: string;
    image: Image[];
    meetings: Meeting[];
    meetingDetailsId: string;
    host: User;
    videoType: VideoType;
    isAnonymous: boolean;
    isFreeAudioMeeting: boolean | true;
    cancellationPolicy: CancellationPolicy;
    isCoHost: boolean;
    ratingCount: number;
    average: number;

    // Display Fields
    display_isAudioMeeting: boolean | true;
    display_date: string;
    display_time: string;
    display_FromDateTime: string;
    display_ToDateTime: string;
    display_startAt: string;
    display_endAt: string;
    display_actualStartAt: string;
    display_actualEndAt: string;
    display_expectedEndAt: string;
    display_imageUrl: string;
    display_hostName: string;
    helpsList: { id: string, help: string }[];
}