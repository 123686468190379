/*
 * Copyright © 2024 HimitsuLabs. All Rights Reserved.
 */
import {LocalParticipant, Room} from 'livekit-client';
import * as React from 'react';
import {useState} from 'react';

export type UseLocalParticipant = [
  LocalParticipant | undefined,
  React.Dispatch<React.SetStateAction<LocalParticipant | undefined>>,
  Room | undefined,
  React.Dispatch<React.SetStateAction<Room | undefined>>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  string | null,
  React.Dispatch<React.SetStateAction<string | null>>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  string[],  // Use an array of strings here
  React.Dispatch<React.SetStateAction<string[]>>,  // Array setter
];

export const useLocalparticipant = (
  _newLocalParticipant: LocalParticipant | undefined,
  _newRoom: Room | undefined,
): UseLocalParticipant => {
  const [localParticipant, setLocalParticipant] = useState<
    LocalParticipant | undefined
  >();
  const [room, setRoom] = useState<Room | undefined>();
  const [isPip, setIsPip] = useState(false);
  const [selectedAudioOutput, setSelectedAudioOutput] = useState<string | null>(
    null,
  );
  const [showPingAnimation, setShowPingAnimation] = useState(false);

  // Keep audioOutputsList as an array of strings
  const [audioOutputsList, setAudioOutputsList] = useState<string[]>([]);

  return [
    localParticipant,
    setLocalParticipant,
    room,
    setRoom,
    isPip,
    setIsPip,
    selectedAudioOutput,
    setSelectedAudioOutput,
    showPingAnimation,
    setShowPingAnimation,
    audioOutputsList,
    setAudioOutputsList,
  ];
};

interface Props {
  children: React.ReactNode;
}

// Generate context
const LocalParticipantContext = React.createContext<UseLocalParticipant>(
  undefined!,
);

// Generate provider
const LiveKitProvider = ({children}: Props) => {
  const [
    localParticipant,
    setLocalParticipant,
    room,
    setRoom,
    isPip,
    setIsPip,
    selectedAudioOutput,
    setSelectedAudioOutput,
    showPingAnimation,
    setShowPingAnimation,
    audioOutputsList,
    setAudioOutputsList,
  ] = useLocalparticipant(undefined, undefined);

  return (
    <LocalParticipantContext.Provider
      value={[
        localParticipant,
        setLocalParticipant,
        room,
        setRoom,
        isPip,
        setIsPip,
        selectedAudioOutput,
        setSelectedAudioOutput,
        showPingAnimation,
        setShowPingAnimation,
        audioOutputsList,
        setAudioOutputsList,
      ]}>
      {children}
    </LocalParticipantContext.Provider>
  );
};

// Custom context hook
const useLocalParticipantContext = () => {
  return React.useContext(LocalParticipantContext);
};

export {LiveKitProvider, useLocalParticipantContext};
