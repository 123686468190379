/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {useGetAllMaybeMeetingsQuery} from '../Services/meetingApi';
import {getCurrentUser} from '../Services/userReducer';
import {useAppSelector} from '../Store/hooks';

/**
 * Retrieves and filters meeting suggestions based on the current user.
 *
 * @return {{ meetingMaybeFiltered: Meeting[], meetingMaybe: Meeting[], maybeSuccess: boolean, maybeLoading: boolean }} An object containing the filtered meeting suggestions, all meeting suggestions, and query status flags.
 */

export const SuggestionsHooks = () => {
  const {
    data: meetingMaybe,
    isSuccess: maybeSuccess,
    isLoading: maybeLoading,
  } = useGetAllMaybeMeetingsQuery();
  const currentUser = useAppSelector(getCurrentUser);
  const meetingMaybeFiltered = meetingMaybe?.filter(
    meeting => meeting.meetingDetails.host.id !== currentUser.id,
  );

  return {meetingMaybeFiltered, meetingMaybe, maybeSuccess, maybeLoading};
};
