/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import Avatar from '../../Components/base/avatar/avatar'
import useToggle from '../../Components/_utils/useToggle'
import { useViewEventCheckHook } from '../../Hooks/ViewEvent'
import { MeetingDetails } from '../../models/meetingDetails.model'
import { RequestForMeeting } from './RequestForMeeting'
import RequestForMeetingReviews from './RequestForMeetingReviews'
import RFMConfirmationModal from './RFMConfirmationModal'
import { getCurrentUser } from '../../Services/userReducer'
import { useSelector } from 'react-redux'
import { RequestForMeetingForm, useRequestForMeetingHooks } from '../../Hooks/RequestForMeetingHook'
import { useViewHostProfileHook } from '../../Hooks/ViewHostProfileHook'
import { useRequestForMeetingReviewsHook } from '../../Hooks/RequestForMeetingReviewsHook'

interface _RFMHostDetailsProps {
    hostDetails: MeetingDetails
}

    /**
     * This component renders the host details section of the view event page.
     * It shows the host's avatar, name, and a link to view their profile.
     * It also shows a button to request a private session with the host.
     * If the user has already requested a private session, it shows a button to view more events.
     * If the host has reviews, it shows a button to view all private session reviews.
     * @param {object} props - The component props.
     * @param {object} props.hostDetails - The meeting event host details.
     * @param {MeetingDetails} props.hostDetails.host - The host object containing id, firstName, lastName, nickName, and anonymous status.
     * @return {JSX.Element} The rendered component.
     */
export default function RFMHostDetails(props: _RFMHostDetailsProps) {
    const { hostDetails } = props
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { toggle: toggleRFMModal, isOpen: isRFMModalOpen } = useToggle()
    const { toggle: toggleRFMConfirmationModal, isOpen: isRFMConfirmationModal } = useToggle()
    const { toggle: toggleRFMReViewModal, isOpen: isRFMReViewModalOpen } = useToggle()
    const [rfmData, setRFMData] = useState<RequestForMeetingForm | null>(null)
    const { displayEventHostName, hostNavigation, displayEventAnonymous } = useViewEventCheckHook({ hostDetails })
    const hostId = hostDetails?.host?.id ? hostDetails?.host?.id : "";
    const currentUser = useSelector(getCurrentUser);
    const { requestCount, verificationError } = useRequestForMeetingHooks({ hostId })
    const { checkAnonymous } = useViewHostProfileHook(hostId);
    const { reviews } = useRequestForMeetingReviewsHook({ hostId })
    const style = {
        warning: "bg-orange-100 mb-3 text-yellow-900 text-center w-full px-4 p-2 rounded-2xl"
    }

    return (
        <>
            {
                verificationError &&
                <div className='w-full'>
                    <div id="error_notVerified" className={style.warning}>{verificationError}</div>
                </div>
            }
            <div id="btn_openUserProfile" className='w-full border p-6 px-2 md:px-8 relative rounded-2xl gap-y-5'>
                <div className="flex flex-col gap-y-5 items-center">
                    <div className="pr-3 mt-2">
                        <Avatar
                            size="large"
                            images={hostDetails?.host?.image}
                            avatar={hostDetails?.host.avatar}
                            anonymous={hostDetails?.isAnonymous}
                        />
                    </div>
                    <div className='flex flex-col leading-none items-center'>
                        <div className={`flex flex-row items-center gap-x-1`}>
                            {currentUser.id === hostDetails.host.id ? <span className={`font-medium line-clamp-1 text-[1.2rem]`} data-testid='txt_hostedBy'>{t('hostedBy')}&nbsp;</span> : <span className="font-medium line-clamp-1 text-[1.2rem]" data-testid='txt_meet'>{t('meet')}&nbsp;</span>}
                            {(hostNavigation === 'VIEWPROFILE' || hostNavigation === 'VIEWHOST') && displayEventHostName &&
                                <Link id="btn_openProfile"
                                    to={hostNavigation === 'VIEWPROFILE' ? `/s/viewProfile/${hostDetails?.host?.userId}` : `/s/viewHost/${hostDetails?.host.id}`}
                                    className="font-medium line-clamp-1 text-[1.2rem] capitalize"
                                    data-testid="txt_hostName">{displayEventHostName.length > 12 ? displayEventHostName?.substring(0, 14) + '...' : displayEventHostName}
                                </Link>
                            }
                            {(hostNavigation === 'BLOCKEDFORRFM' || hostNavigation === 'SELF') &&
                                <Link id="btn_openProfile" to=''
                                    className="font-medium line-clamp-1 text-[1.2rem] cursor-not-allowed capitalize"
                                    data-testid="txt_hostName">{displayEventHostName}
                                </Link>
                            }
                        </div>
                        {!verificationError && hostNavigation === 'VIEWHOST' && checkAnonymous === 'REQUESTFORMEET' && <span className='text-sm font-normal' data-testid="txt_eventHost" >{t('eventHostIsOpenToConnect')}</span>}
                    </div>
         
                    {(requestCount as number < 1) && !verificationError && hostNavigation === 'VIEWHOST' && checkAnonymous === 'REQUESTFORMEET' && <div data-testid="btn_sendRequest" id="btn_sendRequest" onClick={toggleRFMModal} className={`${reviews && reviews.length > 0 ? 'bg-BeeMG-yellow font-normal p-2 px-3 rounded-md cursor-pointer' : 'bg-BeeMG-yellow font-normal p-2 px-3 rounded-md cursor-pointer mb-2'}`}>{t('requestPrivateSession')}</div>}
                    {(requestCount as number >= 1) && displayEventAnonymous !=="ANONYMOUS" && <div id="btn_viewRequest" data-testid="btn_viewRequest" onClick={() => navigate('/s/events/request-events/sentRequests')} className={`${reviews && reviews.length > 0 ? 'bg-BeeMG-yellow font-normal p-2 px-3 rounded-md cursor-pointer' : 'bg-BeeMG-yellow font-normal p-2 px-3 rounded-md cursor-pointer mb-2'}`}>{t('viewMoreEvents')}</div>}
                    {reviews && reviews.length > 0 && currentUser.id !== hostId && <>
                            <div className="underline font-normal text-gray-400 text-sm cursor-pointer" data-testid="btn_reviews" onClick={toggleRFMReViewModal}>
                            {`${t('allPrivateSessionReviews')} (${reviews.length})`}
                        </div>
                    </>
                    }
                </div>
            </div>
            {isRFMModalOpen && <RequestForMeeting closeModal={(e) => {
                if (e === "YES") {
                    toggleRFMConfirmationModal(e)
                    toggleRFMModal(e)
                }
                else {
                    toggleRFMModal(e)
                }
            }}
                onSendRequest={(val) => { setRFMData(val) }}
                hostId={hostId}
            />
            }
            {isRFMConfirmationModal && rfmData && <RFMConfirmationModal closeRFMConfirmModal={toggleRFMConfirmationModal} rfmData={rfmData} hostId={hostId} />}

            {isRFMReViewModalOpen && <RequestForMeetingReviews closeModal={(e) => {
                if (e === 'YES') {
                    toggleRFMReViewModal(e)
                }
            }} reviews={reviews} />
            }
        </>
    )
}
