/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import Avatar from "../../Components/base/avatar/avatar"
import Button from "../../Components/base/button/button"
import Field from "../../Components/base/field/field"
import Loading from "../../Components/base/loading/loading"
import { ToolTip } from "../../Components/base/tooltip/tooltip"
import { toastSuccess } from "../../Components/toast"
import { useEventCoHostHooks } from "../../Hooks/MyEvents/EventsCoHostHooks"
import { MeetingParticipant } from "../../models/meetingParticipant.model"
import  CloseIcon  from '../../Assets/icons/beeMG-icons-close.svg'

/**
 * A component for adding co-hosts to an event.
 *
 * Provides functionality for creating, deleting, and retrieving co-hosts,
 * as well as handling form submission and error states.
 *
 * @param {string} meetingId - The ID of the meeting for which to manage co-hosts.
 *
 * @return {JSX.Element} The component's JSX element.
 */
function EventCoHost() {
  const { t } = useTranslation()
  const { meetingId } = useParams()

  const { deletedSuccess,
    addedSuccess,
    meetingCohostsSuccess,
    meetingCohosts,
    register,
    errors,
    handleSubmit,
    coHostError, setCoHostError,
    reset, getValues,
    onSubmit,
    onDelete } = useEventCoHostHooks(meetingId ?? '')

  useEffect(() => {
    reset();
    setCoHostError('')
  }, [meetingId])

  useEffect(() => {
    if (coHostError) {
      reset();
      setCoHostError('')
    }
  }, [getValues('email')])

  useEffect(() => {
    if (deletedSuccess) {
      toastSuccess(t("coHostDeletedSuccessfully"))
    }
  }, [deletedSuccess]);

  useEffect(() => {
    if (addedSuccess) {
      reset({ email: "" })
      toastSuccess(t("coHostAddedSuccessfully"))
    }
  }, [addedSuccess]);


  if (!meetingCohostsSuccess) {
    return <Loading />
  }

  return (
    <div className="flex flex-col items-start md:col-span-2">
      <div className='text-black font-normal text-center text-lg mb-2'>{t('addCohost')}</div>
      <div>
        <form onSubmit={e => { e.preventDefault() }}>
          <div className="flex w-auto items-center justify-between">
            <Field
              {...register('email')}
              name="email"
              type="text"
              className={` py-[1.1rem] mt-1 ${(errors?.email as any)?.message && `ring-1 focus:ring-red-500`} ring-red-500`}
              placeholder={t('enterValidEmailID') + ' *'}
            />
            <Button data-testid="btn_addCohost" className="normal-case ml-2 md:ml-5 whitespace-nowrap " id="btn_addCoHost" onClick={handleSubmit(onSubmit)} color="footerButton" >
              {t(`addCohost`)}
            </Button>
          </div>
          {errors?.email?.message && <span data-testid="error_coHostMail" id="error_coHostMail" className="text-red-500 text-sm">{t(`${(errors.email as any).message}`)}</span>}
        </form>
      </div>

      {coHostError &&
        <span data-testid="error_coHost" id="error_coHost" className="text-red-500 font-semibold ml-1">
          {t(coHostError)}
        </span>
      }

      <div className="flex flex-row gap-2 flex-wrap">
        {meetingCohosts && meetingCohosts.map((coHost: MeetingParticipant, index: number) => <EventCoHostCard keyId={index} key={coHost.id} coHost={coHost} onDelete={onDelete} />)}
      </div>

    </div>
  )
}

/**
 * A component for rendering a single co-host card.
 *
 * @param {Object} props - The component props.
 * @param {MeetingParticipant} props.coHost - The co-host data.
 * @param {function} props.onDelete - A callback function to delete the co-host.
 * @param {number} [props.keyId] - An optional key ID for the component.
 *
 * @return {JSX.Element} The component's JSX element.
 */
function EventCoHostCard({ coHost, onDelete, keyId }: { coHost: MeetingParticipant, keyId?: number, onDelete: (coHost: MeetingParticipant) => void }) {

  return (
    <span className="flex flex-col items-center" key={coHost.id}>
      <div className="flex mt-2 ml-2">
        <div className="relative">
          <Avatar
            size="md-small"
            images={coHost.user.image}
            avatar={coHost.user.avatar}
            anonymous={coHost.user.anonymous}
            position="top-right"
          />
          <div
            data-testid={`btn_closeButton${keyId}`}
            id={`btn_closeButton${keyId}`}
            className="absolute top-0 right-0 bg-white rounded-full"
            onClick={() => onDelete(coHost)}>
            <img src={CloseIcon} alt="close" width={15} height={15} />
          </div>
        </div>
        <div className="ml-4">
          <div className="items-center mt-2 capitalize">
            {`${coHost.user?.firstName} ${coHost.user?.lastName}`.length >
              15 ? (
              <ToolTip
                isCapitalize
                tip={`${coHost.user?.firstName} ${coHost.user?.lastName}`}>
                <div className="font-semibold whitespace-nowrap">
                  {`${coHost.user?.firstName} ${coHost.user?.lastName}`.substring(
                    0,
                    15,
                  ) + '...'}
                </div>
              </ToolTip>
            ) : (
              <div className="font-semibold whitespace-nowrap">
                {`${coHost.user?.firstName} ${coHost.user?.lastName}`}
              </div>
            )}
          </div>
        </div>
      </div>
    </span>
  )
}

export default EventCoHost
