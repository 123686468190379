/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {Currency} from './../models/currency.model';
import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../Store';
import {allOthersApi} from './allOthersApi';

interface CurrencyState {
  currencyList: Currency[] | null;
}

const initialStateValue: CurrencyState = {
  currencyList: null,
};

/**
 * A Redux slice for managing currency data.
 *
 * @description This slice provides a way to manage the currency list in the application state.
 * It includes reducers for updating the currency list and a matcher for handling the result of the getAllCurrency API call.
 *
 * @return {Slice} The currency slice.
 */

export const currencySlice = createSlice({
  name: 'currency',
  initialState: initialStateValue,
  reducers: {
    changeToInitial: state => {
      state.currencyList = null;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      allOthersApi.endpoints.getAllCurrency.matchFulfilled,
      (state, action) => {
        state.currencyList = action.payload;
      },
    );
  },
});

/**
 * Retrieves the list of currencies from the application state.
 *
 * @param {RootState} state - The current state of the application.
 * @return {Currency[]} The list of currencies.
 */

export const getCurrencyList = (state: RootState): Currency[] =>
  state.currency.currencyList;
export const {changeToInitial} = currencySlice.actions;
export default currencySlice.reducer;
