/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {Feedback} from './../../models/feedback.model';
import {yupResolver} from '@hookform/resolvers/yup';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {
  feedbackApi,
  useGetByMeetingDetailsIdQuery,
  useGetFeedbackByMeetingIdQuery,
  useUpdateFeedbackMutation,
} from '../../Services/feedbackApi';
import {useGetSettingValue} from '../../Services/settingReducer';
import {replyValidationSchema} from '../../Utils/validation';
import {useAppDispatch} from '../../Store/hooks';

/**
 * Retrieves event review data based on the provided meeting details ID.
 *
 * @param {string} [id] - The meeting details ID.
 * @return {{ feedbackData: any, feedbackDataSuccess: boolean, feedbackDataLoading: boolean }} - An object containing the event review data, success status, and loading status.
 */

export const useEventReviewSelectHook = (id?: string) => {
  const limit = useGetSettingValue('EVENT_FEEDBACK_LIMIT');

  let skip = 0;
  const args = {
    limit: limit,
    skip: skip,
    meetingDetailsId: id,
  };

  const {
    data: feedbackData,
    isSuccess: feedbackDataSuccess,
    isLoading: feedbackDataLoading,
  } = useGetByMeetingDetailsIdQuery(args, {skip: !id});

  return {
    feedbackData,
    feedbackDataSuccess,
    feedbackDataLoading,
  };
};

/**
 * Retrieves event review data and provides functionality for updating replies.
 *
 * @param {object} params - An object containing meetingId, feedback, and feedbacks.
 * @param {string} params.meetingId - The ID of the meeting.
 * @param {Feedback} [params.feedback] - The feedback object.
 * @param {Feedback[]} [params.feedbacks] - An array of feedback objects.
 * @return {object} An object containing various event review data and functionality.
 */

export const useEventReviewsHook = ({
  meetingId,
  feedback,
  feedbacks,
}: {
  meetingId: string;
  feedback?: Feedback;
  feedbacks?: Feedback[];
}) => {
  const dispatch = useAppDispatch();
  const [showReply, setShowReply] = useState<boolean>(false);
  const [reply, setReply] = useState<string>('');
  const limit = useGetSettingValue('EVENT_FEEDBACK_LIMIT');
  const [feedbackSkip, setFeedbackSkip] = useState(feedbacks);

  let skip = 0;
  const args = {
    limit: limit,
    skip: skip,
    meetingId: meetingId,
  };

  const {
    data: feedbackData,
    isSuccess: feedbackSuccess,
    isLoading: feedbackLoading,
  } = useGetFeedbackByMeetingIdQuery(args, {skip: !args?.meetingId});
  const [
    updateReply,
    {isSuccess: replyFeedbackSuccess, isLoading: replyFeedbackLoading},
  ] = useUpdateFeedbackMutation();
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    trigger,
    getValues,
    formState: {errors, isDirty, isValid},
  } = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(replyValidationSchema),
  });

  const getFeedback = (meetingId: string) => {
    if (feedbackSkip) {
      const args = {
        meetingId: meetingId,
        limit: limit,
        skip: feedbackSkip.length > 0 ? feedbackSkip.length : 0,
      };
      const res = dispatch(
        feedbackApi.endpoints.getFeedbackByMeetingId.initiate(args),
      );
      res.then((val: any) => {
        const listed = [...feedbackSkip, ...val.data.results];
        setFeedbackSkip(listed);
      });
    }
  };

  const handleUpdateReply = (values: any) => {
    if (feedback) {
      const inviteRes = Object.assign({}, feedback);
      inviteRes.reply = values.reply;
      updateReply(inviteRes).then(() => {
        setShowReply(false);
      });
    }
  };

  return {
    showReply,
    setShowReply,
    handleSubmit,
    errors,
    isDirty,
    isValid,
    register,
    replyFeedbackSuccess,
    reset,
    reply,
    setReply,
    control,
    getValues,
    handleUpdateReply,
    setValue,
    trigger,
    getFeedback,
    feedbackData,
    feedbackSuccess,
    feedbackLoading,
    replyFeedbackLoading,
  };
};
