/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */
import { Auth } from "aws-amplify";
import { useState } from "react";
import { allOthersApi } from "../Services/allOthersApi";
import { useGetCognitoKeysQuery } from "../Services/envApi";
import { preferenceApi } from "../Services/preferenceApi";
import { useGetSettingValue } from "../Services/settingReducer";
import { changeCognitoModelObject } from "../Services/signUpReducer";
import { changeToken } from "../Services/tokenReducer";
import { useCreateUserSSOMutation, userApi } from "../Services/userApi";
import { useCheckUserTermsPolicyQuery } from "../Services/userPolicyAgreementsApi";
import { changeCurrentUserDetail } from "../Services/userReducer";
import { useAppDispatch } from "../Store/hooks";
import { User, Verified } from "../models/user.model";


/**
 * A custom hook that handles the login successful flow, including retrieving user data,
 * checking policy agreements, and navigating to the sign-in screen if necessary.
 *
 * @return {object} An object containing the loading state, navigation state,
 *                  cognito keys, link data, a function to get the user,
 *                  a flag indicating if the user is an SSO user,
 *                  user policy agreements data, and the loading state of user policy agreements.
 */
export const useLoginSuccessfulHook = () => {
  const { data: cognitoKeys, isSuccess: cognitoKeysSuccess } = useGetCognitoKeysQuery()
  const { data: userPolicyAgreementsData, isLoading: userPolicyAgreementsLoading } = useCheckUserTermsPolicyQuery();
  const linkData = useGetSettingValue('WEB_LINK');
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);
  const [navigateScreenSignIn, setNavigateScreenSignIn] = useState(false);
  const [isSSoUser, setIsSSoUser] = useState<boolean>(false);

  if (!cognitoKeysSuccess || !linkData) {
    if (loading) {
      setLoading(false);
    }
  }

  const handleSSOUserName = (name: string) => {  // Finds if the user is SSO or not
    if (name.includes('google')) {
      setIsSSoUser(true); 
    }
    else {
      setIsSSoUser(false);
    }
  }

  //create a  async delay function
  async function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const getUser = () => {
    Auth.currentAuthenticatedUser().then((resultCurrent) => {
      const userData = {
        address: resultCurrent.signInUserSession.idToken.payload.address?.formatted,
        birthdate: resultCurrent.signInUserSession.idToken.payload.birthdate,
        email: resultCurrent.signInUserSession.idToken.payload.email,
        phone_number: resultCurrent.signInUserSession.idToken.payload.phone_number
      };

      if (userData.address === null) {
        delete userData.address;
      }
      if (userData.birthdate === null) {
        delete userData.birthdate;
      }
      if (userData.email === null) {
        delete userData.email;
      }
      if (userData.phone_number === null) {
        delete userData.phone_number;
      }
      
      const model = {} as User;
      model.firstName = resultCurrent.signInUserSession.idToken.payload.given_name;
      model.lastName = resultCurrent.signInUserSession.idToken.payload.family_name;
      model.token = resultCurrent.signInUserSession.idToken.payload.email;
      model.sub = resultCurrent.signInUserSession.idToken.payload.sub;


      if (resultCurrent.signInUserSession.idToken.payload.email_verified) {
        model.emailVerified = Verified.Complete;
      }

      createUserSSO(model).then(async (res: any) => {

        const accessToken = resultCurrent.signInUserSession.accessToken.jwtToken;
        // Update token before making any API requests
        const checkToken = await dispatch(changeToken(accessToken));
        resultCurrent.attributes = userData;
        await delay(5000)
        // Call preference and other necessary APIs after the token is updated
        await dispatch(preferenceApi.endpoints.getUserPreference.initiate());
        await dispatch(userApi.endpoints.getCurrentUserDetails.initiate());
        await dispatch(allOthersApi.endpoints.getAllCurrency.initiate());
    
        // Fetch user details using the newly created user's sub
        const userDetail = await dispatch(userApi.endpoints.getUserDetail.initiate(res.data.sub));
    
        // Update cognito model and handle username
        await dispatch(changeCognitoModelObject(userDetail.data));
    
        handleSSOUserName(userDetail.data.username);
    
        // Finally, update the current user details
        await dispatch(changeCurrentUserDetail(resultCurrent.attributes));

 
      });
    }).catch(error => {
      console.log("----SSOERROR", error);
      if (!navigateScreenSignIn) {
        setNavigateScreenSignIn(true);
      }
    });
}


  const [createUserSSO] = useCreateUserSSOMutation<any>();

  return { 
    loading,
    navigateScreenSignIn, 
    cognitoKeys, 
    linkData, 
    getUser, 
    isSSoUser,
    userPolicyAgreementsData, 
    userPolicyAgreementsLoading };
}