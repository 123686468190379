/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {Image} from '../models/image.model';
import {allApis} from './allApis';

/**
 * Defines the image API endpoints.
 *
 * @description This API provides endpoints for image-related operations.
 * @returns {Object} The image API object.
 */

export const imageApi = allApis.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    uploadEventImageFile: builder.mutation<
      Image,
      {file: any; meetingDetailsId: string; orderNumber: number}
    >({
      query: args => {
        const {file, meetingDetailsId, orderNumber} = args;
        let testData: FormData = new FormData();
        testData.append('file', file);

        return {
          url: `image/fileMeeting/upload/${meetingDetailsId}/${orderNumber}`,
          method: 'POST',
          body: testData,
        };
      },
      invalidatesTags: (result, error, args) => [
        {type: 'MeetingDetail', id: args.meetingDetailsId},
        'MeetingDetails',
      ],
    }),
    deleteImage: builder.mutation<
      boolean,
      {imageId: string; fileName: string; meetingDetailsId?: string}
    >({
      query: args => {
        const {imageId, fileName} = args;

        return {
          url: `image/${imageId}/${fileName}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, args) =>
        args.meetingDetailsId
          ? [{type: 'MeetingDetail', id: args.meetingDetailsId}]
          : [],
    }),
    updateImage: builder.mutation<Image, Partial<Image>>({
      query: image => {
        return {
          url: `image/${image.id}`,
          method: 'PUT',
          body: image,
        };
      },
      invalidatesTags: ['UpdateMeetingDetailsImage'],
    }),
    uploadUserImage: builder.mutation<Image, any>({
      query: args => {
        const {file} = args;
        let data: FormData = new FormData();
        data.append('file', file);

        return {
          url: `image/file/upload/id`,
          method: 'POST',
          body: data,
        };
      },
    }),
    uploadUserImageMobile: builder.mutation<Image, any>({
      query: args => {
        // {name?: string, type: any, uri: string}
        let data: FormData = new FormData();
        data.append('file', args);

        return {
          url: `image/file/upload/id`,
          method: 'POST',
          body: data,
        };
      },
    }),
    uploadUserAvatarImageMobile: builder.mutation<Image, any>({
      query: file => {
        // {id: string, name?: string, type: any, uri: string}
        let data: FormData = new FormData();
        data.append('file', file);

        return {
          url: `users/file/avatar/upload/id`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['AvatarImageUpload'],
    }),
  }),
});

export const {
  useUploadEventImageFileMutation,
  useDeleteImageMutation,
  useUpdateImageMutation,
  useUploadUserImageMutation,
  useUploadUserImageMobileMutation,
  useUploadUserAvatarImageMobileMutation,
} = imageApi;
