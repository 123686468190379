/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import {createSlice} from '@reduxjs/toolkit';
import {UserDetail} from '../models/userDetail.model';
import {RootState} from '../Store';

interface SignUp {
  token: string | null;
  isSignUpTermsAccepted: boolean;
  cognitoObject: {
    email: string | null;
    password: string | null;
    birthdate: string | null;
    phone_number: string | null;
    sub: string | null;
  } | null;
}

const initialStateValue: SignUp = {
  token: null,
  isSignUpTermsAccepted: false,
  cognitoObject: null,
};

/**
 * Creates a slice of the Redux state for managing the sign-up process.
 *
 * @return {Object} The signUpSlice object containing reducers and actions for managing the sign-up state.
 */

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState: initialStateValue,
  reducers: {
    changeToken: (state, action) => {
      state.token = action.payload;
    },
    changeTermsAndCondition: (state, action) => {
      state.isSignUpTermsAccepted = action.payload;
    },
    changeToInitial: state => {
      state.token = null;
      state.isSignUpTermsAccepted = false;
    },
    changeCognitoModelObject: (state, action) => {
      state.cognitoObject = {
        email: action.payload.email,
        password: action.payload.password,
        birthdate: action.payload.birthdate,
        phone_number: action.payload.phone_number,
        sub: action.payload.sub,
      };
    },
  },
});

export const getSignUpToken = (state: RootState): string => state.signUp.token;
export const getIsSignUpTermsAccepted = (state: RootState): boolean =>
  state.signUp.isSignUpTermsAccepted;
export const getCognitoObject = (state: RootState): UserDetail =>
  state.signUp.cognitoObject;
export const {
  changeToken,
  changeTermsAndCondition,
  changeToInitial,
  changeCognitoModelObject,
} = signUpSlice.actions;
export default signUpSlice.reducer;
