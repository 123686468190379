/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useTranslation } from 'react-i18next';
import useCheckOnlineHook from '../Hooks/CheckOnlineHook';

interface _Props {
  id: string | undefined
  className?: string
}

/**
 * A component to display a user's online status as text.
 *
 * This component takes two props: `id` which is the ID of the user whose online status is to be displayed,
 * and `className` which is the CSS class to apply to the text.
 *
 * The text is displayed in a `div` element with a class of `leading-none` to prevent the text from taking up
 * more space than needed.
 *
 * The component displays the translated text "online" if the user is online, and "offline" if the user is offline.
 *
 * @param {Object} props The props object, which should contain the `id` and optional `className`.
 * @param {string} [props.className] The CSS class to apply to the text.
 * @param {string} props.id The ID of the user whose online status is to be displayed.
 * @returns {JSX.Element} A JSX element representing the user's online status text.
 */
function OnlineStatusText(props: _Props) {
  const { id, className } = props;
  const { t } = useTranslation()
  const { isOnline } = useCheckOnlineHook(id ?? "");

  return (
    <div className='leading-none'>{isOnline !== null && <div id={`onlineStatus${id}`} className={` ${isOnline ? 'text-chat-accepted' : 'text-chat-declined'} ${className ?? 'text-xxs'}`}>{isOnline ? t('online') : t('offline')}</div>}</div>
  )
}

export default OnlineStatusText