/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import OnlineStatus from '../../Components/OnlineStatus'
import OnlineStatusText from '../../Components/OnlineStatusText'
import Avatar from '../../Components/base/avatar/avatar'
import Loading from '../../Components/base/loading/loading'
import { useAcceptedRequestHook, useChatErrorAndAllowed, useChatUserBlockedHook, useGetCurrentChatMessages, useGetCurrentChats } from '../../Hooks/MessageHooks'
import { ChatDateLabel, ChatMessage, ChatUserData } from '../../Store/Chat/ChatModel'
import { clearUnreadMessages, getChatUsersList, getCurrentChatUser, getMaxCharExceeded, setCurrentChatUser } from '../../Store/Chat/chat'
import { useAppSelector } from '../../Store/hooks'
import { generateGroupedMessagesWithDate } from '../../Utils/functions'
import ChatMessages from './ChatMessages'
import MessageTypeArea from './MessageTypeArea'
import AlwaysScrollToBottom from './ScrollToBottom'

  /**
   * This component renders a chat window for the given chat user.
   * It renders the chat history, and allows the user to send messages.
   * It also renders a "say hello" message if there is no chat history.
   * It also renders a loading indicator while the chat history is being fetched.
   * It also renders an error message if there is an error.
   * It also renders a "connect through video" message if the user has accepted a video request from the chat user.
   * @returns A JSX element representing the chat window.
   */
function ChatWindow() {
  const { t } = useTranslation()

  const [scrollToBottom, setScrollToBottom] = useState(true)

  const currentChatUser = useAppSelector(getCurrentChatUser)

  const { skip, setSkip, isLoading, getMessagesUnInitialized, isFetching } = useGetCurrentChatMessages()
  const { hasAcceptedRequest } = useAcceptedRequestHook()
  const { useCurrentChats, unreadMessage } = useGetCurrentChats()
  const filteredMessages = useCurrentChats()

  const groupedWithDate: (ChatDateLabel | ChatMessage)[] = generateGroupedMessagesWithDate(filteredMessages)

  useEffect(() => {
    setSkip(0)
  }, [currentChatUser])

  if (_.isEmpty(currentChatUser)) {
    return null
  }

  /**
   * If the user has scrolled to the top of the chat window, fetch the next
   * 20 messages from the server and add them to the chat window.
   * @param {Event} event The scroll event.
   */
  function onScrollTop(event: any) {
    if (!event.target.scrollTop) {
      setSkip(skip + 20)
    }
  }

  /**
   * If the user has scrolled to the bottom of the chat window, set scrollToBottom to true.
   * Otherwise, set scrollToBottom to false.
   * @param {Event} event The scroll event.
   */
  const isAtBottom = (event: any) => {
    const bottom = event.target.scrollHeight - event.target.scrollTop <= (((event.target.clientHeight) / 70) * 100);
    if (bottom)
      setScrollToBottom(true)
    else
      setScrollToBottom(false)
  };

  if (isLoading || getMessagesUnInitialized) {

    return (
      <div className='flex w-full h-full justify-center items-center '>
        <Loading />
      </div>
    )
  }

  return (
    <div className="w-full h-full flex flex-col">
      <BreakPointChatProfile />
      {groupedWithDate && !_.isEmpty(groupedWithDate) && (
        <div
          id="chat"
          className="flex flex-1 h-full  overflow-y-auto overflow-x-hidden flex-col font-normal md:text-sm lg:text-lg text-xs px-4 pl-6"
          onScroll={event => {
            isAtBottom(event)
            onScrollTop(event)
          }}>
          <ChatMessages groupedWithDate={groupedWithDate} unreadMessage={unreadMessage} scrollToBottom={()=>{ setScrollToBottom(true) }}/>
          {!isFetching && !isLoading && hasAcceptedRequest && <div id="connectThroughVideo_txt" className="text-xxs self-center font-normal">{t("youCanNowConnectThroughVideoWithThisUser")}</div>}
          {scrollToBottom && <AlwaysScrollToBottom />}
        </div>
      )}
      {
        (!groupedWithDate || _.isEmpty(groupedWithDate)) && (
          <div className="px-2 flex flex-1 h-full w-full font-thin text-xl md:text-4xl text-gray-300 justify-center items-center">
            {!isLoading && !isFetching && < div > {t("sayHello")}</div>}
          </div>
        )
      }
      <div className="flex flex-row h-12 ml-6 mr-4">
        <MessageTypeArea />
      </div>
      <ChatErrorMessages/>
    </div>
  )
}

  /**
   * Renders a chat profile for the given chat user.
   * The component shows the user's avatar, name, online status, and last message.
   * If the user is blocked by the current user, the row is grayed out.
   * If the user is currently online, a green dot is displayed to the right of their name.
   * The component is hidden on mobile devices.
   * @returns A JSX element representing the chat profile.
   */
function BreakPointChatProfile() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const currentChatUser = useSelector(getCurrentChatUser)
  const chatUsersList = useSelector(getChatUsersList)
  const [dropdown, setDropdown] = useState<boolean>(false)

  const { isBlockedByMe, isBlockedMe } = useChatUserBlockedHook();
  const showOnlineStatus = (id: string) => !isBlockedByMe(id) && !isBlockedMe(id)
  const showAvatar = !isBlockedMe(currentChatUser?.id)

  return (
    <div>
      <div
        className='relative gap-x-4 mx-5 rounded-t-xl border-b border-gray-100 pb-2 px-2 flex justify-start md:pointer-events-none sm:cursor-pointer md:cursor-pointer capitalize'
        onClick={() => { setDropdown(!dropdown) }} >

        {showAvatar ? <Avatar
          size="chatScreen"
          avatar={currentChatUser.avatar}
          anonymous={currentChatUser.anonymous}
          images={currentChatUser.image}
          displayChar={currentChatUser.anonymous ? currentChatUser.nickName : currentChatUser.firstName}
        /> : <Avatar
          size="chatScreen"
        />}

        <div className='flex flex-col justify-center leading-none'>
          <div className='text-xl font-normal'>{currentChatUser.anonymous ? currentChatUser.nickName : currentChatUser.firstName}</div>
          {showOnlineStatus(currentChatUser?.id) && <OnlineStatusText id={currentChatUser.id} className="text-xs -mt-1 font-medium" />}
        </div>

        {dropdown &&
          <div className="top-[3rem] flex flex-col absolute left-0 z-10 rounded-b-xl bg-white cursor-pointer w-full px-1 py-2 overflow-y-auto h-120 ">
            {
              chatUsersList?.map((value: ChatUserData, index: number) => {
                return (
                  <div
                    key={index.toString()}
                    className='flex flex-row py-2 w-full hover:bg-gray-200 rounded-lg '
                    onClick={() => {
                      dispatch(setCurrentChatUser(value?.chatUser))
                      dispatch(clearUnreadMessages(value?.chatUser?.id))
                      navigate(`/s/chat-box/${value?.chatUser?.id}`)
                    }}
                  >
                    <div className='min-w-10 min-h-10 pr-2 relative'>
                      {showOnlineStatus(value.chatUser.id) && <OnlineStatus
                        className='rounded-full border-2 bottom-0 shadow-md right-2 z-10 p-1 absolute'
                        id={value?.chatUser?.id} />}
                      <Avatar
                        size="x-small"
                        avatar={value.chatUser?.avatar}
                        anonymous={value.chatUser?.anonymous as any}
                        images={value.chatUser?.image}
                        displayChar={value.chatUser?.anonymous ? value.chatUser?.nickName : value.chatUser?.firstName}
                      />
                    </div>
                    <div className='flex flex-col justify-center'>
                      {value?.chatUser.anonymous ? value.chatUser.nickName : value.chatUser.firstName}
                    </div>
                  </div>
                )
              })
            }
          </div>}
      </div>
    </div>
  )
}

/**
 * Displays error messages when the user is not allowed to send messages
 * or exceeds the maximum limit of characters in a message.
 * @returns {JSX.Element} JSX element containing the error messages
 */
function ChatErrorMessages() {
  const {t} = useTranslation()
  const chatMaxCharExceeded = useAppSelector(getMaxCharExceeded)
  const currentChatUser = useAppSelector(getCurrentChatUser)

  const { showVideoLimitExceeded, showUpdatePreferences, showCannotSend, maxTextSize, chatVideoLimit } = useChatErrorAndAllowed()
  const { isBlockedByMe } = useChatUserBlockedHook()

  return <>
    {showUpdatePreferences ?
      (<div className='w-full flex justify-end p-1'>
        <div id="txt_updatePreferences" className='text-red-500 text-sm'>
          {t('changeAllowOtherUsersToMessageYouInSettingsToChatWithOthers')}
        </div>
      </div>) : null}
    {showCannotSend ?
      (<div className='w-full flex justify-end p-1'>
        <div id="txt_showCannotSend" className='text-red-500 text-sm'>
          {t('youCannotSendMessagesToThisUser')}
        </div>
      </div>) : null}
    {showVideoLimitExceeded ?
      (<div className='w-full flex justify-end pb-4 p-1'>
        <div id="txt_videoLimit" className='text-red-500 text-sm'>
          {`${t('maximumLimitForRequestIs')} ${chatVideoLimit}, ${t('thatIsReachedForToday')}`}
        </div>
      </div>) : null}
    {chatMaxCharExceeded ?
      (<div className='w-full flex justify-end p-1'>
        <div data-testid="maxLength" id="maxLength" className='text-red-500 text-sm'>
          {`${t('messageCanBeMax')} ${maxTextSize} ${t('charactersLong')}`}
        </div>
      </div>) : null}
    {isBlockedByMe(currentChatUser?.id) ?
      (<div className='w-full flex justify-end p-1'>
        <div data-testid="maxLength" id="maxLength" className='text-red-500 text-sm'>
          {t('unBlockTheUserToChat')}
        </div>
      </div>) : null}
  </>
}


export default ChatWindow
