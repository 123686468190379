/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import { CancellationRequest } from './cancellationRequest.model';
import { CommonEntity } from './commonEntity.model';
import { Meeting } from './meeting.model';
import { MeetingRequest } from './meetingRequest.model';
import { User } from './user.model';

export enum TransactionStatus {
    Submitted = 'Submitted',
    Completed = 'Completed',
    Failed = 'Failed',
    Processing = 'Processing',
}
  
export enum TransactionType {
    Debit = 'Debit',
    Credit = 'Credit',
    Razorpay = 'Razorpay',
    Stripe = 'Stripe',
}

export interface Ledger extends CommonEntity {
    user: User;
    transactionId: string;
    paymentIntentId: string;
    transactionDateTime: Date;
    transactionDateDisplay: string;
    transactionTimeDisplay: string;
    transactionType: TransactionType;
    transactionAmount: number;
    costLocal: number;
    currency: string;
    transactionDescription: string;
    transactionStatus: TransactionStatus;
    meeting: Meeting;
    meetingRequest: MeetingRequest;
    guestCount: number;
    originalGuestCount: number;
    isDeduction: boolean;
    previousTransactions: Ledger;
    balanceTransactionId: string;
    originalTransactionAmount: number;
    transactionDetails?: any | any[];
    cancellation: CancellationRequest;
    netTransactionAmount: number;
    processingFee: number;
    
    display_createdAt: string;
    display_createdAtTime: string;
    display_meetingTime: string;
    display_meetingDate: string;

    totalCredit: number;
    totalDebit: number;
    totalEarning: number;
    creditPercentage: number;
    debitPercentage: number;
    toTransferId: string;
    transactionLedgerDetail: any[];
}