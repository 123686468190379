/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import Icon from "../../Components/base/icon/icon";
import { User } from "../../models/user.model";


/**
 * Displays the user's social media links if they are present in the user object.
 * @param user - The user object to display the social media links for.
 * @returns A JSX element containing the social media links.
 */
const UserSocials = ({ user }: { user: User }) => {

  return (
    <div className="flex flex-row gap-x-10 my-2">
      {user?.facebookName &&
        <a id="link_facebook" data-testid="link_facebook"  rel="noreferrer" target="_blank" href={`https://www.facebook.com/${user?.facebookName}`}>
          <Icon stroke='none' icon="facebook" size="medium"></Icon></a>}
      {user?.instagramName &&
        <a id="link_instagram" data-testid="link_instagram" rel="noreferrer" target="_blank" href={`https://www.instagram.com/${user?.instagramName}`}>
          <Icon stroke='none' icon="instagram" size="medium"></Icon> </a>}
      {user?.twitterName &&
        <a id="link_twitter" data-testid="link_twitter" rel="noreferrer" target="_blank" href={`https://www.twitter.com/${user?.twitterName}`}>
          <Icon stroke='none' icon="twitter" size="medium"></Icon></a>}
      {user?.linkedInName &&
        <a id="link_linkedIn" data-testid="link_linkedIn" rel="noreferrer" target="_blank" href={`https://www.linkedin.com/in/${user?.linkedInName}`}>
          <Icon stroke='none' icon="linkedIn" size="medium"></Icon></a>}
    </div>
  )
}

export default UserSocials;
