/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import defaultImage from '../Assets/Images/Line-style-icons-camera.svg'
import { Button } from '../Components'
import Icon from '../Components/base/icon/icon'
import Loading from '../Components/base/loading/loading'
import { toastSuccess } from '../Components/toast'
import { usePaymentSuccessHook } from '../Hooks/PaymentSuccess'
import { Ledger, TransactionStatus } from '../models/ledger.model'
import DisplayGuestCard from './Payment/DisplayGuestCard'
import InvitedGuest from './Payment/InvitedGuest'
import { useCurrencyHook } from '../Hooks/CurrencyHooks'
import { TagsInput } from '../Components/base/tags-input/tagInput'
import { string } from 'yup';
import { DisplayFormattedDate } from '../Components/formattedDateAndTime'
import { differenceInMinutes, isValid } from 'date-fns'
import { useHandleMeetingNavigation } from './LiveKit/hooks/livekitWebOnlyHooks'
import joinThroughHeader from '../Assets/Images/join-through-header.png';
import joinThroughEvents from '../Assets/Images/join-through-events.png';
import Tippy from '@tippyjs/react'

/**
 * A page component that displays the result of a payment transaction.
 *
 * @param {any} props - The component props, which should include a route parameter with a transactionId.
 * @return {JSX.Element} The JSX element representing the payment success page.
 */
function PaymentSuccess() {
  const { t } = useTranslation();
  const { transactionId } = useParams();

  const { loading, ledger, showToastMessage, setShowToastMessage } = usePaymentSuccessHook(transactionId);

  useEffect(() => {
    if (showToastMessage) {
      toastSuccess(t('guestsInvitedSuccessfully'));
      setShowToastMessage(false);
    }
  }, [showToastMessage]);

  if (loading) {
    return <Loading />
  }

  return <>
    {(ledger?.transactionStatus === TransactionStatus.Completed) &&
      <PaymentSuccessInvite ledger={ledger} transactionId={transactionId} />
    }

    {(ledger?.transactionStatus === 'Submitted' || ledger?.transactionStatus === 'Failed') && (
      <div>
        <div className="mt-8 flex flex-col justify-center items-center">
          <div className="flex justify-center items-center">
            <div className="flex flex-col justify-center items-center lg:w-interests">
              <span className="text-3xl font-bold text-red-500 mb-4">{t('yourPaymentHasFailed')}</span>
              <span className="font-semibold">{t('pleaseTryAgainLater')}</span>
            </div>
          </div>
          <Link to={`/s/meeting/${ledger.meeting.meetingDetails.id}`}>
            <Button className="flex flex-row py-1 justify-center items-center w-60 mt-12" size="sm" color="filterData">
              <span className="pl-2 text-xs md:text-base">{t('goBack')}</span>
            </Button>
          </Link>
        </div>
        <div className="fixed bottom-32 left-0 right-0 flex justify-center"><NavigationButtons ledger={ledger} /></div>
      </div>
    )}
  </>
}

export default PaymentSuccess

/**
 * A component that displays the result of a payment transaction.
 *
 * @param {Object} props - The component props.
 * @param {Ledger} props.ledger - The ledger object associated with the transaction.
 * @param {string} props.transactionId - The ID of the transaction.
 *
 * @return {JSX.Element} The JSX element representing the payment success page.
 */
function PaymentSuccessInvite({ ledger, transactionId }: { ledger: Ledger, transactionId?: string }) {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencyHook();

  const {
    imageURL,
    onSubmit,
    notify,
    invitedGuests,
    getAllInvitedGuestList,
    inviteLoading,
    invitedNonUserEmails,
    uniqueEmailError,
    showToastMessage,
    setShowToastMessage,
    setNewEmails,
    serverError, setServerError,
    uniqueText
  } = usePaymentSuccessHook(transactionId);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (showToastMessage) {
      toastSuccess(t('guestsInvitedSuccessfully'));
      setShowToastMessage(false);
    }
  }, [showToastMessage]);


  return (
    <>
      <div className="my-8 overflow-hidden">
        <div className="flex flex-col text-center">
          <span
            id="chk_payConfirm"
            data-testid="chk_payConfirm"
            className="text-xl md:text-3xl font-bold text-BeeMG-green mb-4">
            {t('yourPaymentIsSuccessful')}
          </span>
          <span
            className="mb-4 md:w-8/12 mx-auto font-semibold"
            data-testid="input_displayDate">
            <DisplayFormattedDate displayDate={ledger.display_createdAt}/>
          </span>
          {ledger?.guestCount > 1 && (
            <div>
              <div>
                <span className="font-semibold text-xl mb-6" data-testid="paidForGuests">
                  {t('youHavePaidFor')} {ledger?.guestCount - 1} {t('guests')}
                </span>
              </div>
              {!invitedGuests && !inviteLoading &&
                <div className="w-full justify-center">
                  <div className='relative flex flex-col gap max-w-80 mt-2 mx-auto justify-center items-center'>

                    <TagsInput
                      placeHolder={t('enterEmails')}
                      value={uniqueText}
                      error={uniqueEmailError}

                      separators={[',', ';', ' ']}
                      disabled={(ledger?.guestCount - 1) <= (getAllInvitedGuestList?.length || 0) + uniqueText.length}
                      beforeAddValidate={(newTag, tags) => {
                        try {
                          string().email('invalid').validateSync(newTag);
                          return true;
                        } catch (error) {
                          return false;
                        }
                      }}
                      onExisting={(email) => {
                        console.log(email, 'email exists');
                      }}
                      onChange={(tags) => {
                        setNewEmails(tags);
                      }}
                      onKeyUp={(e) => {
                        setServerError(undefined)
                        
                      }}
                      name="email_input"
                    />

                    {serverError &&
                      serverError.map((e: any) => (
                        <Tippy content={t(e.error)}>
                          <div
                            id="host_error"
                            className="text-black mt-2 text-sm border border-red-500 p-2 rounded bg-red-500 cursor-pointer">
                            {e.email}
                          </div>
                        </Tippy>
                      ))}

                    {ledger?.guestCount - 1 - (uniqueText.length + (getAllInvitedGuestList?.length || 0)) < 0 && (
                      <div className='text-red-500 text-sm'>
                        {t('inviteCountExceeded')}
                      </div>
                    )}

                    {getAllInvitedGuestList?.length === 0 &&
                      <div className='justify-center text-gray-400 text-xs mt-2'>{t('inviteRemaining')} : {ledger.guestCount - 1 - uniqueText.length}{' / '}{ledger.guestCount - 1}</div>
                    }
                    {getAllInvitedGuestList && getAllInvitedGuestList?.length > 0 &&
                      <div  id="btn_inviteRemaining" data-testid="btn_inviteRemaining" className='text-gray-400 text-xs mt-1'>
                        {t('inviteRemaining')} : {ledger.guestCount - 1 - (uniqueText.length + getAllInvitedGuestList?.length)}{' / '}{ledger.guestCount - 1}
                      </div>}

                  </div>
                  <div className="flex items-center justify-center mt-2">
                    <div className="justify-center items-center" id="btn_resetPassword">
                      <Button
                        id="btn_notify"
                        data-testid="btn_notify"
                        size="sm"
                        color="save"
                        className="m-2 justify-center flex flex-row py-1 px-2 w-52"
                        disabled={notify || inviteLoading || uniqueEmailError.length > 0 || uniqueText.length === 0
                          || (ledger?.guestCount - 1 - (uniqueText.length + (getAllInvitedGuestList?.length || 0))) < 0
                        }
                        onClick={onSubmit}
                      >
                        {t('notify')}
                      </Button>
                    </div>
                  </div>
                </div>
              }

              {invitedNonUserEmails.length !== 0 && <>
                <div className="mt-6">
                  <span className="font-semibold mt-6" id="invitedGuests" data-testid="invitedGuests">{t('invitedGuests')}</span>
                </div>
                <div   id="email_guest" className="max-h-[16rem] overflow-y-auto w-80 mx-auto">
                  <div className="flex-col gap gap-y-1 w-full mt-2 justify-center items-center">
                    <DisplayNonUserGuest invitedNonUserEmails={invitedNonUserEmails} />
                  </div>
                </div>
              </>
              }

              {getAllInvitedGuestList?.length !== 0 && (
                <>
                  <div className="mt-5">
                    {getAllInvitedGuestList?.find(guest => !!guest?.user) && <span className="font-semibold "  data-testid="invitedBeemgGuests" id="invitedBeemgGuests">{t('invitedBeemgGuests')}
                    </span>}
                    <div className="flex flex-row flex-wrap items-center m-auto justify-center max-w-[70rem] mt-2">
                      {getAllInvitedGuestList?.filter(guest => !!guest?.user)?.map(
                        (invitedGuest, index) => {
                          return (
                            <DisplayGuestCard
                              key={'p' + index}
                              invitedGuest={invitedGuest}
                              index={index}
                            />
                          )
                        },
                      )}
                    </div>
                  </div>
                </>
              )}

              <hr className="my-2 mx-auto block border-gray-300 w-3/5" />
              <div className="flex flex-col mt-8 mb-6 justify-center items-center">
                <div className="font-bold text-base pb-3" data-testid="pointsToBeNoted">
                  {t('pointsToBeNoted')}
                  {' *'}
                </div>
                <div className="pb-3 text-center max-w-[31rem]" data-testid="registeredUser">
                  {t(
                    'registeredUsersShouldCheck’AttendingEvents’ToAcceptTheInvitation',
                  )}
                </div>
                <div className="pb-3 text-center max-w-[31rem]" data-testid="nonRegisteredUser">
                  {t(
                    'Non-registeredUsersShouldCheckMailAndThenRegisterInBeeMGAtTheEarliestBeforeTheEventBegins.',
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col text-center">
          {ledger?.guestCount <= 1 && (
            <div className="flex flex-col justify-center items-center">
              <div className="font-bold text-xl p-8">
                {t("youDon'tHaveAnyGuestsAttending")}
              </div>
            </div>
          )}
        </div>
        <hr className="my-2 mx-auto block border-gray-300 w-3/5" />
        <div className="row-span-7 mt-7">
          <div className="flex md:text-xl justify-center text-center font-semibold" data-testid="eventDescription">
            {t('eventDescription')}
          </div>
          <div className="flex flex-cols-2 justify-center lg:justify-items-center items-center">
            <div className="grid justify-items-center lg:justify-items-start mr-5">
              {ledger.meeting.type === 'Type 1' &&
                ledger?.meeting?.meetingDetails?.image.length > 0 && (
                  <img
                    src={`${imageURL}/${ledger?.meeting?.meetingDetails?.image[0].imageName}`}
                    alt="logo"
                    className="object-cover border-0 rounded-full w-28 h-28"
                  />
                )}
              {ledger.meeting.type === 'Type 2' &&
                ledger?.meetingRequest?.guest?.image &&
                ledger?.meetingRequest?.guest?.image?.length > 0 && (
                  <img
                    src={`${imageURL}/${ledger?.meetingRequest?.guest?.image[0].imageName}`}
                    alt="logo"
                    className="object-cover border-0 rounded-full w-28 h-28 items-center"
                  />
                )}
              {ledger?.meeting?.meetingDetails?.image.length === 0 &&
                ledger.meeting.type === 'Type 1' && (
                  <img
                    src={defaultImage}
                    alt="logo"
                    className="object-cover border-0 rounded-full w-28 h-28"
                  />
                )}
              {ledger?.meetingRequest?.guest?.image.length === 0 &&
                ledger.meeting.type === 'Type 2' && (
                  <img
                    src={defaultImage}
                    alt="logo"
                    className="object-cover border-0 rounded-full w-28 h-28 "
                  />
                )}
            </div>
            {/* <div className='col-span-2'> */}
            <ul className="pt-4 text-gray-700 text-normal ">
              <li className="font-semibold" data-testid="event_title">
                {ledger?.meeting?.meetingDetails?.title}
              </li>
              <li className="mb-0" data-testid="input_hostname">
                {t('hostedBy')}{' '}
                <span className='capitalize'>
                  {ledger?.meeting?.meetingDetails?.isAnonymous
                    ? ledger?.meeting?.user?.nickName
                    : ledger?.meeting?.user?.firstName +
                    ' ' +
                    ledger?.meeting?.user?.lastName}
                </span>
              </li>
              <li className="mb-1 py-1" data-testid="input_amount">
                {t('amountPaid')} : {formatCurrency(ledger?.transactionAmount, ledger?.currency)}
              </li>
              <hr className="my-2 border-gray-300" />
              <li className="mb-1 py-1" data-testid="input_eventDate">
                {t('eventDate')} : <span><DisplayFormattedDate displayDate={ledger.meeting.fromDateTime}/></span>
              </li>
            </ul>
            {/* </div> */}
          </div>
        </div>
        <div className="mt-20 flex justify-center ">
          <NavigationButtons ledger={ledger} />
        </div>
        <div className="mt-5 flex justify-center ">
          <ShowCountDown ledger={ledger} />
        </div>
      </div>
    </>
  )

  /**
   * DisplayNonUserGuest component displays a list of invited non-user emails.
   * If the list of emails is longer than 5, it shows a "view more" button.
   * If the list of emails is longer than 5 after clicking the "view more" button,
   * it shows a "view less" button.
   * @param {{ invitedNonUserEmails: string[] }} props
   * @return {JSX.Element}
   */
  function DisplayNonUserGuest({ invitedNonUserEmails }: { invitedNonUserEmails: string[] }) {
    const [displayedEmailsCount, setDisplayedEmailsCount] = useState(5);

        /**
         * Increments the number of displayed emails by 5 when the "view more" button is clicked.
         */
    const handleReadMore = () => {
      setDisplayedEmailsCount(displayedEmailsCount + 5);
    };

        /**
         * Decrements the number of displayed emails back to 5 when the "view less" button is clicked.
         */
    const handleReadLess = () => {
      setDisplayedEmailsCount(5);
    };

    const showReadMoreButton = displayedEmailsCount < invitedNonUserEmails.length;
    const emailsToShow = invitedNonUserEmails.slice(0, displayedEmailsCount);

    return (
      <>
        {emailsToShow.map((invitedEmail, index) => (
          <InvitedGuest key={'p' + index} invitedEmail={invitedEmail} index={index} />
        ))}

        {showReadMoreButton && (
          <div className="text-gray-300 justify-end items-end cursor-pointer">
            <span data-testid="btn-viewMore" onClick={handleReadMore}>{t('viewMore')}</span>
          </div>
        )}

        {!showReadMoreButton && displayedEmailsCount > 5 && (
          <div className="text-gray-300 justify-end items-end cursor-pointer">
            <span data-testid="btn-viewLess" onClick={handleReadLess}>{t('viewLess')}</span>
          </div>
        )}
      </>
    );
  }
}

/**
 * Displays navigation buttons to go back to the meeting details screen and to
 * navigate to the transaction list screen.
 *
 * @param {object} ledger - Ledger object containing the meeting details and meeting request.
 * @returns {JSX.Element} JSX element containing the navigation buttons.
 */
function NavigationButtons({ ledger }: { ledger: Ledger }) {
  const { t } = useTranslation();

  return <>
    {/* <span>
      {!ledger.meetingRequest &&
        <Link to={`/s/meeting/${ledger.meeting.meetingDetails.id}`}>
          <Button className="flex flex-row py-1 items-center" size="sm" color="filterData">
            <Icon icon="LEFT" size="small" height='small' />
            <span className="pl-2 uppercase text-xs md:text-base">{t('backToMeetingDetailsScreen')}</span>
          </Button>
        </Link>
      }
    </span> */}
    <span>
      <Link to="/s/transaction-list">
        <Button className="flex flex-row py-1 items-center border-0" data-testid="btn_details" size="sm" color='transparent'>
          <span className="pr-2 text-xs md:text-base" data-testid="checkYourTransactionDetails">{t('checkYourTransactionDetails')}</span>
          <Icon icon="RIGHT" size="small" height='small' />
        </Button>
      </Link>
    </span>
  </>
}

/**
 * ShowCountDown component displays a countdown timer for the meeting start time.
 * It also displays a button to join the meeting when the meeting is about to start.
 * Additionally, it provides a tutorial on how to join the meeting if the user is not familiar with the process.
 *
 * @param {{ ledger: Ledger }} props - The ledger object containing the meeting details.
 * @returns {JSX.Element} JSX element containing the countdown timer and the join button.
 */
function ShowCountDown({ ledger }: { ledger: Ledger }) {
  const transactiondatetime = new Date(ledger.transactionDateTime);
  const meetingStartTime = new Date(ledger.meeting.fromDateTime);

  const [timeDiffFormatted, setTimeDiffFormatted] = useState<string>("");
  const [showJoinButton, setShowJoinButton] = useState<boolean>(false);
  const { joinMeeting } = useHandleMeetingNavigation();
  const { t } = useTranslation();
  const [previewImage, setPreviewImage] = useState<string | undefined>(undefined);
  const [isModalVisible, setIsModalVisible] = useState(false);


  /**
   * Handle preview button click event.
   * @param {string} imageSrc - Image URL to preview.
   */
  const handlePreview = (imageSrc: string) => {
    setPreviewImage(imageSrc);
    setIsModalVisible(true);
  };

  /**
   * Hide the preview modal and reset the preview image to undefined.
   */
  const handleCancel = () => {
    setIsModalVisible(false);
    setPreviewImage(undefined);
  };

  useEffect(() => {
    if (isValid(transactiondatetime) && isValid(meetingStartTime)) {
      const diffInMinutes = differenceInMinutes(meetingStartTime, transactiondatetime);
    
      const days = Math.floor(diffInMinutes / (60 * 24));
      const hours = Math.floor((diffInMinutes % (60 * 24)) / 60);
      const minutes = diffInMinutes % 60;
    
      const formatTimeUnit = (value: number, unit: string) => {
        return value === 1 ? `${value} ${unit}` : `${value} ${unit}s`;
      };
    
      let formattedTime = "";
      if (days > 0) formattedTime += formatTimeUnit(days, "day");
      if (hours > 0) {
        if (formattedTime) formattedTime += `, `;
        formattedTime += formatTimeUnit(hours, "hour");
      }
      if (minutes > 0 || formattedTime === "") {
        if (formattedTime) formattedTime += `, `;
        formattedTime += formatTimeUnit(minutes, "minute");
      }
      setTimeDiffFormatted(formattedTime);
      setShowJoinButton(diffInMinutes <= 10);
    }
  }, [transactiondatetime, meetingStartTime]);

  return (
    <div className="flex flex-col items-center p-8 text-center space-y-6">
      {showJoinButton ? (
        <>
          <div className="text-black px-4 py-3 rounded w-full max-w-md">
            <strong className="font-bold">Event is starting soon!</strong>
            <span className="block">You can now join the event.</span>
          </div>
          <div
            id={`joinTheMeeting${ledger.meeting?.id}`}
            onClick={() => joinMeeting(ledger.meeting.id)}
            className="cursor-pointer font-medium text-black bg-BeeMG-yellow rounded-md px-5 py-2"
          >
            {t("joinTheMeeting")}
          </div>
        </>
      ) : (
        <>
          <div className="text-lg font-medium mb-2">Event starts in:</div>
          <div className="text-black px-6 py-4 rounded-lg w-full max-w-md">
            <div className="text-3xl font-extrabold">{timeDiffFormatted}</div>
          </div>
        </>
      )}

      {!showJoinButton && (
        <>
          <div className="w-full max-w-md">
            <button className="text-black underline">How to join?</button>
            <p className="text-sm text-black mt-2">
              You can join the event through the header or the attending events tab.
              A reminder will be sent 5 minutes before the event starts. You can also
              add a reminder in the attending events tab to get prior notifications.
            </p>
          </div>

          <div className="space-y-4 mt-4 flex flex-col items-center justify-center">
            <p className="text-black font-semibold text-lg">Ways to join the meeting:</p>

            <div className="cursor-pointer flex flex-col items-center justify-center" onClick={() => handlePreview(joinThroughHeader)}>
              <img
                src={joinThroughHeader}
                alt="Way 1 to join the meeting"
                className="w-1/3 h-auto rounded-md"
              />
              <p className="text-sm mt-2">1. Join through header</p>
            </div>

            <div className="cursor-pointer flex flex-col items-center justify-center" onClick={() => handlePreview(joinThroughEvents)}>
              <img
                src={joinThroughEvents}
                alt="Way 2 to join the meeting"
                className="w-1/3 h-auto rounded-md"
              />
              <p className="text-sm mt-2">2. Join through events tab</p>
            </div>
          </div>
        </>
      )}

      {isModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center">
          <div className="relative bg-white p-4 rounded-lg shadow-lg max-w-lg w-full">
            <button onClick={handleCancel} className="absolute top-2 right-2 text-gray-500 hover:text-gray-900">
              &times;
            </button>
            <img src={previewImage} alt="Preview" className="w-full h-auto rounded-md" />
          </div>
        </div>
      )}
    </div>
  );
}
     
  

