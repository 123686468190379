/*
 * Copyright © 2024 HimitsuLabs. All rights reserved.
 */

import { CommonEntity } from './commonEntity.model';

export enum CurrentlySupported {
    Yes = 'Yes',
    No = 'No'
}

export interface Currency extends CommonEntity {
    code: string;
    name: string;
    valueUSD: number;
    countryCode: string;
    currentlySupported: CurrentlySupported;
    currencyConvertion: string;
    formatType: string;
    currencyLocale: string;
}