/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import useToggle from '../../Components/_utils/useToggle'
import { AccordionBorder, AccordionItem, AccordionPanel } from '../../Components/base/accordion/accordion'
import useWindowDimensions from '../../Components/base/carousel/whirligig/utils'
import NewIcon from '../../Components/base/icon/newIcons'
import { useAttendingHooks, useCancellationHooks, useMeetingJoinHooks } from '../../Hooks/AttendingHooks'
import { useAttendingPartialCancellationHooks } from '../../Hooks/AttendingPartialCancellationHook'
import { Meeting } from '../../models/meeting.model'
import CardImagePlaceHolder from '../EventsCard/CardImagePlaceHolder'
import CardTitle from '../EventsCard/CardTitle'
import { DisplayEventTypeIcons } from '../EventsCard/DisplayEventTypeIcons'
import { EventCard } from '../EventsCard/EventCard'
import InvitedGuests from '../EventsCard/InvitedGuest'
import TextLink from '../EventsCard/TextLink'
import { JoinButton } from './AttendingEventsJoinButton'
import { AttendingEventsRemainder } from './AttendingEventsRemainder'
import { PartialCancellationPolicyModal } from './PartialCancellationPolicyModal'
import { PartialCancellationRequestModal } from './PartialCancellationRequestModal'
import { DisplayFormattedDateAndTime } from '../../Components/formattedDateAndTime'
import { DelayDisplay } from '../../Components/DelayDisplay'
import { useEffect } from 'react'

/**
 * The ScheduleWithGuests component renders a schedule of events with guests.
 * The component renders an accordion with an event card inside.
 * The event card contains the event title, from and to date, icons for the event type and guest count.
 * The component also renders a button to accept or decline the event invitation.
 * The component also renders a link to invite more guests.
 * The component uses the useMeetingJoinHooks and useAttendingPartialCancellationHooks to
 * manage the event invitation and the join time.
 * The component also renders a cancellation policy modal dialog.
 * The component uses the useEffect hook to refetch the attending event page on join time change.
 * @param {Meeting} meeting - The meeting object.
 * @returns {JSX.Element} The ScheduleWithGuests component.
 */
export function ScheduleWithGuests({meeting}: {meeting: Meeting}) {
  const {t} = useTranslation()
  const {toggle: modalCancellationPolicyToggle, isOpen: isModalCancellationPolicyOpen} = useToggle()
  const {toggle: modalCancellationRequestToggle, isOpen: isModalCancellationRequestOpen} = useToggle()

  const { width } = useWindowDimensions()
  const { joinTime, requestCancelTime, remainderJoinTime, joinBufferTime } = useMeetingJoinHooks({meeting})
  const { inviteSuccess, partialCancellationData, invitedGuest} = useAttendingPartialCancellationHooks({meeting, closeModal: modalCancellationPolicyToggle})
  const { requestedCancellation, meetingTitle, requestForMeetingCancel, currentUser } = useCancellationHooks({meeting})
  const {attendingEventPageRefetch} = useAttendingHooks()
  const truncatedTitle = {
    meetingTitle: meetingTitle.length > 25 ? meetingTitle.substring(0, 25) + '...' : meetingTitle
  }

  useEffect(()=>{
    attendingEventPageRefetch()
  },[joinTime])

  if (!inviteSuccess && !partialCancellationData) {
    return <></>
  }
  return (
    <>
      <AccordionBorder keyId={`${meeting.id}`}>
        <div onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}>
          <AccordionItem keyId={`${meeting.id}`} toggle={joinBufferTime ? `${meeting.id}`: ''} color="card" pointing={!requestForMeetingCancel? true : false}>
            <EventCard keyId={meeting.id}>
              {meeting.type === 'Type 1' && <EventCard.Image><CardImagePlaceHolder images={meeting?.meetingDetails?.image} /></EventCard.Image>}
              {meeting.type === 'Type 2' &&  meeting?.user2?.id === currentUser.id ? <EventCard.Image><CardImagePlaceHolder images={meeting?.user2?.image} /></EventCard.Image>: meeting?.user?.id === currentUser.id  && <EventCard.Image><CardImagePlaceHolder images={meeting?.user?.image} /></EventCard.Image>}
              <EventCard.Title><CardTitle keyId={meeting.id} key={meeting.id} meetingTitle={truncatedTitle.meetingTitle} /></EventCard.Title>
              <EventCard.SubTitle>
                <DisplayFormattedDateAndTime displayFromTime={meeting.fromDateTime} displayToTime={meeting.toDateTime} displayDate={meeting.fromDateTime} />
              </EventCard.SubTitle>
              <EventCard.Icons><DisplayEventTypeIcons meetingDetail={meeting.meetingDetails} meeting={meeting} /></EventCard.Icons>
              <EventCard.Actions >
                <div className="grid grid-cols-6 w-full">
                  <div className={`text-center justify-center items-center mx-auto col-span-5 w-20 leading-none`}>
                    {joinTime ? (
                      <JoinButton meeting={meeting} />
                    ) : (
                      <>
                        {requestedCancellation === 'PARTIALREQUESTED' ? (
                          <>
                            <NewIcon size="medium" icon="AWAITCANCEL" className="mb-1 justify-center items-center mx-auto " stroke='#535353' />
                            <span id={`chk_cancellationRequested${meeting.id}`} className="flex text-xs text-center cursor-not-allowed">
                              {t('awaitingCancellation')}
                            </span>
                          </>
                        ) : (
                          <>
                             <DelayDisplay delayTime={1000}>
                              <>
                                    {requestedCancellation === 'PARTIALREQUEST' && !requestForMeetingCancel && !requestCancelTime && (
                                      <>
                                        <div className="flex flex-col items-center justify-center mx-auto px-14">
                                          <NewIcon size="medium" icon="SEND" stroke="#535353" className="mb-1" />
                                          <span
                                            data-testid={`btn_requestForPartialCancellation${meeting.id}`}
                                            id={`btn_requestForPartialCancellation${meeting.id}`}
                                            onClick={modalCancellationPolicyToggle}
                                            className="text-center"
                                          >
                                            <TextLink type="cancelRequest">
                                              {t('requestForPartialCancellation')}
                                            </TextLink>
                                          </span>
                                        </div>
                                      </>
                                    )}
                                  </>    
                            </DelayDisplay>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <div className='flex justify-end mx-auto'>
                    {((!joinTime || remainderJoinTime) && requestedCancellation !== 'PARTIALREQUESTED') && <AttendingEventsRemainder meeting={meeting} />}
                  </div>
                </div>
              </EventCard.Actions>
            </EventCard>

          </AccordionItem>

          {!requestForMeetingCancel &&
            <AccordionPanel id={`${meeting.id}`}>
              <div className="md:p-4 p-2 ml-6 flex flex-col">
                <div className="flex flex-row justify-between items-center w-full px-5 ">
                  {meeting.meetingParticipant[0]?.transaction?.guestCount > 1 &&
                    invitedGuest?.length <
                    meeting.meetingParticipant[0]?.transaction?.guestCount - 1 && requestedCancellation !== 'PARTIALREQUESTED' &&(
                      <span id={`btn_inviteYourFriends${meeting.id}`} className="bg-gray-200 w-32 rounded-md py-1 mt-3 px-1 text-gray-400 text-center ml-16">
                        <Link to={`/s/payment/${meeting.meetingParticipant[0]?.transaction.transactionId}`}>{t('inviteGuests')}</Link>
                      </span>)}
                  <div className="pt-1 md:ml-16 text-gray-400 text-sm">
                    {t('inviteRemaining')} : {' '}
                    {invitedGuest?.length === 0 && meeting?.meetingParticipant[0]?.transaction.guestCount - 1}
                    {invitedGuest?.length > 0 && meeting?.meetingParticipant[0]?.transaction.guestCount - invitedGuest?.length - 1}
                  </div>
                </div>
                {invitedGuest?.length !== 0 &&
                  <InvitedGuests guests={invitedGuest} transactionId={meeting.meetingParticipant[0]?.transaction.id } editableOption={requestedCancellation !== 'PARTIALREQUESTED' ? true : false}/>
                }
              </div>
            </AccordionPanel>}
        </div>
      </AccordionBorder>
      {isModalCancellationPolicyOpen && <PartialCancellationPolicyModal meeting={meeting} width={width} closeModal={(e) => {
        if (e === 'YES') {
          modalCancellationRequestToggle(e)
          modalCancellationPolicyToggle(e);
        } else {
          modalCancellationPolicyToggle(e)
        }

      }} />}
      {isModalCancellationRequestOpen && <PartialCancellationRequestModal meeting={meeting} closeModal={modalCancellationRequestToggle} />}
    </>
  )
}
