/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

import { Preference } from '../../models/preference.model'
import { useTranslation } from 'react-i18next'
import NewIcon from '../../Components/base/icon/newIcons'
import { useCurrencyHook } from '../../Hooks/CurrencyHooks'

interface _TotalCreditedCardProps {
    preference: Preference
    creditData: any
}

/**
 * A component to display total credited amount in the transaction history.
 * @param {Preference} preference - The user's preference.
 * @param {any} creditData - The data of total credited amount.
 * @returns {JSX.Element} - The component element.
 */
export default function TotalCreditedCard(props: _TotalCreditedCardProps) {
    const { preference, creditData } = props
    const { t } = useTranslation()
    const { formatCurrency } = useCurrencyHook();
    return (
      <div>
        <div className="flex pl-12 p-8 border-b-2">
          <div className="flex flex-row items-center justify-between w-full gap-x-4">
            <div className="flex flex-col">
              <div className="text-sm font-semibold">
                {t('amountCreditedThisMonth')}
              </div>
              <div className="text-2xl font-medium text-BeeMG-green">
                {formatCurrency(creditData?.totalCredit, preference.currency)}
              </div>
              <div className="flex text-sm font-medium gap-x-1">
                <div className="text-BeeMG-green">
                  {`${
                    creditData?.creditPercentage === undefined || creditData?.creditPercentage === null
                      ? '0'
                      : creditData?.creditPercentage
                  }`}
                  {'%'}
                </div>
                <div>{t('higherThanLastMonth')}</div>
              </div>
            </div>
            <NewIcon icon="GREENUP_ARROW" size="large" stroke="none" />
          </div>
        </div>
      </div>
    )
}
