/*
 * Copyright © 2024 Himitsu Lab Limited. All Rights Reserved.
 */

/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import google from '../Assets/Images/google.svg'
import { Button } from '../Components'
import Field from '../Components/base/field/field'
import Loading from '../Components/base/loading/loading'
import { useLoginCheckHook } from '../Hooks/LoginCheck'
import { useSignInHook } from '../Hooks/SignIn'
import { getRedirectUrl, resetRedirectUrl } from '../Services/rediretUrlReducer'
import facebook from '../Assets/Images/facebook-square.svg'
import { getCurrentUser } from '../Services/userReducer'
import { store } from '../Store'
import { useAppSelector } from '../Store/hooks'
import { loginValidationSchema } from '../Utils/validation'
import InitialPage from './InitialPage'
import { useTitle } from './useTitle'
import { useLocation } from "react-router-dom";
import Icon from '../Components/base/icon/icon';
import BeemgPrimaryLogo from '../Assets/NewIcons/beemg-logos-primary.svg'
import ReactGA from 'react-ga4';

/**
 * Login Check to load the user details and preferences
 * @returns
 */
function LoginCheck() {
  const navigate = useNavigate()
  const { pathname } = useLocation();
  const redirectURL = useAppSelector(getRedirectUrl);
  const { userSuccess, userData, userPolicyAgreementsData, userPolicyAgreementsSuccess, loading } = useLoginCheckHook();

  const currentUser = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (currentUser && pathname !== '/s/home') {
      navigate('/s/home')
      ReactGA.set({ user_id: currentUser.id });
    }
  }, [currentUser])

  useEffect(() => {
    if (userSuccess && userData) {
      if (userPolicyAgreementsSuccess) {
        if (userPolicyAgreementsData) {
          if (redirectURL) {
            navigate(redirectURL);
            store.dispatch(resetRedirectUrl());
          } else if (pathname !== '/s/home') {
            navigate('/s/home');
          }
        }
      }
    }
  }, [userSuccess, userData, redirectURL, userPolicyAgreementsSuccess]);

  if (loading) {
    return (
      <div className='h-screen flex flex-1 justify-center items-center'>
        <Loading />
      </div>
    )
  }

  return <></>
}

/**
 * SignIn Component
 */
function SignIn() {
  const { t } = useTranslation();
  useTitle(t('signIn'))
  const navigate = useNavigate()

  // ToDo: User shouldn't go back to forgot password screen from here

  const { register, handleSubmit, trigger, getValues, watch, formState: { errors } } = useForm({ resolver: yupResolver(loginValidationSchema) });
  const [showPassword, setShowPassword] = useState(false);
  /**
   * Toggle the show password state.
   * @function
   * @since 0.1.0
   */
  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const {
    token, cognitoKeys, cognitoKeysSuccess, linkData, onSubmit, serverError, openForgotPassword, navigateLink, isForgotPasswordDisabled
  } = useSignInHook();

  if (token) {
    return <LoginCheck />
  }

  if (!cognitoKeysSuccess || !linkData || !cognitoKeys) {
    return (
      <InitialPage>
        <Loading />
      </InitialPage>
    )
  }

  if (navigateLink) {
    navigate(navigateLink);
  }

  return (
    <InitialPage>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-center md:justify-start " >
          <span className="flex items-center flex-col md:p-3 rounded-lg md:opacity-90 bg-none mt-20 md:mt-0">
            <img src={BeemgPrimaryLogo} alt="Beemg Logo" width={110} height={110} className='mb-4' />
            <span className="text-4xl sm:text-normal mt-2 uppercase font-bold">{t('login')}</span>
            {serverError && (
              <span id="error_loginMessage" className="bg-orange-100 w-96 text-yellow-900 rounded-lg py-3 pl-4 mt-3 mx-auto">
                {t(serverError)}
              </span>
            )}
            <div className="mt-2">
              <div className="w-96 px-10 md:px-0">
                <Field
                  {...register('email')}
                  dot={false}
                  autoFocus={true}
                  error={errors?.email?.message}
                  type="text"
                  placeholder={t('email') + ' *'}
                  id="input_email"
                />
                <Field
                  {...register('password')}
                  dot={false}
                  error={errors?.password?.message}
                  type={showPassword ? 'text' : 'password'}
                  placeholder={`${t('password')} *`}
                  id="input_password"
                  icon={
                    (watch('email')?.length > 0 && watch('password')?.length > 0) ? (
                      showPassword ? (
                        <Icon icon="VIEW" onClick={toggleShowPassword} />
                      ) : (
                        <Icon icon="VIEW_SLASH" onClick={toggleShowPassword} />
                      )
                    ) : undefined
                  }
                />
                <span className="flex justify-end text-red-500 mb-1 text-sm font-normal py-2 justify-center" id="btn_forgotPassword">
                  <span
                    className={`cursor-pointer ${isForgotPasswordDisabled ? 'cursor-not-allowed' : ''}`}
                    onClick={isForgotPasswordDisabled ? undefined : () => openForgotPassword(trigger, getValues())}
                  >
                    {t('forgotPassword')}
                  </span>
                </span>
              </div>
            </div>
            <div>
              <Button
                className="w-44 md:w-64 mb-1 md:mb-2 normal-case" color="save" id="btn_signIn" submit
              >
                <div>{t('signIn')}</div>
              </Button>
            </div>
            {/* <div>
                <a
                  href={`https://${cognitoKeys.domain}/oauth2/authorize?response_type=token&client_id=${cognitoKeys.userPoolClientId}&identity_provider=Facebook&redirect_uri=${linkData}loginSuccessful/`}
                >
                  <Button
                    color="facebook"
                    id="btn_signInWithFacebook"
                    className="flex justify-around px-5 w-44 md:w-64 mb-1 md:mb-2"
                    onClick={()=>{window.localStorage.clear()}}
                  >
                    <div className="flex items-center">
                      {t('signInWith')}{' '}
                      <img
                        className="ml-2"
                        width={13}
                        height={13}
                        src={facebook}
                        alt=""
                      ></img>
                    </div>
                  </Button>
                </a>
              </div> */}
            <div>
              <a
                href={`https://${cognitoKeys.domain}/oauth2/authorize?response_type=token&client_id=${cognitoKeys.userPoolClientId}&identity_provider=Google&redirect_uri=${linkData}loginSuccessful/`}
              >
                <Button
                  color="google"
                  id="btn_signInWithGoogle"
                  data-testid="btn_signInWithGoogle"
                  className="flex justify-around mt-1 px-5 w-44 md:w-64 mb-1 md:mb-2"
                  onClick={() => { window.localStorage.clear() }}
                >
                  <div className="flex items-center">
                    {t('signInWith')}{' '}
                    <img
                      className="ml-2"
                      width={12}
                      height={12}
                      src={google}
                      alt=""
                    ></img>
                  </div>
                </Button>
              </a>
            </div>
          </span>
        </div>
      </form>
    </InitialPage>
  )
}

export default SignIn
